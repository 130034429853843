/*
File: renderLoop.ts
Description: Handles the main render loop and scene updates
Created: 2024-02-19
Changes:
- Added splat swapping logic to render loop
- Added engine.runRenderLoop and resize handler
*/

import { GenerateHTMLProps } from '../types';

export const generateRenderLoop = (props: GenerateHTMLProps): string => {
  const { 
    cameraDampeningRef,
    autoPlaySpeed,
    loopMode,
    uiOptions
  } = props;

  const debugMode = uiOptions?.debugMode === true;

  return `
    ${debugMode ? `
    // Create FPS counter element
    const fpsCounter = document.createElement('div');
    fpsCounter.id = 'fpsCounter';
    fpsCounter.style.cssText = 'position: fixed; bottom: 50px; left: 10px; color: #fff; font-size: 12px; font-family: monospace; text-shadow: 1px 1px 2px rgba(0,0,0,0.5); z-index: 3;';
    document.body.appendChild(fpsCounter);
    ` : ''}

    // Handle window resize
    window.addEventListener('resize', function () {
      engine.resize();
    });

    // Main render loop
    engine.runRenderLoop(function () {
      const scrollInterpolationSpeed = 0.01 + transitionSpeed * 0.1;
      const animationRatio = scene.getAnimationRatio();

      // Only update scroll position and camera if not paused for splat loading
      if (!scrollPaused) {
        if (window.autoPlayEnabled && !userControl) {
          scrollTarget += ${autoPlaySpeed} * animationRatio;
        }
        scrollPosition += (scrollTarget - scrollPosition) * scrollInterpolationSpeed;

        if (${loopMode}) {
          if (scrollPosition >= path.length - 1.1) {
            scrollPosition = 0.2;
            scrollTarget = 0.2;
          } else if (scrollPosition <= 0.1) {
            scrollPosition = path.length - 1.11;
            scrollTarget = path.length - 1.11;
          }
        }

        scrollPosition = Math.max(0, Math.min(scrollPosition, path.length - 1));    

        const scrollPercentage = (scrollPosition / (path.length - 1 || 1)) * 100;

        // Update splats based on current position
        updateSplats();

        if (cameraMode !== 'explore') {
          updateScrollUI(scrollPercentage);
        }

        if ((cameraMode === 'hybrid' && !userControl) || (cameraMode === 'tour' && !userControl)) {
        const t = scrollPosition / (path.length - 1 || 1);

        const totalSegments = waypoints.length - 1;
        if (totalSegments >= 1) {
          const segmentT = t * totalSegments;
          const segmentIndex = Math.floor(segmentT);
          const clampedSegmentIndex = Math.min(segmentIndex, totalSegments - 1);
          const lerpFactor = segmentT - clampedSegmentIndex;

          const r1 = rotations[clampedSegmentIndex];
          const nextIndex = (clampedSegmentIndex + 1) % rotations.length;
          const r2 = rotations[nextIndex];

          targetRotation = BABYLON.Quaternion.Slerp(r1, r2, lerpFactor).normalize();

          const floorIndex = Math.floor(scrollPosition);
          const ceilIndex = Math.min(floorIndex + 1, path.length - 1);
          const lerpFactorPos = scrollPosition - floorIndex;

          const interpolatedPosition = BABYLON.Vector3.Lerp(
            path[floorIndex],
            path[ceilIndex],
            lerpFactorPos
          );

          targetPosition = interpolatedPosition;
        } else if (rotations.length === 1) {
          targetRotation = rotations[0].clone();
          targetPosition = path[0].clone();
        }

        if (camera.rotationQuaternion) {
          camera.rotationQuaternion = BABYLON.Quaternion.Slerp(
            camera.rotationQuaternion,
            targetRotation,
            ${cameraDampeningRef}
          ).normalize();
        }

        const positionDampingFactor = 0.1;
        camera.position = BABYLON.Vector3.Lerp(
          camera.position,
          targetPosition,
          positionDampingFactor
        );
      }
    }

      updateActiveWaypoint();
      scene.render();

      ${debugMode ? `
      // Update FPS counter
      const fps = Math.round(engine.getFps());
      fpsCounter.textContent = fps + ' FPS';
      ` : ''}
    });
  `;
};
