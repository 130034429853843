class ImagePreloader {
  private static imageCache: Map<string, HTMLImageElement> = new Map();

  static preloadImages(urls: string[]): Promise<void[]> {
    const uniqueUrls = [...new Set(urls)];
    const loadPromises = uniqueUrls.map(url => {
      if (this.imageCache.has(url)) {
        return Promise.resolve();
      }

      return new Promise<void>((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          this.imageCache.set(url, img);
          resolve();
        };
        img.onerror = reject;
        img.src = url;
      });
    });

    return Promise.all(loadPromises);
  }

  static getPreloadedImage(url: string): HTMLImageElement | undefined {
    return this.imageCache.get(url);
  }

  static isImagePreloaded(url: string): boolean {
    return this.imageCache.has(url);
  }
}

export default ImagePreloader;
