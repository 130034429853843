/*
File: GenerateExportedHtml.ts
Description: Main entry point for HTML generation, now using modular components
Last modified: 2024-02-19
Changes: 
- Added isMobileDevice initialization using helper function
- Updated hotspots interface to match types.ts
- Added proper imports
*/

import { generateTemplate } from './html-generation/template';
import { generateTemplate as generateMinimalTemplate } from './html-generation/template-minimal';
import { generateTemplate as generateProTemplate } from './html-generation/template-pro';
import { GenerateHTMLProps, UIType } from './html-generation/types';
import { CameraMode, SceneLight, SceneType, XRMode, AROptions, SplatSwapPoint } from '../types/SceneTypes';
import { isMobileDevice } from './html-generation/helpers';

export const generateExportedHTML = (
  modelUrl: string,
  includeScrollControls: boolean,
  waypoints: Array<{
    x: number;
    y: number;
    z: number;
    rotation: { x: number; y: number; z: number; w: number };
    interactions: Array<{ id: string; type: string; data: any }>;
    triggerDistance?: number;
    name?: string;
  }>,
  backgroundColor: string,
  cameraMovementSpeed: number,
  cameraRotationSensitivity: number,
  scrollSpeed: number,
  animationFrames: number,
  hotspots: Array<{
    id: string;
    position: { _x: number; _y: number; _z: number };
    scale: { _x: number; _y: number; _z: number };
    rotation: { _x: number; _y: number; _z: number;};
    title: string;
    information?: string;
    photoUrl?: string;
    activationMode: "click" | "hover";
    color: string;
    type: "sphere" | "image";
    imageUrl?: string;
    imageName?: string;
  }>,
  defaultCameraMode: CameraMode,
  cameraDampeningRef: number,
  uiColor: string,
  transitionSpeed: number,
  scrollButtonMode: "percentage" | "waypoint",
  scrollAmount: number,
  allowedCameraModes: string[],
  loopMode: boolean,
  autoPlaySpeed: number,
  autoPlayEnabled: boolean,
  collisionMeshesData: any[],
  includeXR: boolean,
  xrMode: XRMode,
  arOptions: AROptions,
  uiOptions: {
    infoPosition: 'popup' | 'controls';
    buttonPosition: "inline" | "below";
    showStartExperience?: boolean;
    debugMode?: boolean;
    uiType: UIType;
    hideWatermark?: boolean;
  },
  name: string,
  sceneId: string,
  userId: string,
  userName: string,
  lights: SceneLight[],
  sceneType?: SceneType,
  additionalSplats?: SplatSwapPoint[],
  keepMeshesInMemory?: boolean,
  playerHeight?: number,
  useNodeMaterial?: boolean,
  thumbnailUrl?: string,
  fov?: number,
  activeSkyboxUrl?: string | null,
  invertCameraRotation?: boolean
): string => {
  const props: GenerateHTMLProps = {
    modelUrl,
    includeScrollControls,
    waypoints,
    backgroundColor,
    cameraMovementSpeed,
    cameraRotationSensitivity,
    scrollSpeed,
    animationFrames,
    hotspots,
    defaultCameraMode,
    cameraDampeningRef,
    uiColor,
    transitionSpeed,
    scrollButtonMode,
    scrollAmount,
    allowedCameraModes,
    loopMode,
    autoPlaySpeed,
    autoPlayEnabled,
    collisionMeshesData,
    includeXR,
    xrMode,
    arOptions,
    uiOptions,
    name,
    sceneId,
    userId,
    userName,
    lights,
    sceneType,
    additionalSplats,
    isMobileDevice: isMobileDevice(),
    keepMeshesInMemory: keepMeshesInMemory ?? false,
    playerHeight,
    useNodeMaterial: useNodeMaterial ?? true,
    thumbnailUrl,
    fov,
    activeSkyboxUrl,
    invertCameraRotation
  };

  // Select template based on UI type
  console.log('Generating HTML:: additionalSplats', additionalSplats);
  switch (uiOptions.uiType) {
    case UIType.Pro:
      return generateProTemplate(props);
    case UIType.Minimal:
      return generateMinimalTemplate(props);
    case UIType.Standard:
    default:
      return generateTemplate(props);
  }
};

export default generateExportedHTML;
