import React, { useState, ChangeEvent, useEffect } from 'react';
import { SplatSwapPoint, Waypoint } from '../../types/SceneTypes';
import { useSceneState } from '../../hooks/useSceneState';
import { useAuth } from '../../contexts/AuthContext';
import { StorageManager } from '../../utils/StorageManager';
import styled from 'styled-components';
import Draggable from 'react-draggable';
import { toast } from 'react-toastify';

type TriggerType = 'waypoint' | 'percentage';

const Container = styled.div`
  position: absolute;
  top: 300px;
  left: 10px;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 8px;
  color: white;
  max-width: 400px;
  margin: 20px;
  z-index: 1002;
  cursor: move;
  max-height: 80vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  
  &:hover {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  }
`;

const Title = styled.h3`
  margin: 0 0 20px 0;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: move;
`;

const ScrollableContent = styled.div`
  overflow-y: auto;
  flex: 1;
  padding-right: 10px;
  
  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
  }
`;

const SplatList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;

const SplatItem = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  
  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: #ccc;
  font-weight: 500;
`;

const Input = styled.input`
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px;
  border-radius: 6px;
  color: white;
  width: 96%%;
  margin-bottom: 5px;
  
  &:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
  }
`;

const Button = styled.button`
  background: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 15px;
  font-weight: 500;
  transition: background 0.2s;
  
  &:hover {
    background: #45a049;
  }
  
  &:disabled {
    background: #666;
    cursor: not-allowed;
  }
`;

const RemoveButton = styled.button`
  background: #f44336;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.2s;
  
  &:hover {
    background: #d32f2f;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  background: #2196F3;
  color: white;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  margin-top: 15px;
  font-weight: 500;
  transition: background 0.2s;
  text-align: center;
  
  &:hover {
    background: #1976D2;
  }
`;

const Select = styled.select`
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px;
  border-radius: 6px;
  color: white;
  width: 100%;
  margin-bottom: 5px;
  
  &:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
  }
`;

const ErrorMessage = styled.div`
  color: #ff6b6b;
  background: rgba(255, 107, 107, 0.1);
  padding: 10px;
  border-radius: 6px;
  margin: 10px 0;
  font-size: 14px;
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: #ccc;
`;

const StorageInfo = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 16px;
  font-size: 14px;
`;

const ProgressBar = styled.div<{ $percent: number }>`
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  margin-top: 8px;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    width: ${(props) => props.$percent}%;
    height: 100%;
    background-color: #4caf50;
    transition: width 0.3s ease;
  }
`;

const PreloaderToggle = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 16px;
`;

interface SplatSwapControlsProps {
  onClose?: () => void;
  additionalSplats: SplatSwapPoint[];
  setAdditionalSplats: React.Dispatch<React.SetStateAction<SplatSwapPoint[]>>;
  currentSplatIndex: number;
  setCurrentSplatIndex: React.Dispatch<React.SetStateAction<number>>;
  waypoints: Waypoint[];
  loadedModelUrl: string;
  initialSplatName?: string;
  keepMeshesInMemory: boolean;
  setKeepMeshesInMemory: React.Dispatch<React.SetStateAction<boolean>>;
}

const SplatSwapControls: React.FC<SplatSwapControlsProps> = ({ 
  onClose,
  additionalSplats,
  setAdditionalSplats,
  currentSplatIndex,
  setCurrentSplatIndex,
  waypoints, 
  loadedModelUrl,
  initialSplatName,
  keepMeshesInMemory,
  setKeepMeshesInMemory
}) => {
  const { currentUser, userProfile } = useAuth();
  const { setIsSplatLoading } = useSceneState();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [splatName, setSplatName] = useState('');
  const [selectedWaypointIndex, setSelectedWaypointIndex] = useState<number>(0);
  const [percentage, setPercentage] = useState<number>(0);
  const [triggerType, setTriggerType] = useState<TriggerType>('waypoint');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState<string | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleAddSplat = async () => {
    console.log('[SPLAT_DEBUG] handleAddSplat called with:', { selectedFile, currentUser });
    if (!selectedFile || !currentUser) {
      console.log('[SPLAT_DEBUG] Early return - missing file or user');
      return;
    }

    setIsUploading(true);
    setUploadError(null);
    console.log('[SPLAT_DEBUG] Current additionalSplats:', additionalSplats);

    try {
      // Upload the splat file
      const uploadResult = await StorageManager.uploadSplatFile(
        currentUser.uid,
        selectedFile,
        (progress) => {
          setUploadProgress(progress);
        }
      );

      const newSplat: SplatSwapPoint = {
        url: uploadResult.url,
        name: splatName || selectedFile.name,
        waypointIndex: triggerType === 'waypoint' ? selectedWaypointIndex : -1,
        percentage: triggerType === 'percentage' ? percentage : -1
      };

      // Create a new array and update state
      const updatedSplats = [...additionalSplats, newSplat];
      console.log('[SPLAT_DEBUG] Updating additionalSplats with:', updatedSplats);
      setAdditionalSplats(updatedSplats);

      // Reset form
      setSelectedFile(null);
      setSplatName('');
      setSelectedWaypointIndex(0);
      setPercentage(0);
      setUploadProgress(0);

      toast.success('Splat uploaded successfully!');
    } catch (error: any) {
      console.error('Upload error:', error);
      setUploadError(error.message);
      toast.error('Failed to upload splat');
    } finally {
      setIsUploading(false);
    }
  };

  const handleRemoveSplat = async (index: number) => {
    console.log('[SPLAT_DEBUG] handleRemoveSplat called for index:', index);
    console.log('[SPLAT_DEBUG] Current additionalSplats:', additionalSplats);
    if (!currentUser) {
      console.log('[SPLAT_DEBUG] Early return - no current user');
      return;
    }

    try {
      // Delete the splat file from storage
      await StorageManager.deleteSplatFile(currentUser.uid, additionalSplats[index].url);

      const updatedSplats = additionalSplats.filter((_, i) => i !== index);
      console.log('[SPLAT_DEBUG] Updating additionalSplats after removal:', updatedSplats);
      setAdditionalSplats(updatedSplats);
      
      // If removing current splat, switch back to initial splat
      if (currentSplatIndex === index) {
        setCurrentSplatIndex(-1); // -1 indicates initial splat
        setIsSplatLoading(false); // Skip preloader when switching back
      } else if (currentSplatIndex > index) {
        setCurrentSplatIndex(currentSplatIndex - 1);
      }

      toast.success('Splat removed successfully');
    } catch (error) {
      console.error('Error removing splat:', error);
      toast.error('Failed to remove splat');
    }
  };

  const handleUpdateSplat = (index: number, updates: Partial<SplatSwapPoint>) => {
    console.log('[SPLAT_DEBUG] handleUpdateSplat called with:', { index, updates });
    console.log('[SPLAT_DEBUG] Current additionalSplats:', additionalSplats);
    const updatedSplats = additionalSplats.map((splat, i) => {
      if (i !== index) return splat;
      
      const updatedSplat = { ...splat };
      
      // If updating trigger type related fields, reset the other type
      if ('waypointIndex' in updates) {
        updatedSplat.percentage = -1;
      }
      if ('percentage' in updates) {
        updatedSplat.waypointIndex = -1;
      }
      
      // Ensure waypointIndex is within bounds
      if ('waypointIndex' in updates && updates.waypointIndex !== undefined) {
        updates.waypointIndex = Math.min(updates.waypointIndex, waypoints.length - 1);
      }
      
      return { ...updatedSplat, ...updates };
    });
    
    console.log('[SPLAT_DEBUG] Updating additionalSplats with:', updatedSplats);
    setAdditionalSplats(updatedSplats);
  };

  const showNoWaypointsError = triggerType === 'waypoint' && waypoints.length === 0;
  const showNoInitialSplatError = !loadedModelUrl;
  const storageUsedPercent = userProfile ? ((userProfile.storageUsed || 0) / (userProfile.maxStorageBytes || 1)) * 100 : 0;

  const formatStorage = (bytes: number): string => {
    const gb = bytes / (1024 * 1024 * 1024);
    return `${gb.toFixed(2)} GB`;
  };

  return (
    <Draggable handle=".handle">
      <Container>
        <Title className="handle">
          Splat Swap Controls
          {onClose && (
            <Button onClick={onClose} style={{ margin: 0, padding: '5px 10px' }}>
              ✕
            </Button>
          )}
        </Title>

        <ScrollableContent>
          {userProfile && (
            <>
              <StorageInfo>
                <div>
                  Storage: {formatStorage(userProfile.storageUsed || 0)}/
                  {userProfile.maxStorageBytes > 0 ? formatStorage(userProfile.maxStorageBytes) : " Unlimited"}
                </div>
                <ProgressBar $percent={storageUsedPercent} />
              </StorageInfo>
              <PreloaderToggle>
                <Label style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer', marginBottom: '5px' }}>
                  <input
                    type="checkbox"
                    checked={keepMeshesInMemory}
                    onChange={(e) => setKeepMeshesInMemory(e.target.checked)}
                    style={{ margin: 0 }}
                  />
                  Keep Meshes in Memory
                </Label>
                <div style={{ fontSize: '12px', color: '#999' }}>
                  Keeps loaded splats in memory for smoother transitions. May increase memory usage.
                </div>
              </PreloaderToggle>
            </>
          )}

          {showNoInitialSplatError && (
            <ErrorMessage>
              Please upload an initial splat model first
            </ErrorMessage>
          )}

          {showNoWaypointsError && (
            <ErrorMessage>
              Please add at least one waypoint to use waypoint triggers
            </ErrorMessage>
          )}

          {uploadError && (
            <ErrorMessage>{uploadError}</ErrorMessage>
          )}

          <SplatList>
            {/* Original/Initial Splat */}
            <SplatItem>
              <Label>Initial Splat</Label>
              <div style={{ 
                padding: '10px',
                background: 'rgba(255, 255, 255, 0.05)',
                borderRadius: '4px',
                wordBreak: 'break-all'
              }}>
                {initialSplatName || 'No splat loaded'} {/* Use initialSplatName if available */}
                {loadedModelUrl && !initialSplatName && (
                  <div style={{ fontSize: '0.8em', opacity: 0.7, marginTop: '4px' }}>
                    {loadedModelUrl}
                  </div>
                )}
              </div>
            </SplatItem>

              {/* Additional Splats */}
              {additionalSplats.map((splat, index) => (
              <SplatItem key={index}>
                <Label>Name</Label>
                <Input
                  type="text"
                  value={splat.name || ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateSplat(index, { name: e.target.value })}
                  placeholder="Splat name"
                />

                <RadioGroup>
                  <RadioLabel>
                    <input
                      type="radio"
                      checked={splat.waypointIndex !== -1}
                      onChange={() => handleUpdateSplat(index, { waypointIndex: 0, percentage: -1 })}
                    />
                    Waypoint
                  </RadioLabel>
                  <RadioLabel>
                    <input
                      type="radio"
                      checked={splat.percentage !== -1}
                      onChange={() => handleUpdateSplat(index, { waypointIndex: -1, percentage: 0 })}
                    />
                    Percentage
                  </RadioLabel>
                </RadioGroup>

                {splat.waypointIndex !== -1 ? (
                  <>
                    <Label>Waypoint</Label>
                    <Select
                      value={splat.waypointIndex}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => 
                        handleUpdateSplat(index, { waypointIndex: parseInt(e.target.value) })}
                    >
                      {waypoints.map((_, idx) => (
                        <option key={idx} value={idx}>Waypoint {idx + 1}</option>
                      ))}
                    </Select>
                  </>
                ) : (
                  <>
                    <Label>Percentage (%)</Label>
                    <Input
                      type="number"
                      min="0"
                      max="100"
                      value={splat.percentage}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => 
                        handleUpdateSplat(index, { percentage: parseFloat(e.target.value) })}
                    />
                  </>
                )}

                <RemoveButton onClick={() => handleRemoveSplat(index)}>
                  Remove
                </RemoveButton>
              </SplatItem>
            ))}
          </SplatList>

          {/* Add new splat form */}
          <SplatItem>
            <Label>Add New Splat</Label>
            <Input
              type="text"
              value={splatName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSplatName(e.target.value)}
              placeholder="Splat name (optional)"
            />

            <RadioGroup>
              <RadioLabel>
                <input
                  type="radio"
                  checked={triggerType === 'waypoint'}
                  onChange={() => setTriggerType('waypoint')}
                />
                Waypoint
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  checked={triggerType === 'percentage'}
                  onChange={() => setTriggerType('percentage')}
                />
                Percentage
              </RadioLabel>
            </RadioGroup>

            {triggerType === 'waypoint' ? (
              <>
                <Label>Waypoint</Label>
                <Select
                  value={selectedWaypointIndex}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => 
                    setSelectedWaypointIndex(parseInt(e.target.value))}
                  disabled={waypoints.length === 0}
                >
                  {waypoints.map((_, idx) => (
                    <option key={idx} value={idx}>Waypoint {idx + 1}</option>
                  ))}
                </Select>
              </>
            ) : (
              <>
                <Label>Percentage (%)</Label>
                <Input
                  type="number"
                  min="0"
                  max="100"
                  value={percentage}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => 
                    setPercentage(parseFloat(e.target.value))}
                />
              </>
            )}

            <FileInput
              type="file"
              id="splat-file"
              accept=".splat"
              onChange={handleFileChange}
            />
            <FileInputLabel htmlFor="splat-file">
              {selectedFile ? selectedFile.name : 'Choose Splat File'}
            </FileInputLabel>

            {isUploading && (
              <div style={{ marginTop: '10px' }}>
                <div>Uploading... {Math.round(uploadProgress)}%</div>
                <ProgressBar $percent={uploadProgress} />
              </div>
            )}

            <Button 
              onClick={handleAddSplat}
              disabled={!selectedFile || !loadedModelUrl || (triggerType === 'waypoint' && waypoints.length === 0) || isUploading || !currentUser}
            >
              {isUploading ? 'Uploading...' : 'Add Splat'}
            </Button>

            {!currentUser && (
              <ErrorMessage>
                Please sign in to add splats
              </ErrorMessage>
            )}
          </SplatItem>
        </ScrollableContent>
      </Container>
    </Draggable>
  );
};

export default SplatSwapControls;
