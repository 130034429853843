/*
File: sceneSetup.ts
Description: Core Babylon.js scene setup and initialization
Created: 2024-02-19
*/

import { GenerateHTMLProps } from '../types';

export const generateSceneSetup = (props: GenerateHTMLProps): string => {
  const { backgroundColor, cameraMovementSpeed, cameraRotationSensitivity, waypoints, modelUrl, fov, invertCameraRotation } = props;
  
  return `
    const preloader = document.getElementById("preloader");
    const canvas = document.getElementById('renderCanvas');
    const engine = new BABYLON.Engine(canvas, true);
    const scene = new BABYLON.Scene(engine);
    window.scene = scene;

    scene.clearColor = BABYLON.Color3.FromHexString('${backgroundColor}').toColor4(1);

    const camera = new BABYLON.UniversalCamera(
      'camera',
      new BABYLON.Vector3(waypoints[0].x, waypoints[0].y, waypoints[0].z),
      scene
    );
    camera.fov = ${fov};
    camera.invertRotation = ${invertCameraRotation};
    camera.inverseRotationSpeed = 2;
    camera.attachControl(canvas, true);
    camera.speed = ${cameraMovementSpeed};
    camera.angularSensibility = ${cameraRotationSensitivity};

    camera.rotationQuaternion = new BABYLON.Quaternion(
      waypoints[0].rotation._x,
      waypoints[0].rotation._y,
      waypoints[0].rotation._z,
      waypoints[0].rotation._w
    ).normalize();

    camera.rotation = camera.rotationQuaternion.toEulerAngles();
    camera.keysUp.push(87);    // W
    camera.keysDown.push(83);  // S
    camera.keysLeft.push(65);  // A
    camera.keysRight.push(68); // D
    camera.keysUpward.push(81);   // Q
    camera.keysDownward.push(69); // E
    camera.inputs.addGamepad();

    camera.checkCollisions = true;
    camera.applyGravity = false;
    camera.ellipsoid = new BABYLON.Vector3(0.1, 0.1, 0.1);

    // Initialize scene but don't load model yet
    // The splatSystem will handle the initial model load
    // This ensures consistent behavior between initial load and splat swaps
  `;
};
