/*
* HelpContent.tsx
* Description: Component that displays help documentation and tutorial video for users
* Last modified: 2024-02-07 10:30
* Changes:
* - Refined styling for better visual consistency
* - Removed potential shadow effects
* - Adjusted margins and padding for improved readability
* - Ensured consistent spacing between sections
*/

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HelpContainer = styled.div`
  background-color: #2c2c2c;
  color: white;
  padding: 30px;
  border-radius: 8px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: none;
`;

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  margin-bottom: 30px;
  width: auto;
  border-radius: 8px;
  overflow: hidden;
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: none;
`;

const Section = styled.div`
  margin-bottom: 40px;
  padding: 0 10px;
`;

const Title = styled.h2`
  color: #4CAF50;
  margin-bottom: 25px;
  font-size: 28px;
  text-align: center;
`;

const SubTitle = styled.h3`
  color: #81c784;
  margin: 25px 0 20px 0;
  font-size: 22px;
  border-bottom: 2px solid #81c784;
  padding-bottom: 10px;
`;

const Text = styled.p`
  line-height: 1.7;
  margin-bottom: 20px;
  font-size: 16px;
`;

const List = styled.ul`
  margin-left: 25px;
  margin-bottom: 25px;
`;

const ListItem = styled.li`
  margin-bottom: 15px;
  line-height: 1.7;
  font-size: 16px;
`;

const ImportantNote = styled.div`
  background-color: #1e1e1e;
  border-left: 6px solid #4CAF50;
  padding: 20px;
  margin: 20px 0;
  border-radius: 0 8px 8px 0;
`;

const FullDocsLink = styled(Link)`
  display: block;
  text-align: center;
  margin-top: 40px;
  padding: 15px 30px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }
`;

const HelpContent: React.FC = () => {
  return (
    <HelpContainer>
      <Title>Welcome to Story Splat!</Title>
      
      <Section>
        <VideoContainer>
          <StyledIframe
            src="https://www.youtube.com/embed/GPVZ012z0js?si=uM916wUuqybuuYO2"  // Placeholder video, replace with actual tutorial
            title="Story Splat Tutorial"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </VideoContainer>
      </Section>

      <Section>
        <SubTitle>Getting Started: Loading Your Model</SubTitle>
        <Text>
          There are two ways to add your 3D model to Story Splat:
        </Text>
        <List>
          <ListItem>
            <strong>Drag and Drop:</strong> Simply drag your model file directly into the viewer
          </ListItem>
          <ListItem>
            <strong>URL Method:</strong> If your model is hosted online, click the "File" menu and paste the URL (must be CORS accessable)
          </ListItem>
        </List>
        <ImportantNote>
          Remember: You must create at least one waypoint before you can export your story. This first waypoint sets your initial camera position!
        </ImportantNote>
      </Section>

      <Section>
        <SubTitle>Creating Your Story Path with Waypoints</SubTitle>
        <Text>
          Waypoints are like stops on your story's journey. Here's how to use them:
        </Text>
        <List>
          <ListItem>
            <strong>Creating Waypoints:</strong> Click the "Waypoints" button and use the "+" button to add a new waypoint at your current camera position
          </ListItem>
          <ListItem>
            <strong>Adding Details:</strong> Each waypoint can have:
            <ul>
              <li>Text descriptions</li>
              <li>Spatial Audio</li>
            </ul>
          </ListItem>
          <ListItem>
            <strong>Ordering:</strong> Arrange waypoints to create your story's path - the camera will follow this path when viewing
          </ListItem>
        </List>
      </Section>

      <Section>
        <SubTitle>Making Your Story Interactive</SubTitle>
        <Text>
          Story Splat offers several ways to make your story more interactive:
        </Text>
        <List>
          <ListItem>
            <strong>Hotspots:</strong> Add clickable points of interest in your scene
            <ul>
              <li>Click or hover over "Hotspots" to view information</li>
              <li>Add text/image popups to each hotspot</li>
              <li>Link to external content</li>
            </ul>
          </ListItem>
          <ListItem>
            <strong>Collision Areas:</strong> Create invisible boundaries
            <ul>
              <li>Use the "Collision" menu to add boundary boxes</li>
              <li>Keep viewers within specific areas</li>
              <li>Guide the story experience</li>
            </ul>
          </ListItem>
        </List>
      </Section>

      <Section>
        <SubTitle>Camera Modes Explained</SubTitle>
        <Text>
          Story Splat has three different ways to view your story:
        </Text>
        <List>
          <ListItem>
            <strong>Tour Mode:</strong> The camera automatically follows your waypoint path, perfect for presenting your story
          </ListItem>
          <ListItem>
            <strong>Explore Mode:</strong> Free movement using WASD+QE keys and mouse looking
          </ListItem>
          <ListItem>
            <strong>Hybrid Mode:</strong> A special combination that:
            <ul>
              <li>Follows the waypoint path as viewers scroll</li>
              <li>Allows free exploration at any time</li>
              <li>Perfect for interactive storytelling!</li>
            </ul>
          </ListItem>
        </List>
      </Section>

      <Section>
        <SubTitle>Tips for Great Stories</SubTitle>
        <List>
          <ListItem>Start with an interesting first waypoint - it's what viewers see first!</ListItem>
          <ListItem>Use hotspots to highlight interesting details</ListItem>
          <ListItem>Add spatial audio to make your story more engaging</ListItem>
          <ListItem>Test your story in different camera modes to see what works best</ListItem>
          <ListItem>Save your work often using the File menu</ListItem>
        </List>
      </Section>

      <Section>
        <SubTitle>Need More Help?</SubTitle>
        <Text>
          If you need any help, you can:
        </Text>
        <List>
          <ListItem>Join our Discord community (click the Discord button)</ListItem>
          <ListItem>Check out the Examples page for inspiration</ListItem>
          <ListItem>Email us at help@storysplat.com</ListItem>
        </List>
      </Section>

      <FullDocsLink to="/help">
        View Full Documentation →
      </FullDocsLink>
    </HelpContainer>
  );
};

export default HelpContent;
