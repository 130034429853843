import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import * as BABYLON from "@babylonjs/core";
import "@babylonjs/core/Gizmos/gizmoManager";
import Draggable from "react-draggable";
import styled from "styled-components";
import { FiMove, FiRotateCw, FiMaximize } from "react-icons/fi";
import { storage } from "../utils/FirebaseConfig";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { useAuth } from "../contexts/AuthContext";
import { Hotspot } from "../types/SceneTypes";

interface HotspotManagerProps {
  scene: BABYLON.Scene | null;
  camera: BABYLON.Camera | null;
  hotspots: Hotspot[];
  setHotspots: React.Dispatch<React.SetStateAction<Hotspot[]>>;
  setDisplayedHotspot: React.Dispatch<React.SetStateAction<Hotspot | null>>;
  displayedHotspot: Hotspot | null;
}

interface HotspotManagerRef {
  renderHotspots: () => void;
}

const Container = styled.div`
  position: absolute;
  top: 60px;
  left: 400px;
  background-color: #1e1e1e;
  border-radius: 8px;
  color: #ffffff;
  z-index: 1000;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
  width: 320px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  @media (max-width: 480px) {
    width: 90%;
    left: 5%;
  }
`;

const Handle = styled.div<{ $isCollapsed?: boolean }>`
  padding: 16px;
  cursor: move;
  background-color: #2c2c2c;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: ${(props) => (props.$isCollapsed ? "8px" : "")};
  border-bottom-right-radius: ${(props) => (props.$isCollapsed ? "8px" : "")};
  user-select: none;
`;

const Content = styled.div<{ $isCollapsed: boolean }>`
  max-height: ${(props) => (props.$isCollapsed ? "0px" : "400px")};
  opacity: ${(props) => (props.$isCollapsed ? 0 : 1)};
  padding: ${(props) => (props.$isCollapsed ? "0 10px" : "10px")};
  transition:
    max-height 0.3s ease,
    opacity 0.3s ease,
    padding 0.3s ease;
  overflow-y: auto;
  background-color: #1e1e1e;
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 18px;
  color: #ffffff;
`;

const ToggleButton = styled.button`
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  outline: none;
  transition: color 0.3s ease;

  &:hover {
    color: #1e90ff;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;

  &:hover {
    background-color: #45a049;
  }
`;

const HotspotList = styled.div`
  margin-bottom: 15px;
`;

const HotspotItem = styled.div<{ $isSelected?: boolean }>`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) =>
    props.$isSelected ? "rgba(0, 123, 255, 0.3)" : "rgba(255, 255, 255, 0.1)"};
  padding: 8px;
  border-radius: 5px;
  border: ${(props) => (props.$isSelected ? "2px solid #007BFF" : "none")};
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) =>
      props.$isSelected
        ? "rgba(0, 123, 255, 0.4)"
        : "rgba(255, 255, 255, 0.2)"};
  }
`;

const EditButton = styled.button<{ $isEditing?: boolean }>`
  padding: 5px 10px;
  background-color: ${(props) => (props.$isEditing ? "#4CAF50" : "#008CBA")};
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 3px;
  font-size: 12px;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.$isEditing ? "#45a049" : "#007B9A")};
  }
`;

const SaveButton = styled(Button)`
  background-color: #4caf50;
  &:hover {
    background-color: #45a049;
  }
`;

const ControlButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`;

const ControlButton = styled.button<{ $active?: boolean }>`
  flex: 1;
  padding: 8px;
  background-color: ${(props) => (props.$active ? "#007BFF" : "#555555")};
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 12px;

  &:hover {
    background-color: ${(props) => (props.$active ? "#0056b3" : "#666666")};
  }
`;

const ImageUploadInput = styled.input`
  width: 100%;
  margin-top: 5px;
  color: white;
  &::-webkit-file-upload-button {
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    &:hover {
      background-color: #0056b3;
    }
  }
`;

const SectionTitle = styled.h4`
  margin: 15px 0 5px 0;
  color: #ffffff;
  font-size: 14px;
`;

const CollapsibleSection = styled.div<{ $isOpen: boolean }>`
  margin-bottom: 10px;

  .content {
    max-height: ${(props) => (props.$isOpen ? "500px" : "0")};
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
`;

const SectionHeader = styled.div<{ $isOpen: boolean }>`
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin-bottom: ${(props) => (props.$isOpen ? "10px" : "0")};

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
`;

const HotspotManager = forwardRef<HotspotManagerRef, HotspotManagerProps>(
  (props, forwardedRef) => {
    const {
      scene,
      camera,
      hotspots,
      setHotspots,
      setDisplayedHotspot,
      displayedHotspot,
    } = props;

    // The currently selected hotspot in the UI
    const [selectedHotspot, setSelectedHotspot] = useState<Hotspot | null>(
      null
    );

    // Collapsible panel
    const [isCollapsed, setIsCollapsed] = useState(false);

    // Gizmo mode
    const [activeControl, setActiveControl] = useState<
      "move" | "rotate" | "scale"
    >("move");

    // Refs
    const gizmoManagerRef = useRef<BABYLON.GizmoManager | null>(null);
    const nodeRef = useRef<HTMLDivElement>(null);

    // Auth
    const { currentUser } = useAuth();

    // External link section
    const [isExternalLinkOpen, setIsExternalLinkOpen] = useState(false);

    // Tracks which hotspot is being edited (for toggling Edit/Save button)
    const [editingHotspotId, setEditingHotspotId] = useState<string | null>(
      null
    );

    // ---------------------------
    //       DRAGGING LOGIC
    // ---------------------------
    const handleDragEvent = (e: React.DragEvent) => {
      e.stopPropagation();
    };

    const toggleCollapse = () => {
      setIsCollapsed(!isCollapsed);
      if (!isCollapsed) {
        setSelectedHotspot(null);
      }
    };


    // ---------------------------
    //       INIT GIZMO
    // ---------------------------
    useEffect(() => {
      if (!scene) return;

      // Create the GizmoManager ONCE if we haven't yet
      if (!gizmoManagerRef.current) {
        const gm = new BABYLON.GizmoManager(scene);
        gizmoManagerRef.current = gm;
        gizmoManagerRef.current.positionGizmoEnabled = true;
        gizmoManagerRef.current.rotationGizmoEnabled = false;
        gizmoManagerRef.current.scaleGizmoEnabled = false;
        gizmoManagerRef.current.usePointerToAttachGizmos = false;
      }

      // Render all hotspots
      renderHotspots();

      // Cleanup
      return () => {
        // If your design requires disposing the gizmo when unmounting:
        // gizmoManagerRef.current?.dispose();
        // gizmoManagerRef.current = null;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scene]);

    // ---------------------------
    //  GIZMO ATTACHMENT
    // ---------------------------
    useEffect(() => {
      if (!scene || !gizmoManagerRef.current) return;

      // First detach from any previous mesh
      gizmoManagerRef.current.attachToMesh(null);

      if (selectedHotspot) {
        // Get the exact mesh by its unique ID
        const mesh = scene.getMeshByName(`hotspot-${selectedHotspot.id}`);
        if (mesh) {
          const transformNode = mesh.parent as BABYLON.TransformNode;
          if (transformNode) {
            if (activeControl === "move" || activeControl === "rotate") {
              gizmoManagerRef.current.attachToMesh(
                transformNode as unknown as BABYLON.Mesh
              );
            } else {
              gizmoManagerRef.current.attachToMesh(mesh);
            }
          }
        }
      }
    }, [selectedHotspot?.id, activeControl, scene]);

    useEffect(() => {
      if (!gizmoManagerRef.current) return;

      // Toggle gizmos
      gizmoManagerRef.current.positionGizmoEnabled = activeControl === "move";
      gizmoManagerRef.current.rotationGizmoEnabled = activeControl === "rotate";
      gizmoManagerRef.current.scaleGizmoEnabled = activeControl === "scale";

      // Make rotation gizmo bigger if you'd like
      if (gizmoManagerRef.current.gizmos.rotationGizmo) {
        gizmoManagerRef.current.gizmos.rotationGizmo.updateGizmoRotationToMatchAttachedMesh =
          true;
        gizmoManagerRef.current.gizmos.rotationGizmo.scaleRatio = 1.5;
      }
    }, [activeControl]);

    // ---------------------------
    //  IMAGE UPLOAD
    // ---------------------------
    const handleImageUpload = async (
      event: React.ChangeEvent<HTMLInputElement>,
      hotspotId: string,
      imageType: "3d" | "info"
    ) => {
      const file = event.target.files?.[0];
      if (!file || !scene || !currentUser) return;

      try {
        const hotspot = hotspots.find((h) => h.id === hotspotId);
        if (!hotspot) return;

        // Delete old image if it exists
        if (
          imageType === "3d" &&
          hotspot.type === "image" &&
          hotspot.imageUrl
        ) {
          try {
            const oldImagePath = `users/${currentUser.uid}/hotspot-images/${hotspotId}_3d_${hotspot.imageName}`;
            const oldImageRef = ref(storage, oldImagePath);
            await deleteObject(oldImageRef);
          } catch (error) {
            console.error("Error deleting old 3D image:", error);
          }
        } else if (imageType === "info" && hotspot.photoUrl) {
          try {
            const oldImagePath = `users/${currentUser.uid}/hotspot-images/${hotspotId}_info_${hotspot.photoName}`;
            const oldImageRef = ref(storage, oldImagePath);
            await deleteObject(oldImageRef);
          } catch (error) {
            console.error("Error deleting old info image:", error);
          }
        }

        // Upload new image
        const imageRef = ref(
          storage,
          `users/${currentUser.uid}/hotspot-images/${hotspotId}_${imageType}_${file.name}`
        );
        await uploadBytes(imageRef, file);
        const imageUrl = await getDownloadURL(imageRef);

        if (imageType === "3d") {
          // Switch it to an image plane if not already
          updateHotspotFields(hotspotId, {
            type: "image",
            imageUrl,
            imageName: file.name,
          });
        } else {
          // Just set the info image
          updateHotspotFields(hotspotId, {
            photoUrl: imageUrl,
            photoName: file.name,
          });
        }
      } catch (error) {
        console.error("Error uploading hotspot image:", error);
        alert("Failed to upload image. Please try again.");
      }
    };

    // ---------------------------
    //  HOTSPOT CRUD
    // ---------------------------
    const addHotspot = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      // If we're editing another hotspot, let's auto-save it first:
      if (editingHotspotId) {
        const oldHotspot = hotspots.find((h) => h.id === editingHotspotId);
        if (oldHotspot) {
          handleSaveHotspot(oldHotspot);
        }
      }

      if (!scene || !camera) return;

      const forward = camera.getForwardRay().direction;
      const position = camera.position.add(forward.scale(5));
      // Calculate rotation to face camera
      const direction = camera.position.subtract(position);
      const horizontalAngle = -Math.atan2(direction.z, direction.x) - Math.PI / 2;
      const verticalAngle = Math.atan2(direction.y, Math.sqrt(direction.x * direction.x + direction.z * direction.z));
      const rotation = new BABYLON.Vector3(verticalAngle, horizontalAngle, 0);

      const newHotspot: Hotspot = {
        id: `hotspot-${Date.now()}`,
        position: position,
        scale: new BABYLON.Vector3(1, 1, 1),
        rotation: rotation,
        title: `Hotspot ${hotspots.length + 1}`,
        activationMode: "click",
        color: "#ff0000",
        type: "sphere",
        contentType: "image",
        billboard: false,
      };

      // Create the mesh immediately
      createHotspotMesh(newHotspot, scene);

      // Update state
      setHotspots((prev) => [...prev, newHotspot]);
      setSelectedHotspot(newHotspot);
      setEditingHotspotId(newHotspot.id);
    };

    const deleteHotspot = async (id: string) => {
      // First clean up the 3D objects
      if (scene) {
        const meshName = `hotspot-${id}`;
        const transformNodeName = `hotspot-transform-${id}`;

        // Detach gizmo if this was the selected mesh
        if (selectedHotspot?.id === id && gizmoManagerRef.current) {
          gizmoManagerRef.current.attachToMesh(null);
        }

        // Remove mesh
        const existingMesh = scene.getMeshByName(meshName);
        if (existingMesh) {
          if (existingMesh.material) {
            existingMesh.material.dispose();
          }
          existingMesh.dispose();
        }

        // Remove transform node
        const existingTransform =
          scene.getTransformNodeByName(transformNodeName);
        if (existingTransform) {
          existingTransform.dispose();
        }
      }

      // Then handle Firebase cleanup
      if (currentUser) {
        const hotspot = hotspots.find((h) => h.id === id);
        if (hotspot) {
          // Delete 3D image if it exists
          if (hotspot.type === "image" && hotspot.imageUrl) {
            try {
              const imagePath = `users/${currentUser.uid}/hotspot-images/${id}_3d_${hotspot.imageName}`;
              const imageRef = ref(storage, imagePath);
              await deleteObject(imageRef);
            } catch (error) {
              console.error("Error deleting 3D hotspot image:", error);
            }
          }
          // Delete info image if it exists
          if (hotspot.photoUrl) {
            try {
              const imagePath = `users/${currentUser.uid}/hotspot-images/${id}_info_${hotspot.photoName}`;
              const imageRef = ref(storage, imagePath);
              await deleteObject(imageRef);
            } catch (error) {
              console.error("Error deleting info image:", error);
            }
          }
        }
      }

      // Finally update state
      setHotspots(hotspots.filter((h) => h.id !== id));
      setSelectedHotspot(null);
      if (editingHotspotId === id) {
        setEditingHotspotId(null);
      }
      if (displayedHotspot?.id === id) {
        setDisplayedHotspot(null);
      }
    };

    // ---------------------------
    //  MESH CREATION
    // ---------------------------
    const createSphereMesh = (hotspot: Hotspot, scene: BABYLON.Scene) => {
      const mesh = BABYLON.MeshBuilder.CreateSphere(
        `hotspot-${hotspot.id}`,
        { diameter: 0.2 },
        scene
      );
      const material = new BABYLON.StandardMaterial(
        `hotspot-material-${hotspot.id}`,
        scene
      );
      material.diffuseColor = BABYLON.Color3.FromHexString(hotspot.color);
      material.emissiveColor = BABYLON.Color3.FromHexString(
        hotspot.color
      ).scale(0.5);
      mesh.material = material;
      return mesh;
    };

    const createImagePlaneMesh = (hotspot: Hotspot, scene: BABYLON.Scene) => {
      const mesh = BABYLON.MeshBuilder.CreatePlane(
        `hotspot-${hotspot.id}`,
        { size: 1 },
        scene
      );
      const material = new BABYLON.StandardMaterial(
        `hotspot-material-${hotspot.id}`,
        scene
      );

      // Store initial scale if it exists in the hotspot
      const initialScale = hotspot.scale;

      // Use a texture with alpha support
      const texture = new BABYLON.Texture(
        hotspot.imageUrl || "",
        scene,
        true,
        true,
        BABYLON.Texture.TRILINEAR_SAMPLINGMODE,
        // onLoad
        () => {
          const size = texture.getSize();
          const ratio = size.width / size.height;

          if (initialScale.x !== 1 || initialScale.y !== 1) {
            // If we have an initial scale, use it directly
            mesh.scaling = initialScale.clone();
          } else{
            // For new meshes, set initial ratio but maintain independent scaling after
            mesh.scaling = new BABYLON.Vector3(ratio, initialScale.y, 1);
          }
        },
        // onError
        (msg, exception) => {
          console.error(
            "Failed to load hotspot image:",
            hotspot.imageUrl,
            msg,
            exception
          );
        }
      );

      // If it's a PNG, use alpha
      if (hotspot.imageUrl?.toLowerCase().includes(".png")) {
        texture.hasAlpha = true;
      }

      material.diffuseTexture = texture;
      material.emissiveColor = BABYLON.Color3.White();
      material.backFaceCulling = false;
      material.useAlphaFromDiffuseTexture = true; // Transparent if PNG

      mesh.material = material;
      return mesh;
    };

    const createHotspotMesh = (hotspot: Hotspot, scene: BABYLON.Scene) => {
      // First, store current transforms if there's an existing mesh
      let storedPosition: BABYLON.Vector3 | null = null;
      let storedRotation: BABYLON.Vector3 | null = null;
      let storedScale: BABYLON.Vector3 | null = null;

      const existingMesh = scene.getMeshByName(`hotspot-${hotspot.id}`);
      if (existingMesh) {
        const existingTransform = existingMesh.parent as BABYLON.TransformNode;
        if (existingTransform) {
          storedPosition = existingTransform.position.clone();
          storedRotation = existingTransform.rotation.clone();
          storedScale = existingMesh.scaling.clone();
        }
      }

      // Create transform node with unique name
      const transformNodeName = `hotspot-transform-${hotspot.id}`;
      let transformNode = scene.getTransformNodeByName(transformNodeName);
      if (transformNode) {
        transformNode.dispose();
      }
      transformNode = new BABYLON.TransformNode(transformNodeName, scene);

      // Create mesh with unique name
      const meshName = `hotspot-${hotspot.id}`;
      let mesh: BABYLON.Mesh;
      if (hotspot.type === "image" && hotspot.imageUrl) {
        mesh = createImagePlaneMesh(hotspot, scene);
      } else {
        mesh = createSphereMesh(hotspot, scene);
      }
      mesh.name = meshName;

      // Parent the mesh to the transform node
      mesh.parent = transformNode;

      // Apply transforms - prefer stored values if available
      transformNode.position =
        storedPosition?.clone() || hotspot.position.clone();
      transformNode.rotation =
        storedRotation?.clone() || hotspot.rotation.clone();
      mesh.scaling = storedScale?.clone() || hotspot.scale.clone();
      
      // Rest of mesh setup
      mesh.billboardMode = hotspot.billboard
        ? BABYLON.Mesh.BILLBOARDMODE_ALL
        : BABYLON.Mesh.BILLBOARDMODE_NONE;

      mesh.isPickable = true;

      // Set up action manager
      if (mesh.actionManager) {
        mesh.actionManager.dispose();
      }
      mesh.actionManager = new BABYLON.ActionManager(scene);

      // Hover
      mesh.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPointerOverTrigger,
          () => {
            if (hotspot.type !== "image") {
              const mat = mesh.material as BABYLON.StandardMaterial;
              mat.emissiveColor = BABYLON.Color3.FromHexString(hotspot.color);
            }
            if (hotspot.activationMode === "hover") {
              setDisplayedHotspot(hotspot);
            }
          }
        )
      );

      mesh.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPointerOutTrigger,
          () => {
            if (hotspot.type !== "image") {
              const mat = mesh.material as BABYLON.StandardMaterial;
              mat.emissiveColor = BABYLON.Color3.FromHexString(
                hotspot.color
              ).scale(0.5);
            }
            if (hotspot.activationMode === "hover") {
              setDisplayedHotspot(null);
            }
          }
        )
      );

      // Click
      mesh.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPickTrigger,
          () => {
            if (hotspot.activationMode === "click") {
              setDisplayedHotspot(hotspot);
            }
          }
        )
      );

      return { mesh, transformNode };
    };

    // ---------------------------
    //  RENDER HOTSPOTS INITALLY
    // ---------------------------
    const renderHotspots = () => {
      if (!scene) return;

      // Keep track of current meshes and their transforms
      const currentMeshes = new Map<
        string,
        {
          position: BABYLON.Vector3;
          rotation: BABYLON.Vector3;
          scale: BABYLON.Vector3;
        }
      >();

      // Store current transforms before cleanup
      hotspots.forEach((hotspot) => {
        const meshName = `hotspot-${hotspot.id}`;
        const existingMesh = scene.getMeshByName(meshName);
        if (existingMesh) {
          const transformNode = existingMesh.parent as BABYLON.TransformNode;
          if (transformNode) {
            currentMeshes.set(hotspot.id, {
              position: transformNode.position.clone(),
              rotation: transformNode.rotation.clone(),
              scale: existingMesh.scaling.clone(),
            });
          }
        }
      });

      // Clean up existing meshes
      hotspots.forEach((hotspot) => {
        const meshName = `hotspot-${hotspot.id}`;
        const transformNodeName = `hotspot-transform-${hotspot.id}`;

        const existingMesh = scene.getMeshByName(meshName);
        if (existingMesh) {
          existingMesh.dispose();
        }

        const existingTransform =
          scene.getTransformNodeByName(transformNodeName);
        if (existingTransform) {
          existingTransform.dispose();
        }
      });

      // Create new meshes
      hotspots.forEach((hotspot) => {
        const { mesh, transformNode } = createHotspotMesh(hotspot, scene);

        // Restore previous transforms if they exist
        const savedTransforms = currentMeshes.get(hotspot.id);
        if (savedTransforms) {
          transformNode.position = savedTransforms.position;
          transformNode.rotation = savedTransforms.rotation;
          mesh.scaling = savedTransforms.scale;
        }

        // If selected, attach gizmo
        if (selectedHotspot?.id === hotspot.id && gizmoManagerRef.current) {
          if (activeControl === "move" || activeControl === "rotate") {
            gizmoManagerRef.current.attachToMesh(
              transformNode as unknown as BABYLON.Mesh
            );
          } else {
            gizmoManagerRef.current.attachToMesh(mesh);
          }
        }
      });
    };

    // ---------------------------
    //  UPDATE HOTSPOT FIELDS
    // ---------------------------
    const updateHotspotFields = (
      hotspotId: string,
      fields: Partial<Hotspot>
    ) => {
      const oldHotspot = hotspots.find((h) => h.id === hotspotId);
      if (!oldHotspot) return;

      // If we're changing type, store current transforms
      if (fields.type !== undefined && scene) {
        const mesh = scene.getMeshByName(`hotspot-${hotspotId}`);
        if (mesh) {
          const transformNode = mesh.parent as BABYLON.TransformNode;
          if (transformNode) {
            fields.position = transformNode.position.clone();
            fields.rotation = transformNode.rotation.clone();
          }
          fields.scale = mesh.scaling.clone();
        }
      }

      const updatedHotspot = { ...oldHotspot, ...fields };

      // Update global array
      const updatedHotspots = hotspots.map((h) =>
        h.id === hotspotId ? updatedHotspot : h
      );
      setHotspots(updatedHotspots);

      // Update local selected state
      if (selectedHotspot?.id === hotspotId) {
        setSelectedHotspot(updatedHotspot);
      }

      // Update displayed hotspot if this is the one being displayed
      if (displayedHotspot?.id === hotspotId) {
        setDisplayedHotspot(updatedHotspot);
      }

      // Check if we need to re-render the 3D scene
      const visualFieldsChanged =
        fields.type !== undefined ||
        fields.billboard !== undefined ||
        fields.imageUrl !== undefined ||
        fields.color !== undefined ||
        fields.position !== undefined ||
        fields.rotation !== undefined ||
        fields.scale !== undefined ||
        fields.activationMode !== undefined ||
        fields.title !== undefined ||
        fields.information !== undefined;

      if (scene && visualFieldsChanged) {
        // Create a new mesh with updated properties
        const existingMesh = scene.getMeshByName(`hotspot-${hotspotId}`);
        if (existingMesh) {
          // Store transforms before disposal
          const transformNode = existingMesh.parent as BABYLON.TransformNode;
          const currentPosition = transformNode?.position.clone();
          const currentRotation = transformNode?.rotation.clone();
          const currentScale = existingMesh.scaling.clone();
          console.log('Update hotspot fields: ', fields);

          // Dispose old mesh and transform node
          if (transformNode) transformNode.dispose();
          existingMesh.dispose();

          // Create new mesh with updated properties
          const { mesh, transformNode: newTransform } = createHotspotMesh(
            updatedHotspot,
            scene
          );

          // Restore transforms
          if (currentPosition && !fields.position)
            newTransform.position = currentPosition;
          if (currentRotation && !fields.rotation)
            newTransform.rotation = currentRotation;
          if (currentScale && !fields.scale) mesh.scaling = currentScale;

          // Re-attach gizmo if this was the selected hotspot
          if (selectedHotspot?.id === hotspotId && gizmoManagerRef.current) {
            if (activeControl === "move" || activeControl === "rotate") {
              gizmoManagerRef.current.attachToMesh(
                newTransform as unknown as BABYLON.Mesh
              );
            } else {
              gizmoManagerRef.current.attachToMesh(mesh);
            }
          }
        }
      }
    };

    // External call from parent if needed
    useImperativeHandle<HotspotManagerRef, HotspotManagerRef>(
      forwardedRef,
      () => ({
        renderHotspots,
      })
    );

    // ---------------------------
    //  SAVE / EDIT
    // ---------------------------
    const handleSaveHotspot = (hotspot: Hotspot) => {
      const mesh = scene?.getMeshByName(`hotspot-${hotspot.id}`);
      const transformNode = mesh?.parent as BABYLON.TransformNode;

      const updatedFields: Partial<Hotspot> = {};

      if (transformNode) {
        updatedFields.position = transformNode.position.clone();
        updatedFields.rotation = transformNode.rotation.clone();
      }

      if (mesh) {
        updatedFields.scale = mesh.scaling.clone();
      }

      // Update the hotspot with current transform
      updateHotspotFields(hotspot.id, updatedFields);

      // Clear editing state
      if (editingHotspotId === hotspot.id) {
        setEditingHotspotId(null);
      }

      // Clear selection
      setSelectedHotspot(null);
    };

    // Handle switching between hotspots and auto-save
    const handleHotspotEditClick = (hotspot: Hotspot) => {
      // If we're already editing a different hotspot, save it first
      if (editingHotspotId && editingHotspotId !== hotspot.id) {
        const previousHotspot = hotspots.find((h) => h.id === editingHotspotId);
        if (previousHotspot) {
          handleSaveHotspot(previousHotspot);
        }
      }

      if (editingHotspotId === hotspot.id) {
        // Already editing this hotspot => Save it
        handleSaveHotspot(hotspot);
      } else {
        // Start editing new hotspot
        setEditingHotspotId(hotspot.id);
        setSelectedHotspot(hotspot);
      }
    };

    return (
      <>
        <Draggable handle=".handle" nodeRef={nodeRef}>
          <Container
            ref={nodeRef}
            onDragEnter={handleDragEvent}
            onDragOver={handleDragEvent}
            onDragLeave={handleDragEvent}
            onDrop={handleDragEvent}
          >
            <Handle
              $isCollapsed={isCollapsed}
              className="handle"
              onDragEnter={handleDragEvent}
              onDragOver={handleDragEvent}
              onDragLeave={handleDragEvent}
              onDrop={handleDragEvent}
            >
              <Header>
                <Title>Hotspots</Title>
                <ToggleButton onClick={toggleCollapse}>
                  {isCollapsed ? "▼" : "▲"}
                </ToggleButton>
              </Header>
            </Handle>
            <Content $isCollapsed={isCollapsed}>
              {selectedHotspot && (
                <ControlButtonGroup>
                  <ControlButton
                    onClick={() => setActiveControl("move")}
                    $active={activeControl === "move"}
                  >
                    <FiMove /> Move
                  </ControlButton>
                  <ControlButton
                    onClick={() => setActiveControl("rotate")}
                    $active={activeControl === "rotate"}
                  >
                    <FiRotateCw /> Rotate
                  </ControlButton>
                  <ControlButton
                    onClick={() => setActiveControl("scale")}
                    $active={activeControl === "scale"}
                  >
                    <FiMaximize /> Scale
                  </ControlButton>
                </ControlButtonGroup>
              )}

              <Button onClick={addHotspot}>Add Hotspot</Button>

              {hotspots.length > 0 && (
                <HotspotList>
                  <h3>Hotspot List</h3>
                  {hotspots.map((hotspot) => (
                    <HotspotItem
                      key={hotspot.id}
                      $isSelected={selectedHotspot?.id === hotspot.id}
                    >
                      <span>{hotspot.title}</span>
                      <EditButton
                        onClick={() => handleHotspotEditClick(hotspot)}
                        $isEditing={editingHotspotId === hotspot.id}
                      >
                        {editingHotspotId === hotspot.id ? "Save" : "Edit"}
                      </EditButton>
                    </HotspotItem>
                  ))}
                </HotspotList>
              )}

              {selectedHotspot && (
                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    padding: "15px",
                    borderRadius: "5px",
                  }}
                >
                  <h3 style={{ margin: "0 0 10px 0" }}>Edit Hotspot</h3>
                  <input
                    type="text"
                    value={selectedHotspot.title}
                    onChange={(e) =>
                      updateHotspotFields(selectedHotspot.id, {
                        title: e.target.value,
                      })
                    }
                    placeholder="Title"
                    style={{
                      width: "96%",
                      marginBottom: "10px",
                      padding: "8px",
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      color: "white",
                      border: "none",
                      borderRadius: "3px",
                    }}
                  />

                  <div style={{ marginBottom: "10px" }}>
                    <label
                      style={{
                        display: "block",
                        marginBottom: "5px",
                        color: "white",
                      }}
                    >
                      Hotspot Type:
                    </label>
                    <select
                      value={selectedHotspot.type}
                      onChange={(e) =>
                        updateHotspotFields(selectedHotspot.id, {
                          type: e.target.value as "sphere" | "image",
                        })
                      }
                      style={{
                        width: "100%",
                        padding: "5px",
                        backgroundColor: "white",
                        color: "black",
                        border: "none",
                        borderRadius: "3px",
                      }}
                    >
                      <option value="sphere">Sphere</option>
                      <option value="image">Image Plane</option>
                    </select>
                  </div>

                  {/* If image plane, show image url / upload */}
                  {selectedHotspot.type === "image" && (
                    <div style={{ marginBottom: "10px" }}>
                      <SectionTitle>Image Plane:</SectionTitle>
                      <input
                        type="text"
                        value={selectedHotspot.imageUrl || ""}
                        onChange={(e) =>
                          updateHotspotFields(selectedHotspot.id, {
                            imageUrl: e.target.value,
                          })
                        }
                        placeholder="Enter image URL"
                        style={{
                          width: "96%",
                          marginBottom: "10px",
                          padding: "8px",
                          backgroundColor: "rgba(255, 255, 255, 0.2)",
                          color: "white",
                          border: "none",
                          borderRadius: "3px",
                        }}
                      />
                      <ImageUploadInput
                        type="file"
                        accept="image/*"
                        onChange={(e) =>
                          handleImageUpload(e, selectedHotspot.id, "3d")
                        }
                      />
                    </div>
                  )}

                  {/* If sphere, show color picker */}
                  {selectedHotspot.type === "sphere" && (
                    <div style={{ marginBottom: "10px" }}>
                      <label
                        style={{
                          display: "block",
                          marginBottom: "5px",
                          color: "white",
                        }}
                      >
                        Sphere Color:
                      </label>
                      <input
                        type="color"
                        value={selectedHotspot.color}
                        onChange={(e) =>
                          updateHotspotFields(selectedHotspot.id, {
                            color: e.target.value,
                          })
                        }
                        style={{
                          width: "100%",
                          height: "40px",
                          padding: "0",
                          border: "none",
                          borderRadius: "3px",
                        }}
                      />
                    </div>
                  )}

                  <div style={{ marginBottom: "10px" }}>
                    <label
                      style={{
                        display: "block",
                        marginBottom: "5px",
                        color: "white",
                      }}
                    >
                      Activation Mode:
                    </label>
                    <select
                      value={selectedHotspot.activationMode}
                      onChange={(e) =>
                        updateHotspotFields(selectedHotspot.id, {
                          activationMode: e.target.value as "click" | "hover",
                        })
                      }
                      style={{
                        width: "100%",
                        padding: "5px",
                        backgroundColor: "white",
                        color: "black",
                        border: "none",
                        borderRadius: "3px",
                      }}
                    >
                      <option value="click">Click</option>
                      <option value="hover">Hover</option>
                    </select>
                  </div>

                  <div style={{ marginBottom: "10px" }}>
                    <label style={{ color: "white" }}>
                      Billboard
                      <input
                        type="checkbox"
                        checked={selectedHotspot.billboard}
                        onChange={(e) =>
                          updateHotspotFields(selectedHotspot.id, {
                            billboard: e.target.checked,
                          })
                        }
                        style={{ marginLeft: "8px" }}
                      />
                    </label>
                  </div>

                  {/* Content type selector */}
                  <div style={{ marginBottom: "10px" }}>
                    <label
                      style={{
                        display: "block",
                        marginBottom: "5px",
                        color: "white",
                      }}
                    >
                      Content Display Type:
                    </label>
                    <select
                      value={selectedHotspot.contentType || "image"}
                      onChange={(e) =>
                        updateHotspotFields(selectedHotspot.id, {
                          contentType: e.target.value as "image" | "iframe",
                        })
                      }
                      style={{
                        width: "100%",
                        padding: "5px",
                        backgroundColor: "white",
                        color: "black",
                        border: "none",
                        borderRadius: "3px",
                      }}
                    >
                      <option value="image">Image</option>
                      <option value="iframe">iFrame</option>
                    </select>
                  </div>

                  {/* If contentType = iframe */}
                  {selectedHotspot.contentType === "iframe" && (
                    <div style={{ marginBottom: "10px" }}>
                      <SectionTitle>iFrame URL:</SectionTitle>
                      <input
                        type="text"
                        value={selectedHotspot.iframeUrl || ""}
                        onChange={(e) =>
                          updateHotspotFields(selectedHotspot.id, {
                            iframeUrl: e.target.value,
                          })
                        }
                        placeholder="Enter iframe URL"
                        style={{
                          width: "96%",
                          marginBottom: "10px",
                          padding: "8px",
                          backgroundColor: "rgba(255, 255, 255, 0.2)",
                          color: "white",
                          border: "none",
                          borderRadius: "3px",
                        }}
                      />
                    </div>
                  )}

                  {/* If contentType = image (info image) */}
                  {(!selectedHotspot.contentType ||
                    selectedHotspot.contentType === "image") && (
                    <div style={{ marginBottom: "10px" }}>
                      <SectionTitle>Display Image (Info):</SectionTitle>
                      <input
                        type="text"
                        value={selectedHotspot.photoUrl || ""}
                        onChange={(e) =>
                          updateHotspotFields(selectedHotspot.id, {
                            photoUrl: e.target.value,
                          })
                        }
                        placeholder="Enter info image URL"
                        style={{
                          width: "96%",
                          marginBottom: "10px",
                          padding: "8px",
                          backgroundColor: "rgba(255, 255, 255, 0.2)",
                          color: "white",
                          border: "none",
                          borderRadius: "3px",
                        }}
                      />
                      <ImageUploadInput
                        type="file"
                        accept="image/*"
                        onChange={(e) =>
                          handleImageUpload(e, selectedHotspot.id, "info")
                        }
                      />
                    </div>
                  )}

                  {/* Description */}
                  <div style={{ marginBottom: "10px" }}>
                    <SectionTitle>Description:</SectionTitle>
                    <textarea
                      value={selectedHotspot.information || ""}
                      onChange={(e) =>
                        updateHotspotFields(selectedHotspot.id, {
                          information: e.target.value,
                        })
                      }
                      placeholder="Information"
                      style={{
                        width: "96%",
                        marginBottom: "10px",
                        padding: "8px",
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        color: "white",
                        border: "none",
                        borderRadius: "3px",
                        minHeight: "30px",
                      }}
                    />
                  </div>

                  {/* External link URL */}
                  <CollapsibleSection $isOpen={isExternalLinkOpen}>
                    <SectionHeader
                      $isOpen={isExternalLinkOpen}
                      onClick={() => setIsExternalLinkOpen(!isExternalLinkOpen)}
                    >
                      <SectionTitle style={{ margin: 0 }}>
                        External Link Settings {isExternalLinkOpen ? "▼" : "▶"}
                      </SectionTitle>
                    </SectionHeader>
                    <div className="content">
                      <div style={{ marginBottom: "10px" }}>
                        <SectionTitle>URL:</SectionTitle>
                        <input
                          type="text"
                          value={selectedHotspot.externalLinkUrl || ""}
                          onChange={(e) =>
                            updateHotspotFields(selectedHotspot.id, {
                              externalLinkUrl: e.target.value,
                            })
                          }
                          placeholder="Enter external link URL"
                          style={{
                            width: "96%",
                            marginBottom: "10px",
                            padding: "8px",
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                            color: "white",
                            border: "none",
                            borderRadius: "3px",
                          }}
                        />

                        <SectionTitle>Button Text:</SectionTitle>
                        <input
                          type="text"
                          value={selectedHotspot.externalLinkText || ""}
                          onChange={(e) =>
                            updateHotspotFields(selectedHotspot.id, {
                              externalLinkText: e.target.value,
                            })
                          }
                          placeholder="Enter button text"
                          style={{
                            width: "96%",
                            marginBottom: "10px",
                            padding: "8px",
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                            color: "white",
                            border: "none",
                            borderRadius: "3px",
                          }}
                        />

                        <SectionTitle>URL Button Color:</SectionTitle>
                        <input
                          type="color"
                          value={
                            selectedHotspot.externalLinkButtonColor || "#007bff"
                          }
                          onChange={(e) =>
                            updateHotspotFields(selectedHotspot.id, {
                              externalLinkButtonColor: e.target.value,
                            })
                          }
                          style={{
                            width: "100%",
                            height: "40px",
                            padding: "0",
                            border: "none",
                            borderRadius: "3px",
                            marginBottom: "10px",
                          }}
                        />
                      </div>
                    </div>
                  </CollapsibleSection>

                  {/* Popup Styling */}
                  <div style={{ marginBottom: "10px" }}>
                    <SectionTitle>Popup Styling:</SectionTitle>
                    <label
                      style={{
                        color: "white",
                        display: "block",
                        marginBottom: "5px",
                      }}
                    >
                      Background Color:
                    </label>
                    <input
                      type="color"
                      value={selectedHotspot.backgroundColor || "#000000"}
                      onChange={(e) =>
                        updateHotspotFields(selectedHotspot.id, {
                          backgroundColor: e.target.value,
                        })
                      }
                      style={{
                        width: "100%",
                        height: "40px",
                        padding: "0",
                        border: "none",
                        borderRadius: "3px",
                        marginBottom: "10px",
                      }}
                    />

                    <label
                      style={{
                        color: "white",
                        display: "block",
                        marginBottom: "5px",
                      }}
                    >
                      Text Color:
                    </label>
                    <input
                      type="color"
                      value={selectedHotspot.textColor || "#ffffff"}
                      onChange={(e) =>
                        updateHotspotFields(selectedHotspot.id, {
                          textColor: e.target.value,
                        })
                      }
                      style={{
                        width: "100%",
                        height: "40px",
                        padding: "0",
                        border: "none",
                        borderRadius: "3px",
                        marginBottom: "10px",
                      }}
                    />

                    <label
                      style={{
                        color: "white",
                        display: "block",
                        marginBottom: "5px",
                      }}
                    >
                      Font Size (px):
                    </label>
                    <input
                      type="number"
                      value={selectedHotspot.fontSize || 16}
                      onChange={(e) =>
                        updateHotspotFields(selectedHotspot.id, {
                          fontSize: parseInt(e.target.value, 10),
                        })
                      }
                      style={{
                        width: "96%",
                        marginBottom: "10px",
                        padding: "8px",
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        color: "white",
                        border: "none",
                        borderRadius: "3px",
                      }}
                    />

                    <label
                      style={{
                        color: "white",
                        display: "block",
                        marginBottom: "5px",
                      }}
                    >
                      Font Family:
                    </label>
                    <select
                      value={selectedHotspot.fontFamily || "inherit"}
                      onChange={(e) =>
                        updateHotspotFields(selectedHotspot.id, {
                          fontFamily: e.target.value,
                        })
                      }
                      style={{
                        width: "100%",
                        padding: "8px",
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        color: "white",
                        border: "none",
                        borderRadius: "3px",
                        marginBottom: "10px",
                      }}
                    >
                      <option value="inherit">Default</option>
                      <option value="Arial, sans-serif">Arial</option>
                      <option value="Helvetica, sans-serif">Helvetica</option>
                      <option value="Times New Roman, serif">
                        Times New Roman
                      </option>
                      <option value="Georgia, serif">Georgia</option>
                      <option value="Verdana, sans-serif">Verdana</option>
                      <option value="monospace">Monospace</option>
                    </select>
                  </div>
                  <label
                    style={{
                      color: "white",
                      display: "block",
                      marginBottom: "15px",
                    }}
                  >
                    <SectionTitle>Close Button Color:</SectionTitle>
                    <input
                      type="color"
                      value={selectedHotspot.closeButtonColor || "#4caf50"}
                      onChange={(e) =>
                        updateHotspotFields(selectedHotspot.id, {
                          closeButtonColor: e.target.value,
                        })
                      }
                      style={{
                        width: "100%",
                        height: "40px",
                        padding: "0",
                        border: "none",
                        borderRadius: "3px",
                      }}
                    />
                  </label>

                  {/* Delete & Save */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      onClick={() => deleteHotspot(selectedHotspot.id)}
                      style={{ width: "48%", backgroundColor: "#f44336" }}
                    >
                      Delete
                    </Button>
                    <SaveButton
                      onClick={() => handleSaveHotspot(selectedHotspot)}
                      style={{ width: "48%" }}
                    >
                      Save Changes
                    </SaveButton>
                  </div>
                </div>
              )}
            </Content>
          </Container>
        </Draggable>
      </>
    );
  }
);

export default HotspotManager;
