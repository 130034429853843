import { useEffect } from 'react';
import ImagePreloader from '../services/ImagePreloader';

export const useHotspotImagePreloader = (hotspots: any[]) => {
  useEffect(() => {
    const imageUrls = hotspots
      .filter(hotspot => hotspot.photoUrl)
      .map(hotspot => hotspot.photoUrl);

    if (imageUrls.length > 0) {
      ImagePreloader.preloadImages(imageUrls)
        .catch(error => console.error('Failed to preload hotspot images:', error));
    }
  }, [hotspots]);
};
