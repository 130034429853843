/*
File: styles.ts
Description: Generates CSS styles for the exported HTML scene
Last modified: 2024-02-13
Changes: 
- Added fullscreen button styles with hover effects and transitions
- Consolidated duplicate styles from template.ts
- Organized preloader and start button styles into dedicated sections
- Updated preloader styles to match React app's preloader styling
- Added generateStylesMinimal function for minimal template
- Added walk mode toggle button styles
*/

import { adjustColorBrightness } from './helpers';

interface StylesProps {
  uiColor: string;
  isMobileDevice: boolean;
  uiOptions: {
    buttonPosition: "inline" | "below";
  };
}

export const generateStyles = ({
  uiColor,
  isMobileDevice,
  uiOptions
}: StylesProps): string => {
  return `
    /* Base Styles */
    body, html { margin: 0; padding: 0; overflow: hidden; width: 100%; height: 100%; font-family: Arial, sans-serif; }
    #renderCanvas { width: 100%; height: 100%; touch-action: none; }

    /* Fullscreen Button Styles */
    #fullscreenButton {
      position: fixed;
      top: 20px;
      right: 20px;
      width: 40px;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      border-radius: 8px;
      cursor: pointer;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s ease;
    }

    #fullscreenButton:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
    #muteButton {
      position: fixed;
      bottom: 10px;
      left: 10px;
      background: rgba(0, 0, 0, 0.3);
      border: none;
      color: white;
      padding: 5px 8px;
      border-radius: 3px;
      font-size: 12px;
      cursor: pointer;
      z-index: 1000;
    }

    #fullscreenButton svg {
      width: 24px;
      height: 24px;
      fill: white;
    }

    /* Preloader Styles */
    @keyframes fadeOut {
      from { opacity: 1; }
      to { opacity: 0; }
    }

    #preloader {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #1e1e1e;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100000;
      transition: opacity 0.5s ease-out;
    }

    #preloader.hidden {
      opacity: 0;
      pointer-events: none;
    }

    #preloader-content {
      display: flex;
      align-items: center;
      padding: 40px;
    }

    #preloader-media {
      display: flex;
      align-items: center;
      gap: 40px;
    }

    #preloader-image {
      height: 200px;
      width: auto;
      object-fit: contain;
      filter: invert(1);
      margin-right: -100px;
    }

    #preloader-lottie {
      height: 200px;
      width: 200px;
    }

    @media (max-width: 768px) {
      #preloader-image {
        height: 100px;
        margin-right: -50px;
      }
      
      #preloader-lottie {
        height: 100px;
        width: 100px;
      }
    }

    /* Start Experience Button Styles */
    #startButtonContainer {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 5000;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
    }

    #startButton {
      padding: 20px 40px;
      font-size: 24px;
      background-color: rgba(255, 255, 255, 0.25);
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }

    #startButton:hover {
      background-color: rgba(255, 255, 255, 0.35);
    }

    /* Content Display Styles */
    #hotspotContent {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      padding: 20px;
      border-radius: 10px;
      z-index: 1001;
      max-width: 300px;
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
      display: none;
      font-size: 14px;
    }

    #infoPopup {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      padding: 20px;
      border-radius: 10px;
      z-index: 1002;
      max-width: 80%;
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
      display: none;
      font-size: 16px;
    }

    /* Scroll Controls Styles */
    #waypointInfo {
      width: 100%;
      margin-bottom: 10px;
      border-radius: 4px;
      font-size: 14px;
      display: none;
    }

    #scrollControls {
      position: absolute;
      bottom: 45px;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgba(0,0,0,0.7);
      padding: 15px;
      border-radius: 10px;
      color: white;
      z-index: 1000;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0 4px 6px rgba(0,0,0,0.1);
      max-width: 350px;
    }

    #scrollControlsContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: opacity 0.3s ease-in-out;
      width: 100%;
    }

    #scrollControlsContent.hidden {
      opacity: 0;
      pointer-events: none;
      position: absolute;
    }

    #scrollPercentage {
      font-size: 18px;
      margin-bottom: 10px;
    }

    #progressBarContainer {
      width: 200px;
      height: 10px;
      background-color: rgba(255,255,255,0.3);
      border-radius: 5px;
      overflow: hidden;
      margin-bottom: 10px;
    }

    #progressBar {
      width: 0%;
      height: 100%;
      background-color: ${uiColor};
      transition: width 0.3s ease;
    }

    /* Button Styles */
    .button {
      background-color: ${uiColor};
      border: none;
      color: white;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      border-radius: 5px;
      transition: background-color 0.3s;
    }

    #scrollButtons {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: ${uiOptions.buttonPosition === "below" ? "10px" : "0"};
    }

    #toggleCameraMode {
      background-color: ${uiColor};
      border: none;
      color: white;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: ${uiOptions.buttonPosition === "inline" ? "inline-block" : "block"};
      width: ${uiOptions.buttonPosition === "inline" ? "auto" : "100%"};
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      border-radius: 5px;
      transition: background-color 0.3s;
    }

    .button:hover, #toggleCameraMode:hover {
      background-color: ${adjustColorBrightness(uiColor, -20)};
    }

    /* Inline Button Position Styles */
    ${uiOptions.buttonPosition === "inline"
      ? `
    #scrollControlsContentInline {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    `
      : ""}
    
    /* Mode Toggle Styles */
      #modeToggleContainer {
      margin: 10px 0;
      display: flex;
      justify-content: center;
    }

    #modeToggle {
      display: flex;
      border: 1px solid ${uiColor};
      border-radius: 5px;
      overflow: hidden;
      width: 250px;
    }

    .mode-button {
      flex: 1;
      padding: 10px;
      background-color: ${uiColor};
      border: none;
      color: white;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s;
      width: 33%;
    }

    .mode-button:not(:last-child) {
      border-right: 1px solid ${uiColor};
    }

    .mode-button.selected {
      background-color: ${adjustColorBrightness(uiColor, 20)};
      color: white;
    }

    .mode-button:not(.selected):hover {
      background-color: ${adjustColorBrightness(uiColor, 20)};
      color: white;
    }


    /* Walk Mode Toggle Button */
    #walkToggleButton {
      position: fixed;
      bottom: 60px;
      right: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      transition: all 0.3s ease;
      z-index: 1000;
      display: none;
    }

    #walkToggleButton.selected {
      background-color: ${uiColor};
    }

    #walkToggleButton:hover {
      background-color: ${adjustColorBrightness(uiColor, -20)};
    }
  `;
};
