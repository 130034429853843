/*
File: fullscreenControls.ts
Description: Handles fullscreen functionality and controls
Created: 2024-02-19
*/

export const generateFullscreenControls = (): string => {
  return `
    // Fullscreen functionality
    const fullscreenButton = document.getElementById('fullscreenButton');
    const expandIcon = document.getElementById('expandIcon');
    const compressIcon = document.getElementById('compressIcon');

    function toggleFullscreen() {
      if (!document.fullscreenElement &&
          !document.mozFullScreenElement &&
          !document.webkitFullscreenElement &&
          !document.msFullscreenElement) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen();
        }
        expandIcon.style.display = 'none';
        compressIcon.style.display = 'block';
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        expandIcon.style.display = 'block';
        compressIcon.style.display = 'none';
      }
    }

    fullscreenButton.addEventListener('click', toggleFullscreen);

    // Update button icons when fullscreen changes
    document.addEventListener('fullscreenchange', updateFullscreenIcons);
    document.addEventListener('webkitfullscreenchange', updateFullscreenIcons);
    document.addEventListener('mozfullscreenchange', updateFullscreenIcons);
    document.addEventListener('MSFullscreenChange', updateFullscreenIcons);

    function updateFullscreenIcons() {
      if (document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement) {
        expandIcon.style.display = 'none';
        compressIcon.style.display = 'block';
      } else {
        expandIcon.style.display = 'block';
        compressIcon.style.display = 'none';
      }
    }
  `;
};
