import React, { useEffect, useRef, useState } from 'react';
import PreloadImage from './PreloadImage';

interface HotspotContentDisplayProps {
  hotspot: {
    title: string;
    information?: string;
    photoUrl?: string;
    contentType?: "image" | "iframe";
    iframeUrl?: string;
    externalLinkUrl?: string;
    externalLinkText?: string;
    backgroundColor?: string;
    textColor?: string;
    fontSize?: number;
    fontFamily?: string;
    externalLinkButtonColor?: string;
    closeButtonColor?: string;
  };
  onClose: () => void;
  showCloseButton: boolean;
  mousePosition: { x: number; y: number };
}

const HotspotContentDisplay: React.FC<HotspotContentDisplayProps> = ({ hotspot, onClose, showCloseButton, mousePosition }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    if (contentRef.current) {
      const rect = contentRef.current.getBoundingClientRect();
      let left, top;

      if (showCloseButton) {
        // Click mode - center on screen
        left = (window.innerWidth - rect.width) / 2;
        top = (window.innerHeight - rect.height) / 2;
      } else {
        // Hover mode - position above cursor
        const padding = 10;
        left = mousePosition.x - (rect.width / 2);
        top = mousePosition.y - rect.height - padding;
      }

      // Boundary checks
      if (left + rect.width > window.innerWidth) {
        left = window.innerWidth - rect.width - 10;
      }
      if (left < 10) {
        left = 10;
      }
      if (top < 10) {
        top = 10;
      }
      if (top + rect.height > window.innerHeight) {
        top = window.innerHeight - rect.height - 10;
      }

      contentRef.current.style.left = `${left}px`;
      contentRef.current.style.top = `${top}px`;
    }
  }, [mousePosition, showCloseButton, isImageLoaded]);

  const containerStyle = {
    position: 'fixed' as const,
    backgroundColor: hotspot.backgroundColor || 'rgba(0, 0, 0, 0.8)',
    color: hotspot.textColor || 'white',
    fontSize: hotspot.fontSize ? `${hotspot.fontSize}px` : 'inherit',
    fontFamily: hotspot.fontFamily || 'inherit',
    padding: '20px',
    borderRadius: '10px',
    zIndex: 1001,
    maxWidth: '300px',
    boxShadow: '0 0 10px rgba(0,0,0,0.5)'
  };

  return (
    <div 
      ref={contentRef}
      style={containerStyle}
    >
      <h3 style={{ margin: '0 0 10px 0' }}>{hotspot.title}</h3>
      
      {hotspot.contentType === 'iframe' && hotspot.iframeUrl && (
        <iframe 
          src={hotspot.iframeUrl}
          style={{ width: '100%', height: '200px', border: 'none', marginBottom: '10px' }}
          title={hotspot.title}
        />
      )}

      {(hotspot.contentType === 'image' || !hotspot.contentType) && hotspot.photoUrl && (
        <PreloadImage
          src={hotspot.photoUrl}
          alt={hotspot.title}
          style={{ width: '100%', height: 'auto', marginBottom: '10px', borderRadius: '5px' }}
          onLoad={() => setIsImageLoaded(true)}
        />
      )}

      {hotspot.information && (
        <p style={{ marginBottom: '10px' }}>{hotspot.information}</p>
      )}

      {hotspot.externalLinkUrl && (
        <div 
          onClick={() => window.open(hotspot.externalLinkUrl, '_blank', 'noopener,noreferrer')}
          style={{
            display: 'block',
            padding: '8px 16px',
            backgroundColor: hotspot.externalLinkButtonColor || '#007bff',
            color: 'white',
            textDecoration: 'none',
            borderRadius: '4px',
            textAlign: 'center',
            marginBottom: '10px',
            cursor: 'pointer'
          }}
        >
          {hotspot.externalLinkText || 'Open External Link'}
        </div>
      )}

      {showCloseButton && (
        <button 
          onClick={onClose} 
          style={{ 
            width: '100%', 
            padding: '10px', 
            backgroundColor: hotspot.closeButtonColor || '#4CAF50', 
            border: 'none', 
            color: 'white', 
            cursor: 'pointer', 
            borderRadius: '5px' 
          }}
        >
          Close
        </button>
      )}
    </div>
  );
};

export default HotspotContentDisplay;
