/*
File: xrSystem.ts
Description: Handles XR (AR/VR) functionality and features
Created: 2024-02-19
*/

import { GenerateHTMLProps } from '../types';

export const generateXRSystem = (props: GenerateHTMLProps): string => {
  const { includeXR, xrMode, arOptions, modelUrl } = props;

  if (!includeXR) {
    return '';
  }

  return `
    let xrExperience;
    // Find floor mesh for VR reference
    const collisionMeshes = scene.meshes.find(mesh => 
      mesh.name.startsWith('collisionMesh')
    );

    const xrPromise = scene.createDefaultXRExperienceAsync({
      uiOptions: {
        sessionMode: "${xrMode === 'ar' ? 'immersive-ar' : 'immersive-vr'}",
        referenceSpaceType: "local-floor"
      },
      optionalFeatures: true,
      floorMeshes: collisionMeshes ? [collisionMeshes] : undefined
    });

    xrPromise.then((xr) => {
      xrExperience = xr;
      
      // Set up button click handlers
      const arButton = document.getElementById('ar-button');
      const vrButton = document.getElementById('vr-button');

      if (arButton) {
        arButton.addEventListener('click', async () => {
          try {
            await xr.baseExperience.enterXRAsync("immersive-ar", "local-floor");
          } catch (error) {
            console.error('Error entering AR:', error);
            alert('Failed to enter AR mode. Please ensure your device supports WebXR and you have granted the necessary permissions.');
          }
        });
      }

      if (vrButton) {
        vrButton.addEventListener('click', async () => {
          try {
            await xr.baseExperience.enterXRAsync("immersive-vr", "local-floor");
          } catch (error) {
            console.error('Error entering VR:', error);
            alert('Failed to enter VR mode. Please ensure your device supports WebXR and you have a compatible VR headset connected.');
          }
        });
      }

      if ("${xrMode}" === "ar") {
        const fm = xr.baseExperience.featuresManager;

        // Hit Testing
        ${arOptions?.enableHitTest ? `
        const xrHitTest = fm.enableFeature(BABYLON.WebXRHitTest.Name, "latest");
        const marker = BABYLON.MeshBuilder.CreateTorus('marker', { diameter: 0.15, thickness: 0.05 });
        marker.isVisible = false;
        marker.rotationQuaternion = new BABYLON.Quaternion();

        let hitTest;
        xrHitTest.onHitTestResultObservable.add((results) => {
          if (results.length) {
            marker.isVisible = true;
            hitTest = results[0];
            hitTest.transformationMatrix.decompose(marker.scaling, marker.rotationQuaternion, marker.position);
          } else {
            marker.isVisible = false;
            hitTest = undefined;
          }
        });` : ''}

        // Plane Detection
        ${arOptions?.enablePlaneDetection ? `
        const xrPlanes = fm.enableFeature(BABYLON.WebXRPlaneDetector.Name, "latest");
        const planes = [];

        xrPlanes.onPlaneAddedObservable.add(plane => {
          const planeMesh = BABYLON.MeshBuilder.CreateGround("plane", { width: 1, height: 1 });
          const material = new BABYLON.StandardMaterial("planeMat", scene);
          material.alpha = 0.5;
          material.diffuseColor = BABYLON.Color3.Random();
          planeMesh.material = material;
          planeMesh.rotationQuaternion = new BABYLON.Quaternion();
          
          planes[plane.id] = planeMesh;
          plane.transformationMatrix.decompose(planeMesh.scaling, planeMesh.rotationQuaternion, planeMesh.position);
        });

        xrPlanes.onPlaneUpdatedObservable.add(plane => {
          if (planes[plane.id]) {
            plane.transformationMatrix.decompose(
              planes[plane.id].scaling,
              planes[plane.id].rotationQuaternion,
              planes[plane.id].position
            );
          }
        });

        xrPlanes.onPlaneRemovedObservable.add(plane => {
          if (planes[plane.id]) {
            planes[plane.id].dispose();
            delete planes[plane.id];
          }
        });` : ''}

        // Anchors
        ${arOptions?.enableAnchors ? `
        const anchors = fm.enableFeature(BABYLON.WebXRAnchorSystem.Name, 'latest');
        let loadedModel;
        
        if (anchors) {
          scene.onPointerDown = (evt, pickInfo) => {
            if (hitTest && anchors && loadedModel) {
              anchors.addAnchorPointUsingHitTestResultAsync(hitTest).then(anchor => {
                const anchoredModel = loadedModel.clone("anchored-model");
                anchoredModel.isVisible = true;
                anchor.attachedNode = anchoredModel;
                
                hitTest.transformationMatrix.decompose(
                  anchoredModel.scaling,
                  anchoredModel.rotationQuaternion,
                  anchoredModel.position
                );
              });
            }
          }

          anchors.onAnchorRemovedObservable.add(anchor => {
            if (anchor && anchor.attachedNode) {
              anchor.attachedNode.dispose();
            }
          });
        }` : ''}

        // Background Removal
        ${arOptions?.enableBackgroundRemoval ? `
        const xrBackgroundRemover = fm.enableFeature(BABYLON.WebXRBackgroundRemover.Name);` : ''}

        // Clean up on session end
        xr.baseExperience.sessionManager.onXRSessionInit.add(() => {
          ${arOptions?.enablePlaneDetection ? `
          Object.values(planes).forEach(plane => plane.dispose());
          Object.keys(planes).forEach(key => delete planes[key]);` : ''}
        });
      }
    });
  `;
};
