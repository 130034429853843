/*
File: BabylonScene.tsx
Description: Core component for rendering the 3D scene
Last modified: 2024-02-13
Changes: 
- Added arc rotate camera for edit mode
- Added double-click ray picking behavior
- Added camera mode switching
- Added FPV camera visualization
- Added splat swapping logic to render loop
*/

import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import * as BABYLON from "@babylonjs/core";
import "@babylonjs/loaders";
import "@babylonjs/inspector";
import { VirtualJoystick } from "@babylonjs/core";
import { wheelHandler } from "../../tools/WheelHandler";
import {
  Waypoint,
  AudioInteractionData,
  Interaction,
  InfoInteractionData,
  SceneLight,
  CameraMode,
  SplatSwapPoint,
} from "../../types/SceneTypes";
import AudioManager from "../../utils/AudioManager";
import { useLights } from "../../hooks/useLights";

export interface BabylonSceneRef {
  captureScreenshot: () => Promise<Blob>;
}

interface BabylonSceneProps {
  onSceneReady: (scene: BABYLON.Scene, camera: BABYLON.UniversalCamera) => void;
  onSceneCapture?: (thumbnailBlob: Blob) => void;
  backgroundColor: string;
  cameraMovementSpeed: number;
  cameraRotationSensitivity: number;
  isMobile: boolean;
  setIsLoading: (loading: boolean) => void;
  pathRef: React.MutableRefObject<BABYLON.Vector3[]>;
  rotationsRef: React.MutableRefObject<BABYLON.Quaternion[]>;
  scrollPositionRef: React.MutableRefObject<number>;
  scrollTargetRef: React.MutableRefObject<number>;
  userControlRef: React.MutableRefObject<boolean>;
  cameraModeRef: React.MutableRefObject<CameraMode>;
  setScrollPercentage: (percentage: number) => void;
  transitionSpeedRef: React.MutableRefObject<number>;
  waypoints: Waypoint[];
  animationFrames: number;
  scrollSpeed: number;
  isEditMode: boolean;
  autoPlayEnabled: boolean;
  autoPlaySpeed: number;
  loopMode: boolean;
  setCurrentWaypointTitle: (title: string) => void;
  setInfoPopupText: (text: string | null) => void;
  lights: SceneLight[];
  scrollPausedRef: React.MutableRefObject<boolean>;
  additionalSplatsRef: React.MutableRefObject<SplatSwapPoint[]>;
  loadedModelUrl: string;
  setLoadedModelUrl: (url: string) => void;
  setCurrentSplatIndex: (index: number) => void;
  waypointsRef: React.MutableRefObject<Waypoint[]>;
  savedModelUrlRef:  React.MutableRefObject<string | null>;
  playerHeight: number;
  fov: number;
  skyboxUrl: string | null;
  invertCameraRotation: boolean;
}

const BabylonScene = forwardRef<BabylonSceneRef, BabylonSceneProps>(
  (props, ref) => {
    const {
      onSceneReady,
      onSceneCapture,
      backgroundColor,
      cameraMovementSpeed,
      cameraRotationSensitivity,
      isMobile,
      setIsLoading,
      pathRef,
      rotationsRef,
      scrollPositionRef,
      scrollTargetRef,
      userControlRef,
      cameraModeRef,
      setScrollPercentage,
      transitionSpeedRef,
      waypoints,
      animationFrames,
      scrollSpeed,
      isEditMode,
      autoPlayEnabled,
      autoPlaySpeed,
      loopMode,
      setCurrentWaypointTitle,
      setInfoPopupText,
      lights,
      scrollPausedRef,
      additionalSplatsRef,
      loadedModelUrl,
      waypointsRef,
      savedModelUrlRef,
      playerHeight,
      fov,
      skyboxUrl,
      invertCameraRotation
    } = props;

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const engineRef = useRef<BABYLON.Engine | null>(null);
    const sceneRef = useRef<BABYLON.Scene | null>(null);
    const cameraRef = useRef<BABYLON.UniversalCamera | null>(null);
    const editCameraRef = useRef<BABYLON.ArcRotateCamera | null>(null);
    const isSceneReadyRef = useRef<boolean>(false);
    const lastCameraModeRef = useRef<string>(cameraModeRef.current);
    const lastScrollTargetRef = useRef<number>(scrollTargetRef.current);
    const lastScrollPercentageRef = useRef<number>(0);
    const frameCountRef = useRef<number>(0);
    const animatingToPathRef = useRef<boolean>(false);
  const lastUpdateTimeRef = useRef<number>(0);
  const fpsRef = useRef<number>(0);
  const autoPlayEnabledRef = useRef<boolean>(autoPlayEnabled);
  const autoPlaySpeedRef = useRef<number>(autoPlaySpeed);
    const loopModeRef = useRef<boolean>(loopMode);
    const activeWaypointsRef = useRef<Set<number>>(new Set());
    const activeSoundsRef = useRef<Map<string, BABYLON.Sound>>(new Map());
    const isMutedRef = useRef<boolean>(false);
    const lightInstancesRef = useLights(sceneRef.current, lights);

    const captureScreenshot = async (): Promise<Blob> => {
      if (!engineRef.current || !canvasRef.current || !cameraRef.current) {
        throw new Error("Scene not ready for screenshot");
      }

      engineRef.current.performanceMonitor.enable();
      engineRef.current.performanceMonitor.disable();

      return new Promise((resolve, reject) => {
        try {
          const width = 300;
          const height = 200;

          BABYLON.Tools.CreateScreenshot(
            engineRef.current!,
            cameraRef.current!,
            { width, height },
            (data) => {
              console.log("Screenshot captured in babylon scene");
              fetch(data)
                .then((res) => res.blob())
                .then((blob) => {
                  if (props.onSceneCapture) {
                    props.onSceneCapture(blob);
                  }
                  resolve(blob);
                })
                .catch(reject);
            }
          );
        } catch (error) {
          reject(error);
        }
      });
    };

    useImperativeHandle(
      ref,
      () => ({
        captureScreenshot,
      }),
      []
    );

    // Handle double-click for edit camera
    const handleDoubleClick = (event: BABYLON.PointerInfo) => {
      if (
        cameraModeRef.current !== "edit" ||
        !editCameraRef.current ||
        !sceneRef.current
      )
        return;

      const pickResult = sceneRef.current.pick(
        sceneRef.current.pointerX,
        sceneRef.current.pointerY
      );

      if (pickResult.hit) {
        const targetPosition = pickResult.pickedPoint;
        if (targetPosition) {
          // Smoothly animate the camera target to the picked point
          BABYLON.Animation.CreateAndStartAnimation(
            "cameraTarget",
            editCameraRef.current,
            "target",
            60,
            30,
            editCameraRef.current.target,
            targetPosition,
            BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
          );
        }
      }
    };

    // Add ref for FPV camera visualization
    const fpvVisualizationRef = useRef<{
      root: BABYLON.Mesh | null;
      body: BABYLON.Mesh | null;
      lens: BABYLON.Mesh | null;
      direction: BABYLON.Mesh | null;
    }>({ root: null, body: null, lens: null, direction: null });
    // Create FPV camera visualization
    const createFPVVisualization = (scene: BABYLON.Scene) => {
      // Create camera body
      const body = BABYLON.MeshBuilder.CreateBox(
        "fpvCamera",
        {
          width: 0.2,
          height: 0.2,
          depth: 0.3,
        },
        scene
      );

      // Create lens
      const lens = BABYLON.MeshBuilder.CreateCylinder(
        "fpvLens",
        {
          height: 0.1,
          diameter: 0.1,
        },
        scene
      );
      lens.position.z = 0.2;
      lens.rotation.x = Math.PI / 2;

      // Create direction indicator
      const direction = BABYLON.MeshBuilder.CreateCylinder(
        "fpvDirection",
        {
          height: 0.3,
          diameterTop: 0,
          diameterBottom: 0.05,
        },
        scene
      );
      direction.position.z = 0.3;
      direction.rotation.x = Math.PI / 2;

      // Create root mesh for the group
      const root = new BABYLON.Mesh("fpvRoot", scene);
      body.parent = root;
      lens.parent = body;
      direction.parent = body;

      // Create materials
      const bodyMaterial = new BABYLON.StandardMaterial(
        "fpvCameraMaterial",
        scene
      );
      bodyMaterial.diffuseColor = new BABYLON.Color3(0.2, 0.2, 0.2);
      bodyMaterial.specularColor = new BABYLON.Color3(0.3, 0.3, 0.3);

      const lensMaterial = new BABYLON.StandardMaterial(
        "fpvLensMaterial",
        scene
      );
      lensMaterial.diffuseColor = new BABYLON.Color3(0.1, 0.1, 0.1);

      const directionMaterial = new BABYLON.StandardMaterial(
        "fpvDirectionMaterial",
        scene
      );
      directionMaterial.diffuseColor = new BABYLON.Color3(1, 0, 0);

      body.material = bodyMaterial;
      lens.material = lensMaterial;
      direction.material = directionMaterial;

      // Make meshes unselectable for picking
      root.isPickable = false;
      body.isPickable = false;
      lens.isPickable = false;
      direction.isPickable = false;

      // Store references
      fpvVisualizationRef.current = {
        root,
        body,
        lens,
        direction,
      };

      // Initially hide the visualization
      root.setEnabled(false);
    };

    // Update FPV camera visualization
    const updateFPVVisualization = () => {
      const { root } = fpvVisualizationRef.current;
      if (!root || !cameraRef.current) return;

      const camera = cameraRef.current;

      // Update position
      root.position = camera.position.clone();

      // Update rotation based on camera's rotation quaternion
      if (camera.rotationQuaternion) {
        root.rotationQuaternion = camera.rotationQuaternion.clone();
      }
    };

    // Switch between cameras based on mode
    const switchCamera = (mode: CameraMode) => {
      if (!sceneRef.current) return;

      if (mode === "edit") {
        if (!editCameraRef.current) {
          // Create arc rotate camera if it doesn't exist
          const camera = new BABYLON.ArcRotateCamera(
            "editCamera",
            0,
            Math.PI / 3,
            10,
            BABYLON.Vector3.Zero(),
            sceneRef.current
          );
          camera.attachControl(canvasRef.current!, true);
          camera.wheelPrecision = 50;
          camera.pinchPrecision = 50;
          camera.lowerRadiusLimit = 1;
          camera.upperRadiusLimit = 100;
          camera.checkCollisions = true;
          editCameraRef.current = camera;
        }

        if (cameraRef.current) {
          // Copy position from universal camera
          const direction = cameraRef.current.getDirection(
            BABYLON.Vector3.Forward()
          );
          const alpha = Math.atan2(direction.z, direction.x) + Math.PI / 2;
          const beta = Math.acos(direction.y);
          editCameraRef.current.alpha = alpha;
          editCameraRef.current.beta = beta;
          editCameraRef.current.radius = 10;
        }

        // Create FPV visualization if it doesn't exist
        if (!fpvVisualizationRef.current.root && sceneRef.current) {
          createFPVVisualization(sceneRef.current);
        }

        // Show FPV visualization
        if (fpvVisualizationRef.current.root) {
          fpvVisualizationRef.current.root.setEnabled(true);
          updateFPVVisualization();
        }

        editCameraRef.current.attachControl(canvasRef.current!, true);
        sceneRef.current.activeCamera = editCameraRef.current;
      } else {
        if (cameraRef.current) {
          cameraRef.current.attachControl(canvasRef.current!, true);
          sceneRef.current.activeCamera = cameraRef.current;
        }

        // Hide FPV visualization
        if (fpvVisualizationRef.current.root) {
          fpvVisualizationRef.current.root.setEnabled(false);
        }
      }
    };

    const playAudio = (
      audioData: AudioInteractionData,
      waypointIndex: number
    ) => {
      if (isMutedRef.current) return;

      // Use AudioManager to play sound
      AudioManager.playSound(audioData, {
        scene: sceneRef.current ?? undefined,
        position: waypoints[waypointIndex]
          ? new BABYLON.Vector3(
              waypoints[waypointIndex].x,
              waypoints[waypointIndex].y,
              waypoints[waypointIndex].z
            )
          : undefined,
      });
    };

    // Function to stop audio
    const stopAudio = (id: string) => {
      AudioManager.stopSound(id);
    };

    // Function to execute interactions
    const executeInteractions = (
      interactions: Interaction[],
      waypointIndex: number
    ) => {
      interactions.forEach((interaction) => {
        switch (interaction.type) {
          case "audio":
            playAudio(interaction.data as AudioInteractionData, waypointIndex);
            break;
          case "info":
            const infoData = interaction.data as InfoInteractionData;
            setInfoPopupText(infoData.text);
            break;
        }
      });
    };

    // Function to reverse interactions
    const reverseInteractions = (interactions: Interaction[]) => {
      interactions.forEach((interaction) => {
        switch (interaction.type) {
          case "audio":
            const audioData = interaction.data as AudioInteractionData;
            if (AudioManager.isSoundPlaying(audioData.id)) {
              stopAudio(audioData.id);
            }
            break;
          case "info":
            setInfoPopupText(null);
            break;
        }
      });
    };

    // Function to update active waypoint info
    const updateActiveWaypoint = () => {
      if (!cameraRef.current) return;

      let closestWaypoint = null as Waypoint | null;
      let minDistance = Infinity;

      waypoints.forEach((wp, index) => {
        const distance = BABYLON.Vector3.Distance(
          cameraRef.current!.position,
          new BABYLON.Vector3(wp.x, wp.y, wp.z)
        );

        if (distance < minDistance) {
          minDistance = distance;
          closestWaypoint = wp;
        }

        // Execute interactions when within trigger distance
        const triggerDistance = wp.triggerDistance ?? 1.0;
        if (distance <= triggerDistance) {
          if (!activeWaypointsRef.current.has(index)) {
            console.log(
              "Entering waypoint range:",
              wp.name || `Waypoint ${index + 1}`
            );
            activeWaypointsRef.current.add(index);
            setCurrentWaypointTitle(wp.name || "");

            // Handle interactions
            wp.interactions.forEach((interaction) => {
              if (interaction.type === "audio") {
                const audioData = interaction.data as AudioInteractionData;
                if (!sceneRef.current) return;

                // Check if sound is already playing
                if (!AudioManager.isSoundPlaying(audioData.id)) {
                  // Play sound using AudioManager
                  AudioManager.playSound(audioData, {
                    scene: sceneRef.current,
                    position: new BABYLON.Vector3(wp.x, wp.y, wp.z),
                  });
                }
              } else if (interaction.type === "info") {
                const infoData = interaction.data as InfoInteractionData;
                console.log("Setting info text:", infoData.text);
                setInfoPopupText(infoData.text);
              }
            });
          }
        } else {
          if (activeWaypointsRef.current.has(index)) {
            console.log(
              "Leaving waypoint range:",
              wp.name || `Waypoint ${index + 1}`
            );
            activeWaypointsRef.current.delete(index);

            // Clean up interactions
            wp.interactions.forEach((interaction) => {
              if (interaction.type === "audio") {
                const audioData = interaction.data as AudioInteractionData;
                // Only stop non-spatial sounds that have stopOnExit set to true
                if (audioData.stopOnExit) {
                  console.log("Stopping sound:", audioData.id);
                  AudioManager.stopSound(audioData.id);
                }
              }
            });

            if (closestWaypoint === wp) {
              setCurrentWaypointTitle("");
              setInfoPopupText(null);
            }
          }
        }
      });
    };

    // Cleanup sounds on unmount
    useEffect(() => {
      return () => {
        AudioManager.stopAllSounds();
      };
    }, []);

    useEffect(() => {
      if (!canvasRef.current) return;

      if (!engineRef.current) {
        console.log("Creating new scene");
        setIsLoading(true);

        const canvas = canvasRef.current;
        const engine = new BABYLON.Engine(canvas, true);
        engineRef.current = engine;

        //if wasd or arrow keys focus on the canvas
        //dom event
        window.addEventListener("keydown",
          function(e) {
            //if wasd or arrow keys, and canvas is not focused
            if([32, 37, 38, 39, 40, 87, 65, 83, 68, 81, 69].indexOf(e.keyCode) > -1 && 
               document.activeElement !== canvas &&
               !(document.activeElement instanceof HTMLInputElement) &&
               !(document.activeElement instanceof HTMLTextAreaElement)) {
              e.preventDefault();
              canvas.focus();
              //resend the event to the canvas  
              canvas.dispatchEvent(new KeyboardEvent('keydown', e));
            }
          }, false
        );

        const scene = new BABYLON.Scene(engine);
        sceneRef.current = scene;
        const color = BABYLON.Color3.FromHexString(backgroundColor);
        scene.clearColor = new BABYLON.Color4(color.r, color.g, color.b, 1);

        const camera = new BABYLON.UniversalCamera(
          "camera",
          new BABYLON.Vector3(0, 5, -10),
          scene
        );

        camera.invertRotation = invertCameraRotation;
        camera.inverseRotationSpeed = 2;
        
        camera.fov = props.fov;

        cameraRef.current = camera;

        camera.setTarget(BABYLON.Vector3.Zero());
        camera.attachControl(canvas, true);
        camera.speed = cameraMovementSpeed;
        camera.angularSensibility = cameraRotationSensitivity;
        camera.rotationQuaternion = BABYLON.Quaternion.Identity();

        camera.keysUp.push(87); // W
        camera.keysDown.push(83); // S
        camera.keysLeft.push(65); // A
        camera.keysRight.push(68); // D
        camera.keysUpward.push(81); // Q
        camera.keysDownward.push(69); // E

        camera.inputs.addGamepad();
        camera.checkCollisions = true;
        camera.applyGravity = false;
        camera.ellipsoid = new BABYLON.Vector3(0.1, 0.1, 0.1);

   
        scene.debugLayer.hide();
        // Add keyboard observer for inspector debug
        scene.onKeyboardObservable.add((kbInfo) => {
          if (
            kbInfo.type === BABYLON.KeyboardEventTypes.KEYDOWN &&
            kbInfo.event.key.toLowerCase() === "i" &&
            kbInfo.event.ctrlKey
          ) {
            if (scene.debugLayer.isVisible()) {
              scene.debugLayer.hide();
            } else {
              scene.debugLayer.show();
            }
          }
        });

        // Add pointer observer for double-click
        scene.onPointerObservable.add((pointerInfo) => {
          if (pointerInfo.type === BABYLON.PointerEventTypes.POINTERDOUBLETAP) {
            handleDoubleClick(pointerInfo);
          }
        });

        // Watch for camera mode changes and update visualization
        scene.onBeforeRenderObservable.add(() => {
          if (cameraModeRef.current !== lastCameraModeRef.current) {
            console.log(
              "Camera mode changed:",
              lastCameraModeRef.current,
              "->",
              cameraModeRef.current
            );
            if(cameraModeRef.current === "walk" && cameraRef.current) {
              cameraRef.current.applyGravity = true;
              cameraRef.current.ellipsoid = new BABYLON.Vector3(1, playerHeight, 1);
            } else if(cameraRef.current) {
              cameraRef.current.applyGravity = false;
              cameraRef.current.ellipsoid = new BABYLON.Vector3(0.1, 0.1, 0.1);

            }

            switchCamera(cameraModeRef.current);
            lastCameraModeRef.current = cameraModeRef.current;
          }

          // Rest of the existing render loop code...
          const currentTime = performance.now();
          const deltaTime = currentTime - lastUpdateTimeRef.current;
          lastUpdateTimeRef.current = currentTime;
          const waypoints = waypointsRef.current;
          // Handle splat swapping based on scroll position
          // 1. Calculate current percentage
          const currentPercentage =
            (scrollPositionRef.current / (pathRef.current.length - 1)) * 100;

          // 2. Sort splats by ascending trigger percentage
          const sortedSplats = [...additionalSplatsRef.current].sort((a, b) => {
            const getComparisonValue = (splat: any) => {
              if (splat.percentage !== -1) return splat.percentage;
              if (splat.waypointIndex !== -1) {
                return (splat.waypointIndex / (waypoints.length - 1)) * 100;
              }
              // If a splat has no valid triggers, move it to the end
              return Number.MAX_SAFE_INTEGER;
            };
            return getComparisonValue(a) - getComparisonValue(b);
          });

          const savedModelUrl = savedModelUrlRef.current;

          const initialSplat = {url: savedModelUrl ?? '', name: 'initialSplat', waypointIndex: -1, percentage: 0} as SplatSwapPoint;
         // console.log("Initial splat:", initialSplat);
          const allSplats = [initialSplat, ...sortedSplats];
          // 3. Helper to get the numeric "trigger percentage" for a splat
          const getTriggerPercentage = (splat: any) => {
            if (splat.percentage !== -1) return splat.percentage;
            if (splat.waypointIndex !== -1) {
              return (splat.waypointIndex / (waypoints.length - 1)) * 100;
            }
            return -1; // or any invalid marker
          };

          // 4. Find the 'last splat' we have passed (trigger <= currentPercentage)
          const nextSplat = allSplats
            .filter((splat) => {
              const trigger = getTriggerPercentage(splat);
              return trigger !== -1 && trigger <= currentPercentage;
            })
            .pop(); // pop() will give us the last item in the filtered array

          // 5. If nextSplat differs from the currently loaded, swap it in. Otherwise, revert if needed.
          if (nextSplat && nextSplat.url !== loadedModelUrl) {
            //console.log("Switching to splat:", nextSplat.name || nextSplat.url);
            props.setLoadedModelUrl(nextSplat.url);
            props.setCurrentSplatIndex(
              additionalSplatsRef.current.indexOf(nextSplat)
            );
          } else if (!nextSplat && loadedModelUrl !== savedModelUrl) {
            console.log("Reverting to initial splat");
            props.setLoadedModelUrl(savedModelUrl ?? '');
            props.setCurrentSplatIndex(0);
          }

          // 6. Update last scroll percentage
          lastScrollPercentageRef.current = currentPercentage;

          // Update scroll position and camera for all modes except explore
          // and when not paused for splat loading
          if (
            cameraModeRef.current !== "explore" &&
            pathRef.current.length > 1 &&
            !scrollPausedRef.current
          ) {
            // Handle autoplay
            if (autoPlayEnabledRef.current) {
              const animationRatio = scene.getAnimationRatio();
              scrollTargetRef.current += autoPlaySpeedRef.current * animationRatio;

              // Handle looping in autoplay
              if (loopModeRef.current) {
                if (scrollTargetRef.current >= pathRef.current.length - 1.1) {
                  scrollTargetRef.current = 0.2;
                  scrollPositionRef.current = 0.2;
                }
              } else if (scrollTargetRef.current > pathRef.current.length - 1) {
                scrollTargetRef.current = pathRef.current.length - 1;
              }
            }

            const scrollDiff =
              scrollTargetRef.current - scrollPositionRef.current;

            // Check if there's active path navigation happening
            const isNavigatingPath = Math.abs(scrollDiff) > 0.001;

            if (isNavigatingPath) {
              if (cameraModeRef.current === "edit") {
                updateFPVVisualization();
              }

              if (cameraModeRef.current === "hybrid") {
                userControlRef.current = false;
              }

              const interpolationSpeed = transitionSpeedRef.current * 0.1;
              scrollPositionRef.current += scrollDiff * interpolationSpeed;

              // Handle looping
              if (loopMode) {
                if (scrollPositionRef.current >= pathRef.current.length - 1.1) {
                  scrollPositionRef.current = 0.2;
                  scrollTargetRef.current = 0.2;
                } else if (scrollPositionRef.current <= 0.1) {
                  scrollPositionRef.current = pathRef.current.length - 1.11;
                  scrollTargetRef.current = pathRef.current.length - 1.11;
                }
              } else {
                scrollPositionRef.current = Math.max(
                  0,
                  Math.min(
                    pathRef.current.length - 1,
                    scrollPositionRef.current
                  )
                );
              }
            }

            const currentIndex = Math.floor(scrollPositionRef.current);
            const nextIndex = Math.min(
              currentIndex + 1,
              pathRef.current.length - 1
            );
            const fraction = scrollPositionRef.current - currentIndex;

            // Get target position and rotation
            const currentPos = pathRef.current[currentIndex];
            const nextPos = pathRef.current[nextIndex];
            const currentRot = rotationsRef.current[currentIndex];
            const nextRot = rotationsRef.current[nextIndex];

            if (currentPos && nextPos && currentRot && nextRot) {
              const targetPosition = BABYLON.Vector3.Lerp(
                currentPos,
                nextPos,
                fraction
              );
              const targetRotation = BABYLON.Quaternion.Slerp(
                currentRot,
                nextRot,
                fraction
              );

              // Different pull strengths based on mode and state
              let pullStrength = 0;

              if (cameraModeRef.current === "tour") {
                pullStrength = 0.1; // Strong pull in tour mode
              } else if (
                cameraModeRef.current === "hybrid" &&
                !userControlRef.current
              ) {
                // Only apply pull in auto mode when not under user control
                pullStrength = 0.1;
              }

              if (pullStrength > 0) {
                camera.position = BABYLON.Vector3.Lerp(
                  camera.position,
                  targetPosition,
                  pullStrength
                );

                if (camera.rotationQuaternion) {
                  camera.rotationQuaternion = BABYLON.Quaternion.Slerp(
                    camera.rotationQuaternion,
                    targetRotation,
                    pullStrength
                  );
                }
              }
            }

            // Update scroll percentage
            const percentage =
              (scrollPositionRef.current / (pathRef.current.length - 1)) * 100;
            setScrollPercentage(Math.min(100, Math.max(0, percentage)));
          }

          // Update active waypoint info
          updateActiveWaypoint();
        });

        window.addEventListener("resize", () => {
          engine.resize();
        });

        // Enable performance monitor
        engine.performanceMonitor.enable();
        
        engine.runRenderLoop(() => {
          scene.render();
          // Update FPS
          const fps = Math.round(engine.getFps());
          fpsRef.current = fps;
        });

        scene.executeWhenReady(() => {
          console.log("Scene is ready");
          if (!isSceneReadyRef.current) {
            onSceneReady(scene, camera);
            isSceneReadyRef.current = true;

            setTimeout(() => {
              setIsLoading(false);
              console.log("Loading set to false");
            }, 750);
          }
        });
      }
    }, [
      cameraMovementSpeed,
      cameraRotationSensitivity,
      onSceneReady,
      setIsLoading,
      autoPlayEnabled,
      autoPlaySpeed,
      lights,
      fov
    ]);

    //update autoPlayEnabledRef
    useEffect(() => {
      autoPlayEnabledRef.current = autoPlayEnabled;
      autoPlaySpeedRef.current = autoPlaySpeed;
    }, [autoPlayEnabled, autoPlaySpeed]);

    //update loopModeRef
    useEffect(() => {
      loopModeRef.current = loopMode;
    }, [loopMode]);

    useEffect(() => {
      if (sceneRef.current) {
        console.log("Updating background color to:", backgroundColor);
        const color = BABYLON.Color3.FromHexString(backgroundColor);
        sceneRef.current.clearColor = new BABYLON.Color4(
          color.r,
          color.g,
          color.b,
          1
        );
      }
    }, [backgroundColor]);

    useEffect(() => {
      if (cameraRef.current) {
        cameraRef.current.speed = cameraMovementSpeed;
        cameraRef.current.angularSensibility = cameraRotationSensitivity;
      }
    }, [cameraMovementSpeed, cameraRotationSensitivity]);

    useEffect(() => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      // Prevent default wheel behavior on the canvas
      canvas.addEventListener(
        "wheel",
        (event: WheelEvent) => {
          event.preventDefault();
          event.stopPropagation();
        },
        { passive: false }
      );

      const handleWheel = (event: WheelEvent) => {
        if (!cameraRef.current || pathRef.current.length === 0) {
          return;
        }

        wheelHandler(
          event,
          animatingToPathRef,
          userControlRef,
          cameraRef.current,
          pathRef,
          rotationsRef.current,
          waypoints,
          animationFrames,
          scrollSpeed,
          scrollTargetRef,
          scrollPositionRef,
          isEditMode,
          cameraModeRef.current,
          loopMode
        );
      };

      canvas.addEventListener("wheel", handleWheel);

      // Update or add these event listeners
      if (sceneRef.current) {
        sceneRef.current.onPointerObservable.add((evt) => {
          if (evt.type === BABYLON.PointerEventTypes.POINTERDOWN) {
            if (
              cameraModeRef.current === "explore" ||
              cameraModeRef.current === "hybrid"
            ) {
              userControlRef.current = true;
            } else if (cameraModeRef.current === "tour") {
              userControlRef.current = false;
            }
          }
        });
      }
      window.addEventListener("keydown", () => {
        if (
          cameraModeRef.current === "explore" ||
          cameraModeRef.current === "hybrid"
        ) {
          userControlRef.current = true;
        } else if (cameraModeRef.current === "tour") {
          userControlRef.current = false;
        }
      });

      return () => {
        canvas.removeEventListener("wheel", handleWheel);
      };
    }, [
      pathRef,
      rotationsRef,
      scrollPositionRef,
      scrollTargetRef,
      userControlRef,
      cameraModeRef,
      waypoints,
      animationFrames,
      scrollSpeed,
      isEditMode,
      loopMode,
    ]);

    // Add updateActiveWaypoint to the render loop
    useEffect(() => {
      if (sceneRef.current) {
        const observer = sceneRef.current.onBeforeRenderObservable.add(() => {
          updateActiveWaypoint();
        });

        return () => {
          if (sceneRef.current) {
            sceneRef.current.onBeforeRenderObservable.remove(observer);
          }
        };
      }
    }, [waypoints]);

    useEffect(() => {
      if (cameraRef.current) {
        cameraRef.current.fov = props.fov;
      }
    }, [props.fov]);

    useEffect(() => {
      if (!sceneRef.current || !skyboxUrl) return;
      
      // Create PhotoDome for skybox
      const photoDome = new BABYLON.PhotoDome(
        "skybox",
        skyboxUrl,
        {
          resolution: 32,
          size: 1000
        },
        sceneRef.current
      );

      return () => {
        photoDome.dispose();
      };
    }, [skyboxUrl]);

    useEffect(() => {
      if (!cameraRef.current) return;
      
      // Invert rotation by setting these values to negative
      cameraRef.current.invertRotation = invertCameraRotation;

      // Note: For VR/AR apps you might also want to add:
      // camera.inputs.attached.mouse.invertRotation = invertRotation;
      
    }, [invertCameraRotation]);

    return (
      <>
        <canvas
          ref={canvasRef}
          style={{
            width: "100%",
            height: "100%",
            touchAction: "none",
            position: "relative",
            zIndex: "2",
          }}
        />
        <div style={{
          position: 'absolute',
          bottom: '50px',
          left: '10px',
          color: '#fff',
          fontSize: '12px',
          fontFamily: 'monospace',
          textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
          zIndex: 3
        }}>
          {fpsRef.current} FPS
        </div>
      </>
    );
  }
);

export default BabylonScene;
