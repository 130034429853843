/*
File: ExportPopup.tsx
Description: Combined component for exporting and uploading scene configurations
Last modified: 2024-02-20
Changes: 
- Added additionalSplats to props and export handler
- Merged upload functionality into ExportPopup
- Added mode flag for export-only vs full upload
- Added description field for upload mode
- Unified AR settings between modes
*/

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from 'uuid';
import { CameraMode, UIOptions, XRMode, AROptions, SplatSwapPoint } from "../types/SceneTypes";
import { UIType } from "../tools/html-generation/types";
import { Mesh } from "@babylonjs/core";

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
`;

const PopupContent = styled.div`
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 10px;
  width: 320px;
  color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 80vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #2c2c2c;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #555555;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #666666;
  }
`;

const Title = styled.h2`
  margin-top: 0;
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 15px;
`;

const Input = styled.input`
  width: 96%;
  padding: 8px;
  margin-bottom: 15px;
  background-color: #2c2c2c;
  border: 1px solid #555555;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #4caf50;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Checkbox = styled.input<{ disabled?: boolean }>`
  margin-right: 10px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? '0.5' : '1'};
`;

const CheckboxLabel = styled.label<{ disabled?: boolean }>`
  font-size: 14px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  color: ${props => props.disabled ? '#666666' : '#ffffff'};
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Button = styled.button<{ primary?: boolean }>`
  padding: 8px 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s;
  background-color: ${(props) => (props.primary ? "#4CAF50" : "#555555")};
  color: #ffffff;

  &:hover {
    background-color: ${(props) => (props.primary ? "#45a049" : "#666666")};
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  background-color: #2c2c2c;
  border: 1px solid #555555;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #4caf50;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  font-size: 16px;
  color: #ffffff;
  margin: 15px 0 10px 0;
`;

const ErrorMessage = styled.p`
  color: #ff5555;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
`;

const ProFeatureLabel = styled.span`
  background-color: #4CAF50;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 8px;
`;

const RadioGroup = styled.div`
  margin-bottom: 15px;
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const RadioInput = styled.input`
  margin-right: 10px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

const RadioLabel = styled.label<{ disabled?: boolean }>`
  font-size: 14px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  color: ${props => props.disabled ? '#666666' : '#ffffff'};
  display: flex;
  align-items: center;
`;

const DisabledText = styled.span`
  color: #666;
  font-style: italic;
  margin-left: 8px;
  font-size: 12px;
`;

const Description = styled.textarea`
  width: 94%;
  padding: 8px;
  margin-bottom: 10px;
  background-color: #2c2c2c;
  border: 1px solid #555555;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  resize: vertical;
  min-height: 80px;
  font-family: inherit;

  &:focus {
    outline: none;
    border-color: #4CAF50;
  }
`;

const ARSettingsContainer = styled.div`
  margin-top: 10px;
  background: #2a2a2a;
  padding: 10px;
  border-radius: 4px;
  font-size: 13px;
  max-width: 280px;
`;

const ARRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  & > div {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  label {
    margin-right: 5px;
    font-size: 12px;
  }
`;

const CompactInput = styled.input`
  width: 50px;
  padding: 4px;
  background-color: #2c2c2c;
  border: 1px solid #555555;
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;
  margin-right: 5px;

  &:focus {
    outline: none;
    border-color: #4caf50;
  }
`;

const ColorPicker = styled.input`
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  background: transparent;
  margin-right: 5px;
`;


interface ExportPopupProps {
  mode: 'export' | 'upload';
  collisionMeshesRef: React.MutableRefObject<Mesh[]>;
  playerHeight?: number;
  onExport?: (
    savedModelUrl: string,
    includeScrollControls: boolean,
    defaultCameraMode: CameraMode,
    allowedCameraModes: string[],
    includeXR: boolean,
    xrMode: XRMode,
    arOptions: AROptions,
    uiOptions: UIOptions,
    additionalSplats?: SplatSwapPoint[],
    keepMeshesInMemory?: boolean,
    playerHeight?: number
  ) => void;
  onUpload?: (
    description: string,
    includeXR: boolean,
    xrMode: XRMode,
    arOptions: AROptions,
    includeScrollControls: boolean,
    defaultCameraMode: CameraMode,
    allowedCameraModes: string[],
    uiOptions: UIOptions,
    sceneId: string,
    sceneTitle: string,
    additionalSplats?: SplatSwapPoint[],
    keepMeshesInMemory?: boolean // Added this parameter
  ) => void;
  onCancel: () => void;
  isModelLocal?: boolean;
  initialUIOptions: UIOptions;
  onUIOptionsChange: (options: UIOptions) => void;
  isPro: boolean;
  isBusiness: boolean;
  sceneTitle: string;
  setSceneTitle: (title: string) => void;
  loadedModelUrl?: string;
  setLoadedModelUrl?: (url: string) => void;
  savedModelUrl?: string;
  setSavedModelUrl?: (url: string) => void;
  isUpdate?: boolean;
  initialDescription?: string;
  initialSceneId?: string;
  additionalSplats?: SplatSwapPoint[];
  keepMeshesInMemory?: boolean;
}

const ExportPopup: React.FC<ExportPopupProps> = ({
  mode,
  onExport,
  onUpload,
  onCancel,
  isModelLocal = false,
  initialUIOptions,
  onUIOptionsChange,
  isPro,
  isBusiness,
  sceneTitle,
  setSceneTitle,
  loadedModelUrl = '',
  setLoadedModelUrl,
  isUpdate = false,
  initialDescription = '',
  initialSceneId,
  additionalSplats,
  keepMeshesInMemory, 
  savedModelUrl,
  collisionMeshesRef,
  playerHeight
}) => {
  const [localTitle, setLocalTitle] = useState(sceneTitle);
  const [description, setDescription] = useState(initialDescription);
  // Previously: includeScrollControls defaulted to true
  // Now we have hideNavigator instead, default false means show navigator
  const [hideNavigator, setHideNavigator] = useState(false);
  
  const [allowedCameraModes, setAllowedCameraModes] = useState<string[]>([
    "tour",
    "explore",
    "hybrid",
    collisionMeshesRef.current.filter(mesh => mesh.name.toLowerCase().includes("floor")).length > 0 ? "walk" : ""
  ]);
  const [defaultCameraMode, setDefaultCameraMode] = useState<CameraMode>("tour");
  const [includeXR, setIncludeXR] = useState(false);
  const [xrMode, setXRMode] = useState<XRMode>("ar");
  const [arOptions, setAROptions] = useState<AROptions>({
    enableHitTest: false,
    enablePlaneDetection: true,
    enableAnchors: true,
    enableBackgroundRemoval: false,
    convertWaypointsToHotspots: true,
    defaultARHotspotOptions: {
      textSize: 24,
      textColor: "#ffffff",
      backgroundColor: "#000000",
      displayDistance: 2,
      billboardMode: true
    }
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [uiOptions, setUIOptions] = useState<UIOptions>(initialUIOptions);
  const [sceneId] = useState(initialSceneId || uuidv4());


  useEffect(() => {
    setLocalTitle(sceneTitle);
  }, [sceneTitle]);

  useEffect(() => {
    if (!allowedCameraModes.includes(defaultCameraMode)) {
      if (allowedCameraModes.length > 0) {
        setDefaultCameraMode(allowedCameraModes[0] as CameraMode);
      } else {
        setDefaultCameraMode("tour");
      }
    }
  }, [allowedCameraModes, defaultCameraMode]);

  const toggleCameraMode = (mode: string) => {
    setAllowedCameraModes((prevModes) => {
      let newModes;
      if (prevModes.includes(mode)) {
        if (mode === defaultCameraMode) {
          setErrorMessage(
            "Cannot uncheck the default navigator option. Please select a different default option first."
          );
          return prevModes;
        }
        if (prevModes.length === 1) {
          setErrorMessage("At least one navigator option must be selected.");
          return prevModes;
        }
        newModes = prevModes.filter((m) => m !== mode);
      } else {
        newModes = [...prevModes, mode];
      }
      setErrorMessage("");
      return newModes;
    });
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setLocalTitle(newTitle);
    if (errorMessage && newTitle.trim()) {
      setErrorMessage("");
    }
  };

  const handleUIOptionsChange = (newOptions: Partial<UIOptions>) => {
    const updatedOptions = {
      ...uiOptions,
      ...newOptions
    };
    setUIOptions(updatedOptions);
    onUIOptionsChange(updatedOptions);
  };

  const handleSubmit = () => {
    //find collision mesh with floor in its name 
  const floorMesh = collisionMeshesRef.current.filter(mesh => mesh.name.toLowerCase().includes("floor"));
    // Ensure walk mode is only included if floor collision mesh exists
    if (allowedCameraModes.includes("walk") && !floorMesh) {
      setErrorMessage("Cannot export with walk mode enabled without a floor collision mesh");
      return;
    }
    console.log("handleSubmit: Additional splats:", additionalSplats);
    console.log("handleSubmit: Keep meshes in memory:", keepMeshesInMemory);
    if (!localTitle.trim()) {
      setErrorMessage("Please provide a title for the scene.");
      return;
    }
    
    if (mode === 'export') {
      if (isModelLocal && !loadedModelUrl) {
        setErrorMessage("Please provide a URL for the model.");
        return;
      }
      
          onExport?.(
            savedModelUrl || loadedModelUrl,
            !hideNavigator, // invert hideNavigator to pass includeScrollControls
            defaultCameraMode,
            allowedCameraModes,
            includeXR,
            xrMode,
            arOptions,
            uiOptions,
            additionalSplats,
            keepMeshesInMemory,
            playerHeight
          );
    } else {
      onUpload?.(
        description,
        includeXR,
        xrMode,
        arOptions,
        !hideNavigator, // invert
        defaultCameraMode,
        allowedCameraModes,
        uiOptions,
        sceneId,
        localTitle,
        additionalSplats,
        keepMeshesInMemory
      );
    }
  };

  return (
    <PopupOverlay>
    <PopupContent>
      <Title>
        {mode === 'export' ? 'Export Scene' : 
         isUpdate ? 'Update Scene Configuration' : 'Upload Scene Configuration'}
      </Title>

      <Section>
        <Input
          type="text"
          value={localTitle}
          onChange={handleTitleChange}
          placeholder="Enter scene title"
        />
      </Section>

      {mode === 'upload' && (
        <Section>
          <Description
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter a description for your scene..."
          />
        </Section>
      )}

      {mode === 'export' && isModelLocal && (
        <Section>
          <Input
            type="text"
            value={loadedModelUrl}
            onChange={(e) => setLoadedModelUrl?.(e.target.value)}
            placeholder="Enter model URL"
          />
        </Section>
      )}

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      {/* Navigator Layouts section */}
      <Section>
        <SectionTitle>Layouts</SectionTitle>
        <RadioGroup>
          <RadioContainer>
            <RadioInput
              type="radio"
              id="standardUI"
              name="uiType"
              value={UIType.Standard}
              checked={uiOptions.uiType === UIType.Standard}
              onChange={() => handleUIOptionsChange({ uiType: UIType.Standard })}
            />
            <RadioLabel htmlFor="standardUI">Standard</RadioLabel>
          </RadioContainer>

          <RadioContainer>
            <RadioInput
              type="radio"
              id="minimalUI"
              name="uiType"
              value={UIType.Minimal}
              checked={uiOptions.uiType === UIType.Minimal}
              onChange={() => handleUIOptionsChange({ uiType: UIType.Minimal })}
            />
            <RadioLabel htmlFor="minimalUI">Minimal</RadioLabel>
          </RadioContainer>

          <RadioContainer>
            <RadioInput
              type="radio"
              id="proUI"
              name="uiType"
              value={UIType.Pro}
              checked={uiOptions.uiType === UIType.Pro}
       
              onChange={() => handleUIOptionsChange({ uiType: UIType.Pro })}
            />
            <RadioLabel htmlFor="proUI" >
              Dark
            </RadioLabel>
          </RadioContainer>
        </RadioGroup>

        {/* Hide Navigator */}
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={hideNavigator}
            onChange={() => setHideNavigator(!hideNavigator)}
            id="hideNavigator"
          />
          <CheckboxLabel htmlFor="hideNavigator">
            Hide Navigator
          </CheckboxLabel>
        </CheckboxContainer>

        {/* Waypoint Info Style */}
        <SectionTitle>Waypoint Info Style</SectionTitle>
        <Select
          value={uiOptions.infoPosition || 'controls'}
          onChange={(e) =>
            handleUIOptionsChange({
              infoPosition: e.target.value as 'controls' | 'popup',
            })
          }
        >
          <option value='controls'>Standard</option>
          <option value='popup'>Pop-up</option>
        </Select>

        {/* Show start experience button with tooltip */}
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={uiOptions.showStartExperience}
            onChange={() => handleUIOptionsChange({ showStartExperience: !uiOptions.showStartExperience })}
            id="showStartExperience"
          />
          <CheckboxLabel
            htmlFor="showStartExperience"
            title="Ensure a click to allow sounds to play on iOS"
          >
            Show Start Experience Button
          </CheckboxLabel>
        </CheckboxContainer>

        {/* Debug Mode */}
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={uiOptions.debugMode}
            onChange={() => handleUIOptionsChange({ debugMode: !uiOptions.debugMode })}
            id="debugMode"
          />
          <CheckboxLabel htmlFor="debugMode">
            Enable FPS Counter + Inspector (Ctrl+I)
          </CheckboxLabel>
        </CheckboxContainer>

        {/* Hide Watermark for Business */}
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={uiOptions.hideWatermark}
            onChange={() => handleUIOptionsChange({ hideWatermark: !uiOptions.hideWatermark })}
            id="hideWatermark"
            disabled={!isBusiness}
          />
          <CheckboxLabel htmlFor="hideWatermark" disabled={!isBusiness}>
            Hide Watermark
            <ProFeatureLabel>BUSINESS</ProFeatureLabel>
            {!isBusiness && <DisabledText>(Upgrade to Business to unlock)</DisabledText>}
          </CheckboxLabel>
        </CheckboxContainer>
      </Section>

      {/* Default Navigator Options */}
      <Section>
        <SectionTitle>Default Navigator Mode</SectionTitle>
        <Select
          value={defaultCameraMode}
          onChange={(e) =>
            setDefaultCameraMode(e.target.value as CameraMode)
          }
        >
          {allowedCameraModes.map((mode) => (
            <option key={mode} value={mode}>
              {mode.charAt(0).toUpperCase() + mode.slice(1)}
            </option>
          ))}
        </Select>

        <SectionTitle>Allowed Navigator Modes</SectionTitle>
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={allowedCameraModes.includes("tour")}
            onChange={() => toggleCameraMode("tour")}
            id="allowTourMode"
          />
          <CheckboxLabel htmlFor="allowTourMode">Tour Mode</CheckboxLabel>
        </CheckboxContainer>

        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={allowedCameraModes.includes("explore")}
            onChange={() => toggleCameraMode("explore")}
            id="allowExploreMode"
          />
          <CheckboxLabel htmlFor="allowExploreMode">
            Explore Mode
          </CheckboxLabel>
        </CheckboxContainer>

        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={allowedCameraModes.includes("hybrid")}
            onChange={() => toggleCameraMode("hybrid")}
            id="allowAutoMode"
          />
          <CheckboxLabel htmlFor="allowAutoMode">Hybrid Mode</CheckboxLabel>
        </CheckboxContainer>

        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={allowedCameraModes.includes("walk")}
            onChange={() => toggleCameraMode("walk")}
            id="allowWalkMode"
            disabled={collisionMeshesRef.current.length === 0}
          />
            <CheckboxLabel 
              htmlFor="allowWalkMode"
              title={collisionMeshesRef.current.filter(mesh => mesh.name.toLowerCase().includes("floor")).length === 0 ? "Add a floor collision mesh to enable walk mode" : "Enable first-person walk mode with gravity and collision"}
              disabled={collisionMeshesRef.current.filter(mesh => mesh.name.toLowerCase().includes("floor")).length === 0}
            >
              Walk Mode
              {collisionMeshesRef.current.length === 0 ? 
                <DisabledText>(Add floor collision mesh)</DisabledText> :
                <DisabledText>(First-person mode with gravity)</DisabledText>
              }
            </CheckboxLabel>
        </CheckboxContainer>
      </Section>

      {/* Experimental Options */}
      <Section>
        <SectionTitle>Experimental Options</SectionTitle>
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={includeXR}
            onChange={() => {
              setIncludeXR(!includeXR);
              if (!includeXR) {
                setXRMode("ar");
              }
            }}
            id="includeXR"
          />
          <CheckboxLabel htmlFor="includeXR">
            Include XR Support (Experimental)
          </CheckboxLabel>
        </CheckboxContainer>

        {includeXR && xrMode === "ar" && (
          <ARSettingsContainer>
            <SectionTitle>AR Settings</SectionTitle>

            <CheckboxContainer>
              <Checkbox
                type="checkbox"
                checked={arOptions.convertWaypointsToHotspots}
                onChange={() => setAROptions({
                  ...arOptions,
                  convertWaypointsToHotspots: !arOptions.convertWaypointsToHotspots
                })}
                id="convertWaypoints"
              />
              <CheckboxLabel htmlFor="convertWaypoints">
                Convert Waypoints to AR Hotspots
              </CheckboxLabel>
            </CheckboxContainer>

            <SectionTitle>AR Hotspot Appearance</SectionTitle>
            <ARRow>
              <div>
                <label>Text Size</label>
                <CompactInput
                  type="number"
                  value={arOptions.defaultARHotspotOptions?.textSize || 24}
                  onChange={(e) => setAROptions({
                    ...arOptions,
                    defaultARHotspotOptions: {
                      ...arOptions.defaultARHotspotOptions,
                      textSize: parseInt(e.target.value)
                    }
                  })}
                  min="12"
                  max="48"
                />
              </div>
              <div>
                <label>Text</label>
                <ColorPicker
                  type="color"
                  value={arOptions.defaultARHotspotOptions?.textColor || "#ffffff"}
                  onChange={(e) => setAROptions({
                    ...arOptions,
                    defaultARHotspotOptions: {
                      ...arOptions.defaultARHotspotOptions,
                      textColor: e.target.value
                    }
                  })}
                />
              </div>
              <div>
                <label>Bg</label>
                <ColorPicker
                  type="color"
                  value={arOptions.defaultARHotspotOptions?.backgroundColor || "#000000"}
                  onChange={(e) => setAROptions({
                    ...arOptions,
                    defaultARHotspotOptions: {
                      ...arOptions.defaultARHotspotOptions,
                      backgroundColor: e.target.value
                    }
                  })}
                />
              </div>
            </ARRow>

            <CheckboxContainer>
              <Checkbox
                type="checkbox"
                checked={arOptions.defaultARHotspotOptions?.billboardMode !== false}
                onChange={() => setAROptions({
                  ...arOptions,
                  defaultARHotspotOptions: {
                    ...arOptions.defaultARHotspotOptions,
                    billboardMode: !arOptions.defaultARHotspotOptions?.billboardMode
                  }
                })}
                id="billboardMode"
              />
              <CheckboxLabel htmlFor="billboardMode">
                Always Face Camera
              </CheckboxLabel>
            </CheckboxContainer>
          </ARSettingsContainer>
        )}

        {includeXR && (
          <Select
            value={xrMode}
            onChange={(e) => setXRMode(e.target.value as XRMode)}
            style={{ marginTop: '10px' }}
          >
            <option value="ar">AR Mode</option>
            <option value="vr">VR Mode</option>
          </Select>
        )}
      </Section>

      <ButtonContainer>
        <Button onClick={onCancel}>Cancel</Button>
        <Button primary onClick={handleSubmit}>
          {mode === 'export' ? 'Export' : isUpdate ? 'Update' : 'Upload'}
        </Button>
      </ButtonContainer>
    </PopupContent>
  </PopupOverlay>
  );
};

export default ExportPopup;
