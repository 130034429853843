/*
File: template-minimal.ts
Description: A minimal visual style version of the HTML template that maintains all functionality
Created: 2024-02-08
Last Modified: 2024-02-14
Changes: 
- Fixed waypoint info positioning to top of screen
- Improved UI element centering
- Adjusted z-index values for proper layering
- Removed inline styles in favor of generateStylesMinimal from styles.ts
- Added selected state for camera mode buttons
- Added conditional rendering for waypoint info div
- Added hover states for prev and next buttons
- Added collapsible help section with ? icon
- Removed movement instructions overlay
- Added drone/walk mode toggle system
*/

import { GenerateHTMLProps } from './types';
import { generateStylesMinimal } from './styles-minimal';
import { generateWaypointInteractionCode } from './waypointInteractions';
import { generateAnalyticsCode } from './analytics';
import { generateMainScript } from './mainScript';
import { isMobileDevice } from './helpers';

const generateWatermark = (userName: string, sceneId: string): string => {
  return `
    <div class="storysplat-watermark" style="
      position: fixed;
      bottom: 5px;
      right: 5px;
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      padding: 3px 6px;
      border-radius: 3px;
      font-size: 10px;
      z-index: 1000;
      pointer-events: none;
      font-family: system-ui, -apple-system, sans-serif;
    ">
      Created with <a href="https://storysplat.com?ref=${sceneId}" target="_blank" 
        style="color: #4CAF50; text-decoration: none; pointer-events: auto;">
        StorySplat
      </a>
    </div>
  `;
};

export const generateTemplate = (props: GenerateHTMLProps): string => {
  const {
    includeScrollControls,
    uiOptions,
    uiColor,
    name,
    sceneId,
    userId,
    userName,
    defaultCameraMode,
    autoPlayEnabled,
    scrollButtonMode,
    scrollAmount,
    allowedCameraModes
  } = props;

  const showStartExperience = uiOptions.showStartExperience;

  return `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <title>${name}</title>
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
  <meta property="og:title" content="${name}">
  <meta property="og:type" content="website">
  <meta property="og:image" content="${props.thumbnailUrl}">
  <meta property="og:url" content="">
  <script>
    document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
  </script>
  <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
  <style>
    ${generateStylesMinimal({
      uiColor,
      isMobileDevice: isMobileDevice(),
      uiOptions
    })}
    
    #prevButton, #nextButton {
      transition: background-color 0.3s, color 0.3s;
    }
    #prevButton:hover, #nextButton:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    #helpButton {
      position: fixed;
      top: 10px;
      left: 10px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0);
      color: white;
      border: none;
      cursor: pointer;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10000;
      transition: background-color 0.3s;
    }

    #helpPanel {
      position: fixed;
      top: 100px;
      left: 10px;
      background: rgba(0, 0, 0, 0.8);
      color: white;
      padding: 15px;
      border-radius: 8px;
      max-width: 300px;
      z-index: 999;
      display: none;
      font-family: system-ui, -apple-system, sans-serif;
      font-size: 14px;
    }

    #helpPanel.visible {
      display: block;
    }

    #helpPanel h3 {
      margin: 0 0 10px 0;
      font-size: 16px;
    }

    #helpPanel p {
      margin: 5px 0;
      line-height: 1.4;
    }

    #exploreToggleContainer {
      position: fixed;
      bottom: 60px;
      right: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      z-index: 1000;
      height: 90px;
    }

    #droneToggleButton {
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      transition: all 0.3s ease;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #droneToggleButton svg {
      width: 24px;
      height: 24px;
      fill: currentColor;
    }

    #droneToggleButton.selected {
      background-color: ${uiColor};
    }

    #droneToggleButton:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }

    #walkToggleButton {
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      transition: all 0.3s ease;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #walkToggleButton svg {
      width: 24px;
      height: 24px;
      fill: currentColor;
    }

    #walkToggleButton.selected {
      background-color: ${uiColor};
    }

    #walkToggleButton:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
  </style>
  <script async src="https://www.googletagmanager.com/gtag/js?id=G-L2NG77PKGG"></script>
  <script>
    ${generateAnalyticsCode({
      sceneId,
      userId,
      sceneName: name,
      userName
    })}
  </script>
</head>
<body>
  <button id="helpButton" title="Toggle Help">?</button>
  <div id="helpPanel">
    <h3>Controls & Help</h3>
    <p><strong>Camera Modes:</strong></p>
    <p>• Tour - Follow predefined path</p>
    <p>• Explore - Free movement</p>
    <p>• Hybrid - Mix of both</p>
    ${allowedCameraModes.includes('walk') ? '<p>• Walk - First-person mode with gravity</p>' : ''}
    <p><strong>Navigation:</strong></p>
    <p>• Mouse/Touch - Look around</p>
    <p>• WASD/Arrows - Move camera</p>
    ${!allowedCameraModes.includes('walk') ? '<p>• Q/E - Move up/down</p>' : ''}
    <p>• Scroll - Move along path</p>
    ${allowedCameraModes.includes('walk') ? '<p>• Enter walk mode in explore mode to walk with gravity</p>' : ''}
  </div>

  <script>
    window.cameraMode = '${defaultCameraMode}';
    window.autoPlayEnabled = ${autoPlayEnabled};
  </script>

  <div id="preloader">
    <div id="preloader-content">
      <div id="preloader-media">
        <img id="preloader-image" src="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Fimages%2FStorySplat.webp?alt=media&token=953e8ab3-1865-4ac1-a98d-b548b7066bda" alt="StorySplat Logo" />
        <lottie-player id="preloader-lottie" 
          src="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Flotties%2FstorySplatLottie.json?alt=media&token=d7edc19d-9cb8-4c6e-a94c-cba1d2b65d5e"
          background="transparent" 
          speed="1" 
          loop 
          autoplay>
        </lottie-player>
      </div>
    </div>
  </div>

  <button id="fullscreenButton" title="Toggle Fullscreen">
    <svg id="expandIcon" viewBox="0 0 24 24">
      <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"/>
    </svg>
    <svg id="compressIcon" viewBox="0 0 24 24" style="display: none;">
      <path d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"/>
    </svg>
  </button>

  ${showStartExperience ? `
  <div id="startButtonContainer">
    <button id="startButton">Start Experience</button>
  </div>
  ` : ''}

  <canvas id="renderCanvas"></canvas>

  <div id="hotspotContent"></div>
  <div id="infoPopup"></div>
  
  <div id="waypointInfo" style="display: none;"></div>

  ${includeScrollControls
    ? `
    <div id="scrollControls">
      <div id="scrollControlsContent">
        <div id="scrollPercentage">0%</div>
        <div id="progressBarContainer">
          <div id="progressBar"></div>
        </div>
        ${uiOptions.buttonPosition === "inline"
          ? `
        <div id="scrollControlsContentInline">
          <div id="scrollButtons">
            <button id="prevButton" class="button" onclick="handleButtonScroll(-1)">
              <span class="button-icon">←</span>
              <span class="button-text">${scrollButtonMode === 'percentage' ? `${scrollAmount}%` : 'Prev'}</span>
            </button>
            <button id="nextButton" class="button" onclick="handleButtonScroll(1)">
              <span class="button-text">${scrollButtonMode === 'percentage' ? `${scrollAmount}%` : 'Next'}</span>
              <span class="button-icon">→</span>
            </button>
          </div>
        </div>
        `
          : `
        <div id="scrollButtons">
          <button id="prevButton" class="button" onclick="handleButtonScroll(-1)">
            <span class="button-icon">←</span>
            <span class="button-text">${scrollButtonMode === 'percentage' ? `${scrollAmount}%` : 'Prev'}</span>
          </button>
          <button id="nextButton" class="button" onclick="handleButtonScroll(1)">
            <span class="button-text">${scrollButtonMode === 'percentage' ? `${scrollAmount}%` : 'Next'}</span>
            <span class="button-icon">→</span>
          </button>
        </div>
        `}
      </div>
      <div id="modeToggleContainer">
        <div id="modeToggle">
                  ${allowedCameraModes.includes('walk') && !allowedCameraModes.includes('explore') ? 
            `<button id="modeWalk" class="mode-button" onclick="enableWalkMode()? changeCameraMode('walk') : null">Walk</button>` : ''}
          ${allowedCameraModes.includes('explore') ? 
            `<button id="modeExplore" class="mode-button" onclick="changeCameraMode('explore')">Explore</button>` : ''}
          ${allowedCameraModes.includes('tour') ? 
            `<button id="modeTour" class="mode-button" onclick="changeCameraMode('tour')">Tour</button>` : ''}
          ${allowedCameraModes.includes('hybrid') ? 
            `<button id="modeHybrid" class="mode-button" onclick="changeCameraMode('hybrid')">Hybrid</button>` : ''}
        </div>
      </div>
    </div>
    `
    : `
    <div id="modeToggleContainer">
      <div id="modeToggle">
                ${allowedCameraModes.includes('walk') && !allowedCameraModes.includes('explore') ? 
            `<button id="modeWalk" class="mode-button" onclick="enableWalkMode()? changeCameraMode('walk') : null">Walk</button>` : ''}
        ${allowedCameraModes.includes('explore') ? 
          `<button id="modeExplore" class="mode-button" onclick="changeCameraMode('explore')">Explore</button>` : ''}
        ${allowedCameraModes.includes('tour') ? 
          `<button id="modeTour" class="mode-button" onclick="changeCameraMode('tour')">Tour</button>` : ''}
        ${allowedCameraModes.includes('hybrid') ? 
          `<button id="modeHybrid" class="mode-button" onclick="changeCameraMode('hybrid')">Hybrid</button>` : ''}
      </div>
    </div>
    `}

  ${allowedCameraModes.includes('walk') && allowedCameraModes.includes('explore') ? `
    <div id="exploreToggleContainer">
      <button id="droneToggleButton" title="Explore Mode">
       <svg width="800px" height="800px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--gis" preserveAspectRatio="xMidYMid meet"><path d="M19.53 28.49a3.852 3.852 0 0 0-3.533 2.343C6.706 31.364-.029 32.257 0 32.955c.027.693 6.712.997 15.928.724c.32.862.936 1.58 1.738 2.027H16.17v2.742h-1.83a.874.874 0 0 0-.875.874v1.954c0 .483.391.874.874.874h12.316c3.103.73 3.45 1.843 5.774 3.88c-.38 2.113-.94 4.42-1.378 6.414v16.973a2.092 2.092 0 1 0 4.185 0V61.21c-.048-6.9 1.066-9.69 4.905-15.031l.965-.448c0 4.146 2.866 4.395 6.908 5.32h-3.036c-.924 0-1.674.75-1.674 1.675v10c0 .924.75 1.674 1.674 1.674h10.044c.924 0 1.674-.75 1.674-1.674v-10c0-.925-.75-1.674-1.674-1.674h-3.033c4.041-.928 6.905-1.176 6.905-5.321l.965.448c4.857 5.026 4.905 8.447 4.905 15.03v8.207a2.092 2.092 0 0 0 4.185 0V52.444c-.513-2.191-1.062-4.487-1.58-6.762c2.199-2.155 3.101-2.64 5.956-3.532h12.336a.874.874 0 0 0 .874-.874v-1.954a.874.874 0 0 0-.874-.874H83.83v-2.742h-1.496a3.852 3.852 0 0 0 1.738-2.027c9.216.273 15.901-.031 15.928-.724c.029-.698-6.706-1.59-15.997-2.122a3.852 3.852 0 0 0-6.943-.302c-9.307-.283-16.103.018-16.142.716c-.029.693 6.615 1.58 15.827 2.112a3.852 3.852 0 0 0 1.839 2.347h-1.496v2.742C67.654 38.426 60.352 33.685 50 33.49c-10.003.212-18.38 4.958-27.088 4.958v-2.742h-1.496a3.852 3.852 0 0 0 1.839-2.347c9.212-.532 15.856-1.42 15.827-2.112c-.039-.698-6.835-1-16.142-.716a3.852 3.852 0 0 0-3.41-2.04zM50 53.503c2.347 0 4.276 1.929 4.276 4.276c0 2.347-1.929 4.277-4.276 4.277c-2.347 0-4.278-1.93-4.278-4.277c0-2.347 1.93-4.276 4.278-4.276zm0 2.51c-.99 0-1.767.776-1.767 1.766s.777 1.766 1.767 1.766c.99 0 1.765-.776 1.765-1.766S50.99 56.013 50 56.013z" fill="#ffffff"></path></svg>
      </button>
      <button id="walkToggleButton" title="Walk Mode">
        <svg viewBox="0 0 24 24">
          <path d="M13.5 5.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM9.8 8.9L7 23h2.1l1.8-8 2.1 2v6h2v-7.5l-2.1-2 .6-3C14.8 12 16.8 13 19 13v-2c-1.9 0-3.5-1-4.3-2.4l-1-1.6c-.4-.6-1-1-1.7-1-.3 0-.5.1-.8.1L6 8.3V13h2V9.6l1.8-.7"/>
        </svg>
      </button>
    </div>` : ''}
  <button id="muteButton">🔊 Mute</button>

  ${generateWatermark(userName, sceneId)}

  <script src="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Fbabylon%2Fcdn%2Fbabylon-7.38.0.js?alt=media&token=848987a3-68a6-47b5-8f7b-0e42185b7a09" onerror="this.onerror=null;this.src='https://cdn.babylonjs.com/babylon.js'"></script>
  <script src="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Fbabylon%2Fcdn%2Fbabylonjs.loaders.min.js?alt=media&token=789d9d2f-c308-419c-9899-2650e62bdae1" onerror="this.onerror=null;this.src='https://preview.babylonjs.com/loaders/babylonjs.loaders.min.js'"></script>

  <script>
    ${generateWaypointInteractionCode({ uiOptions })}
    ${generateMainScript(props)}

    const helpButton = document.getElementById('helpButton');
    const helpPanel = document.getElementById('helpPanel');
    let helpVisible = false;

    helpButton.addEventListener('click', () => {
      helpVisible = !helpVisible;
      helpPanel.classList.toggle('visible', helpVisible);
    });

    document.addEventListener('click', (event) => {
      if (helpVisible && !helpPanel.contains(event.target) && event.target !== helpButton) {
        helpVisible = false;
        helpPanel.classList.remove('visible');
      }
    });

    function updateCameraModeButtons() {
      const buttons = document.querySelectorAll('.mode-button');
      buttons.forEach(button => {
        const mode = button.id.replace('mode', '').toLowerCase();
        button.classList.toggle('selected', mode === window.cameraMode || 
          (mode === 'explore' && (window.cameraMode === 'explore' || window.cameraMode === 'walk')));
      });

      // Update scroll controls visibility based on camera mode
      const scrollControls = document.getElementById('scrollControls');
      if (scrollControls) {
        scrollControls.classList.toggle('visible', window.cameraMode !== 'explore' && window.cameraMode !== 'walk');
      }

      // Update explore toggle buttons visibility and state
      const droneToggleButton = document.getElementById('droneToggleButton');
      const walkToggleButton = document.getElementById('walkToggleButton');
      
      if (droneToggleButton && walkToggleButton) {
        droneToggleButton.classList.toggle('selected', window.cameraMode === 'explore');
        walkToggleButton.classList.toggle('selected', window.cameraMode === 'walk');
      }
    }

    // Handle explore/walk mode buttons
    const droneToggleButton = document.getElementById('droneToggleButton');
    const walkToggleButton = document.getElementById('walkToggleButton');

    if (droneToggleButton) {
      droneToggleButton.addEventListener('click', () => {
        if (window.cameraMode === 'walk') {
          disableWalkMode();
        }
        changeCameraMode('explore');
      });
    }

    if (walkToggleButton) {
      walkToggleButton.addEventListener('click', () => {
        if (window.cameraMode !== 'walk' && enableWalkMode()) {
          changeCameraMode('walk');
        }
      });
    }

    const originalChangeCameraMode = window.changeCameraMode;
    window.changeCameraMode = function(mode) {
      originalChangeCameraMode(mode);
      updateCameraModeButtons();
    };

    updateCameraModeButtons();
  </script>
</body>
</html>
  `;
};
