/*
File: splatSystem.ts
Description: Handles splat model swapping based on waypoint progress.
             Includes a top-level toggle to choose between:
             - Option 1: Retain old splat meshes (just hide/show)
             - Option 2: Dispose & re-import old splats
             Now also includes a background preloader system to load next splats
             without displaying the loading screen each time.

Last modified: 2024-02-21
Changes:
- Added keepMeshesInMemory toggle to switch between approaches.
- Retains existing code structure and logs.
- Added a background preloader system (preloadSplat, preloadNextSplats).
- Hides the preloader for additional splat loads (only shows on main load).
*/

import { GenerateHTMLProps } from '../types';

export const generateSplatSystem = (props: GenerateHTMLProps): string => {
  return `
    // ---------------------------------------------
    // SPLAT SYSTEM with toggle for disposal vs. retain
    // and a background preloader for next splats
    // ---------------------------------------------
    const additionalSplats = ${JSON.stringify(props.additionalSplats)};
    const keepMeshesInMemory = ${props.keepMeshesInMemory ?? false}; // top-level toggle
    const useNodeMaterial = ${props.useNodeMaterial ?? true}; // toggle for node material
   // console.log('SPLATSWAP:: Initializing splat system. keepMeshesInMemory =', keepMeshesInMemory);
   // console.log('SPLATSWAP:: additionalSplats:', additionalSplats);

    // Store materials to reuse them
    let fadeMaterial = null;
    let inverseFadeMaterial = null;

    // Initialize materials if node material is enabled
    async function initializeMaterials() {
      if (useNodeMaterial) {
        if (!fadeMaterial) {
          fadeMaterial = await BABYLON.NodeMaterial.ParseFromFileAsync(
            "nodeMat",
            "https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Fmaterials%2FsplatFadeNodeMaterial.json?alt=media&token=00ea0eea-ea77-40bf-9f98-ed9bc54c25f9",
            scene
          );
        }
        if (!inverseFadeMaterial) {
          inverseFadeMaterial = await BABYLON.NodeMaterial.ParseFromFileAsync(
            "nodeMat",
            "https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Fmaterials%2FsplatFadeNodeMaterialInverse.json?alt=media&token=669d70b9-5957-4070-8b80-d01f9327fa08",
            scene
          );
        }
      }
    }

    // Initialize materials when the system starts
    initializeMaterials();

    let currentSplatUrl = null; 
    let isLoadingSplat = false;
    let lastWaypointIndex = -1;
    let lastPercentage = -1;
    let scrollPaused = false;

    // Because we only want the preloader to show for the *first* load,
    // we can track when the initial load is done:
    let initialLoadDone = false;

    // A map of splatUrl -> array of meshes
    // For Option 1: we never remove them, just hide or show.
    // For Option 2: we remove them whenever we switch splats.
    let preloadedSplats = new Map();


    // =========================================================
    // For Option 1: Hide a splat instead of disposing
    // =========================================================
    async function hidePreloadedSplat(url) {
    //  console.log('SPLATSWAP:: Hiding splat =>', url);
      const meshes = preloadedSplats.get(url);
      if (meshes) {
        if (useNodeMaterial && inverseFadeMaterial) {
          for (const mesh of meshes) {
            // Clone the material for this instance to avoid shared state
            const meshInverseMaterial = inverseFadeMaterial.clone("inverseFadeMat");
            mesh.material = meshInverseMaterial;

            const fadeAmountFloat = meshInverseMaterial.getBlockByName("FadeAmountFloat");
            if (fadeAmountFloat && fadeAmountFloat.isInput) {
              // Start from 10 and decrease to -10
              fadeAmountFloat._storedValue = 10;
              const observer = scene.onBeforeRenderObservable.add(() => {
                fadeAmountFloat._storedValue -= scene.getEngine().getDeltaTime() / 100;
                if (fadeAmountFloat._storedValue <= -10) {
                  mesh.isVisible = false;
                  mesh.isPickable = false;
                  scene.onBeforeRenderObservable.remove(observer);
                  meshInverseMaterial.dispose();
                }
              });
            }
          }
        } else {
          for (const mesh of meshes) {
            mesh.isVisible = false;
            mesh.isPickable = false;
          }
        }
      }
    }

    // =========================================================
    // For Option 2: Fully dispose of a splat
    // =========================================================
    function disposePreloadedSplat(url) {
    //  console.log('SPLATSWAP:: Disposing splat =>', url);
      const meshes = preloadedSplats.get(url);

      if (meshes && inverseFadeMaterial) {
        for (const mesh of meshes) {
          // Clone the material for this instance to avoid shared state
          const meshInverseMaterial = inverseFadeMaterial.clone("inverseFadeMat");
          mesh.material = meshInverseMaterial;

          const fadeAmountFloat = meshInverseMaterial.getBlockByName("FadeAmountFloat");
          if (fadeAmountFloat && fadeAmountFloat.isInput) {
            // Start from 10 and decrease to -10
            fadeAmountFloat._storedValue = 10;
            const observer = scene.onBeforeRenderObservable.add(() => {
              fadeAmountFloat._storedValue -= scene.getEngine().getDeltaTime() / 100;
              if (fadeAmountFloat._storedValue <= -10) {
                mesh.dispose();
                scene.onBeforeRenderObservable.remove(observer);
                meshInverseMaterial.dispose();
              }
            });
          }
        }
      }
      preloadedSplats.delete(url);
    }

    // =========================================================
    // Make a splat visible (both Option 1 or 2 end up here)
    // =========================================================
    function showPreloadedSplat(url) {
     // console.log('SPLATSWAP:: Attempting to show preloaded splat =>', url);
      const meshes = preloadedSplats.get(url);
      if (!meshes || (useNodeMaterial && !fadeMaterial)) {
     //   console.warn('SPLATSWAP:: No meshes found or fade material not initialized =>', url);
        return false;
      }
      meshes.forEach(mesh => {
        mesh.isVisible = true;
        mesh.isPickable = true;
        
        if (useNodeMaterial && fadeMaterial) {
          // Clone the material for this instance to avoid shared state
          const meshFadeMaterial = fadeMaterial.clone("fadeMat");
          mesh.material = meshFadeMaterial;
          
          const fadeAmountFloat = meshFadeMaterial.getBlockByName("FadeAmountFloat");
          if (fadeAmountFloat && fadeAmountFloat.isInput) {
            fadeAmountFloat._storedValue = -10;
            scene.registerBeforeRender(() => {
              fadeAmountFloat._storedValue += scene.getEngine().getDeltaTime() / 100;
            });
          }
        }
      });
      currentSplatUrl = url;
    //  console.log('SPLATSWAP:: Splat is now visible =>', url);
      return true;
    }

    // =========================================================
    // preloadSplat: background load a splat *without* showing preloader
    // =========================================================
    async function preloadSplat(url) {
      // If we already have it in memory, or it was loaded before, skip
      if (preloadedSplats.has(url)) {
      //  console.log('SPLATSWAP:: preloadSplat => already in memory =>', url);
        return;
      }
      // If it's the current splat, skip
      if (url === currentSplatUrl) {
      //  console.log('SPLATSWAP:: preloadSplat => same as current =>', url);
        return;
      }

    //  console.log('SPLATSWAP:: Preloading splat (no preloader) =>', url);
      try {
        // We do a background load. No preloader shown.
        const result = await BABYLON.SceneLoader.ImportMeshAsync("", url, "", scene);
        
        // Hide them initially and apply cloned fade material
        result.meshes.forEach(mesh => {
          mesh.checkCollisions = true;
          mesh.isVisible = false;
          mesh.isPickable = false;
          if (fadeMaterial) {
            const meshFadeMaterial = fadeMaterial.clone("fadeMat");
            mesh.material = meshFadeMaterial;
            const fadeAmountFloat = meshFadeMaterial.getBlockByName("FadeAmountFloat");
            if (fadeAmountFloat && fadeAmountFloat.isInput) {
              fadeAmountFloat._storedValue = -10;
              scene.registerBeforeRender(() => {
                fadeAmountFloat._storedValue += scene.getEngine().getDeltaTime() / 100;
              });
            }
          }
        });

        // Store in the map
        preloadedSplats.set(url, result.meshes);
      //  console.log('SPLATSWAP:: Background preloaded =>', url);
      } catch (error) {
       // console.error('SPLATSWAP:: Error preloading splat =>', url, error);
      }
    }

    // =========================================================
    // preloadNextSplats: a simple example that preloads
    // the next item in additionalSplats, if any
    // =========================================================
    async function preloadNextSplats(currentIndex) {
      if (!additionalSplats || additionalSplats.length === 0) {
        console.log('SPLATSWAP:: No additional splats');
        return;
      }
      // For instance, preload the next splat in the list:
      const nextIndex = currentIndex % additionalSplats.length; 
      const nextSplat = additionalSplats[nextIndex];
      if (nextSplat && nextSplat.url) {
      //  console.log('SPLATSWAP:: Attempting to preload next splat =>', nextSplat.url);
        await preloadSplat(nextSplat.url);
      }
    }

    // =========================================================
    // loadSplat: Switch to a new splat. Behavior depends on 
    // keepMeshesInMemory toggle: hide or dispose old.
    // =========================================================
    async function loadSplat(url) {
      if (url === currentSplatUrl) {
     //   console.log('SPLATSWAP:: loadSplat with same url =>', url, 'Ignoring.');
        return;
      }
      if (isLoadingSplat) {
      //  console.log('SPLATSWAP:: Already loading a splat, ignoring =>', url);
        return;
      }

   //   console.log('SPLATSWAP:: Loading new splat =>', url);
      isLoadingSplat = true;

      // Show preloader only if not done initial load
      if (!initialLoadDone) {
        showPreloader();
      }

      // Pause scroll while loading
      if (!scrollPaused) {
        scrollPaused = true;
      }

      try {
        // If we have a current splat, either hide or dispose it 
        if (currentSplatUrl && preloadedSplats.has(currentSplatUrl)) {
          if (keepMeshesInMemory) {
            // Option 1: Just hide
         //   console.log('SPLATSWAP:: Option1 => Hiding old splat =>', currentSplatUrl);
            hidePreloadedSplat(currentSplatUrl);
          } else {
            // Option 2: Dispose & remove from preloadedSplats
         //   console.log('SPLATSWAP:: Option2 => Disposing old splat =>', currentSplatUrl);
            disposePreloadedSplat(currentSplatUrl);
          }
        }

        // If the new splat is already loaded (and not disposed), show it
        if (preloadedSplats.has(url)) {
         // console.log('SPLATSWAP:: Reusing loaded splat =>', url);
          showPreloadedSplat(url);
        } else {
          // Otherwise, import from scratch
        //  console.log('SPLATSWAP:: Importing new splat =>', url);
          const result = await BABYLON.SceneLoader.ImportMeshAsync("", url, "", scene);

          result.meshes.forEach(mesh => {
            mesh.checkCollisions = true;
            mesh.isVisible = true;
            mesh.isPickable = true;
            if (fadeMaterial) {
              const meshFadeMaterial = fadeMaterial.clone("fadeMat");
              mesh.material = meshFadeMaterial;
              const fadeAmountFloat = meshFadeMaterial.getBlockByName("FadeAmountFloat");
              if (fadeAmountFloat && fadeAmountFloat.isInput) {
                fadeAmountFloat._storedValue = -10;
                scene.registerBeforeRender(() => {
                  fadeAmountFloat._storedValue += scene.getEngine().getDeltaTime() / 100;
                });
              }
            }
          });

          // Store it in our map
          preloadedSplats.set(url, result.meshes);
          currentSplatUrl = url;
       //   console.log('SPLATSWAP:: Splat loaded & stored =>', url);
        }

        // Now that we've loaded the main splat, let's do
        // a quick background preload of the next one:
        const currentIndex = additionalSplats.findIndex(s => s.url === url);
        if (currentIndex !== -1) {
          preloadNextSplats(currentIndex + 1);
        } else {
          console.log('SPLATSWAP:: Current splat not found in additionalSplats: ', additionalSplats);
          preloadNextSplats(0); 
        }

      } catch (error) {
        console.error('SPLATSWAP:: Error loading splat =>', url, error);
        currentSplatUrl = null;
      } finally {
        isLoadingSplat = false;

        // Hide the preloader if it was shown
        if (!initialLoadDone) {
          hidePreloader();
        }
        // Mark that we've done at least one load
        initialLoadDone = true;

        // Resume scrolling
        if (scrollPaused) {
          scrollPaused = false;
        //  console.log('SPLATSWAP:: Resuming scroll at =>', scrollPosition);
        }
      }
    }

    // =========================================================
    // updateSplats: checks progress & chooses which splat to load
    // =========================================================
    function updateSplats() {
      // If no splat loaded yet, start with default
      if (!currentSplatUrl) {
        console.log('SPLATSWAP:: No current splat. Loading initial =>', '${props.modelUrl}');
        loadSplat('${props.modelUrl}');
        return;
      }

      if (!additionalSplats || additionalSplats.length === 0) {
       // console.log('SPLATSWAP:: No additionalSplats - skipping');
        return;
      }

      // Evaluate user progress
      const currentWaypointIndex = Math.round(scrollPosition / 20);
      const currentPercentage = ((scrollPosition / 20) / (waypoints.length - 1)) * 100;

      console.log(\`SPLATSWAP:: waypointIndex:\${currentWaypointIndex}, percentage:\${currentPercentage.toFixed(2)}\`);

      // Only proceed if there's a real change
      if (
        currentWaypointIndex === lastWaypointIndex &&
        Math.abs(currentPercentage - lastPercentage) < 0.1
      ) {
       // console.log('SPLATSWAP:: No significant change, skipping');
        return;
      }

      let bestSplat = null;
      let bestTrigger = -Infinity;

      // Pick the largest trigger <= current progress
      additionalSplats.forEach(splat => {
        if (splat.waypointIndex !== -1) {
          const triggerValue = splat.waypointIndex;
          if (currentWaypointIndex >= triggerValue && triggerValue > bestTrigger) {
            bestTrigger = triggerValue;
            bestSplat = splat;
          }
        } else if (splat.percentage !== -1) {
          const triggerValue = splat.percentage;
          if (currentPercentage >= triggerValue && triggerValue > bestTrigger) {
            bestTrigger = triggerValue;
            bestSplat = splat;
          }
        }
      });

      if (bestSplat) {
        console.log('SPLATSWAP:: Found best splat =>', bestSplat);
      } else {
        console.log('SPLATSWAP:: No triggered splat found => might revert to default');
      }

      // If we have a best splat, load it; else revert to default
      if (bestSplat && bestSplat.url !== currentSplatUrl) {
        console.log('SPLATSWAP:: Switching to =>', bestSplat.url);
        loadSplat(bestSplat.url);
      } else if (!bestSplat && currentSplatUrl !== '${props.modelUrl}') {
        console.log('SPLATSWAP:: Reverting to initial =>', '${props.modelUrl}');
        loadSplat('${props.modelUrl}');
      }

      // Update trackers
      lastWaypointIndex = currentWaypointIndex;
      lastPercentage = currentPercentage;
      console.log('SPLATSWAP:: Updated lastWaypointIndex:', lastWaypointIndex, 'lastPercentage:', lastPercentage);
    }

    // ----------------------------------------------------
    // Show/hide preloader (only used for initial load)
    // ----------------------------------------------------
    function showPreloader() {
      const preloader = document.getElementById('preloader');
      if (!preloader) {
        return;
      }
      console.log('SPLATSWAP:: Showing preloader');
      preloader.style.display = 'flex';
    }

    function hidePreloader() {
      const preloader = document.getElementById('preloader');
      if (!preloader) {
        return;
      }
      console.log('SPLATSWAP:: Hiding preloader');
      preloader.style.display = 'none';
    }
  `;
};
