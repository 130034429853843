/*
StorageManager.ts
Description: Manages file storage operations and user storage limits
Last modified: 2024-02-15
Changes:
- Added uploadSplatFile method for splat swapping
- Added debug logging for thumbnail upload process
- Fixed thumbnail URL logging
- Added better error handling and logging for thumbnail operations
- Added likes and comments arrays initialization for new splats
- Fixed URL decoding in deleteSplatFile method
*/

// [Previous imports remain unchanged]
import { storage, db } from "./FirebaseConfig";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
  getMetadata
} from "firebase/storage";
import {
  doc,
  updateDoc,
  arrayUnion,
  getDoc,
  arrayRemove,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { generateExportedHTML } from "../tools/GenerateExportedHtml";
import { UserSplat, UserProfile } from "../types/UserTypes";
import { SaveFile } from "../types/SceneTypes";
import e from "cors";

const ALLOWED_FILE_TYPES = ['.splat', '.ply', '.glb', '.gltf'];

export class StorageManager {
 // Helper function to get the effective max scenes limit
 private static getMaxScenes(userData: Partial<UserProfile>): number {
  // Use maxSplats if available, otherwise fall back to maxStorage, or default to 5
  return userData.maxSplats ?? userData.maxStorage ?? 5;
}

// Helper function to migrate maxStorage to maxSplats if needed
private static async migrateToMaxSplats(userId: string, userData: Partial<UserProfile>): Promise<void> {
  if (userData.maxStorage !== undefined && userData.maxSplats === undefined) {
    await updateDoc(doc(db, 'users', userId), {
      maxSplats: userData.maxStorage,
      // Don't remove maxStorage yet for backward compatibility
    });
  }
}

static async validateProAccess(userId: string): Promise<boolean> {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    const userData = userDoc.data();

    if (!userData) return false;

    const isPro = userData.isPro || false;
    const proExpiryDate = userData.proExpiryDate?.toDate();

    if (!isPro) return false;
    if (proExpiryDate && proExpiryDate < new Date()) {
      await updateDoc(doc(db, 'users', userId), {
        isPro: false,
        proExpiryDate: null,
        maxSplats: 5,
        maxStorageBytes: 250 * 1024 * 1024 // Reset to free tier (250MB)
      });
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error validating pro access:', error);
    return false;
  }
}

static async getPlanDetails(userId: string) {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    const userData = userDoc.data();

    // Ensure maxSplats is set
    if(userData) {
    await this.migrateToMaxSplats(userId, userData);
    }
    return {
      isPro: userData?.isPro || false,
      expiryDate: userData?.proExpiryDate?.toDate(),
      maxSplats: this.getMaxScenes(userData || {}),
      maxStorageBytes: userData?.maxStorageBytes || (250 * 1024 * 1024), // Default to 250MB
      storageUsed: userData?.storageUsed || 0,
      stripeCustomerId: userData?.stripeCustomerId,
      stripeSubscriptionId: userData?.stripeSubscriptionId
    };
  } catch (error) {
    console.error('Error getting plan details:', error);
    throw error;
  }
}

static validateFileType(fileName: string): boolean {
  const extension = fileName.toLowerCase().substring(fileName.lastIndexOf('.'));
  return ALLOWED_FILE_TYPES.includes(extension);
}

static async checkStorageLimit(userId: string, fileSize: number): Promise<boolean> {
  try {
    const planDetails = await this.getPlanDetails(userId);
    const newTotalSize = planDetails.storageUsed + fileSize;
    return (newTotalSize <= planDetails.maxStorageBytes )|| planDetails.maxStorageBytes <= 0;
  } catch (error) {
    console.error('Error checking storage limit:', error);
    throw error;
  }
}  
  private static async uploadThumbnail(
    userId: string,
    thumbnailBlob: Blob,
    sceneId: string
  ): Promise<string> {
    try {
      console.log('Starting thumbnail upload for scene:', sceneId);
      console.log('Thumbnail blob details:', {
        size: thumbnailBlob?.size,
        type: thumbnailBlob?.type,
        exists: !!thumbnailBlob
      });
      
      const thumbnailRef = ref(storage, `users/${userId}/thumbnails/${sceneId}.png`);
      console.log('Created storage reference:', thumbnailRef.fullPath);
      
      // Upload the blob
      await uploadBytes(thumbnailRef, thumbnailBlob);
      console.log('Thumbnail blob uploaded successfully, getting URL...');
      
      // Get the URL
      const url = await getDownloadURL(thumbnailRef);
      console.log('Thumbnail URL obtained:', url);
      
      return url;
    } catch (error) {
      console.error("Error uploading thumbnail:", error);
      throw new Error(`Failed to upload thumbnail: ${error}`);
    }
  }

  private static async createBackup(userId: string, currentData: UserProfile) {
    try {
      const backupRef = ref(
        storage,
        `users/${userId}/backups/profile_${Date.now()}.json`
      );
      await uploadBytes(backupRef, new Blob([JSON.stringify(currentData)]));
    } catch (error) {
      console.error("Backup creation failed:", error);
      // Continue with the operation even if backup fails
    }
  }
  private static getBaseName(path: string): string {
    // Remove any query parameters
    const baseUrl = path.split("?")[0];
    // Get the last segment of the path and decode it
    const segments = baseUrl.split("/");
    const encodedName = segments[segments.length - 1];
    // Decode the URL twice to handle double encoding
    try {
      return decodeURIComponent(decodeURIComponent(encodedName));
    } catch {
      // If double decoding fails, try single decode
      try {
        return decodeURIComponent(encodedName);
      } catch {
        // If all decoding fails, return as is
        return encodedName;
      }
    }
  }

  static async deleteSplatFile(userId: string, splatUrl: string): Promise<void> {
    try {
      // Extract the storage path from the URL
      const urlObj = new URL(splatUrl);
      const pathSegments = urlObj.pathname.split('/');
      const startIndex = pathSegments.indexOf('o') + 1; // Firebase Storage URLs contain '/o/' before the actual path
      const storagePath = pathSegments.slice(startIndex).join('/');
      const decodedPath = decodeURIComponent(storagePath);
      
      console.log("Attempting to delete splat file with path:", decodedPath);
      
      // Get current user data
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        throw new Error("User profile not found");
      }
      const userData = userDoc.data() as UserProfile;

      // Create storage reference with decoded path
      const splatRef = ref(storage, decodedPath);
      
      // Get file size before deletion
      const metadata = await getMetadata(splatRef).catch(() => null);
      const fileSize = metadata?.size || 0;

      // Delete the file from storage
      await deleteObject(splatRef);

      // Update storage usage
      if (fileSize > 0) {
        await updateDoc(userDocRef, {
          storageUsed: Math.max(0, (userData.storageUsed || 0) - fileSize)
        });
      }

      console.log("Successfully deleted splat file:", decodedPath);
    } catch (error) {
      console.error("Error in deleteSplatFile:", error);
      throw error;
    }
  }
  static async deleteSplat(userId: string, splat: UserSplat): Promise<void> {
    try {
      // Get current user data
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        throw new Error("User profile not found");
      }
      const userData = userDoc.data();

      const deletePromises: Promise<void>[] = [];

      // Handle splat file deletion
      if (splat.splatUrl && !splat.splatUrl.includes("assets.babylonjs.com")) {
        const splatName = this.getBaseName(splat.name);
        console.log("Attempting to delete splat:", splatName);
        const splatRef = ref(storage, `users/${userId}/splats/${splatName}`);
        deletePromises.push(
          deleteObject(splatRef).catch((error) => {
            console.log("Error deleting splat:", error);
          })
        );
      }

      // Handle HTML file deletion
      const htmlName = splat.name.replace(".splat", ".html");
      console.log("Attempting to delete HTML:", htmlName);
      const htmlRef = ref(storage, `users/${userId}/html/${htmlName}`);
      deletePromises.push(
        deleteObject(htmlRef).catch((error) => {
          console.log("Error deleting HTML:", error);
        })
      );

      // Handle JSON file deletion
      if (splat.jsonUrl) {
        const jsonName = `scene_${splat.name}.json`;
        console.log("Attempting to delete JSON:", jsonName);
        const jsonRef = ref(storage, `users/${userId}/json/${jsonName}`);
        deletePromises.push(
          deleteObject(jsonRef).catch((error) => {
            console.log("Error deleting JSON:", error);
          })
        );
      }

      // Handle thumbnail deletion
      if (splat.thumbnailUrl) {
        console.log("Attempting to delete thumbnail:", splat.id);
        const thumbnailRef = ref(storage, `users/${userId}/thumbnails/${splat.id}.png`);
        deletePromises.push(
          deleteObject(thumbnailRef).catch((error) => {
            console.log("Error deleting thumbnail:", error);
          })
        );
      }

      // Add skybox deletion
      await this.deleteSkybox(userId, splat.id);

      // Wait for all delete attempts to complete
      await Promise.allSettled(deletePromises);

      // Update Firestore document regardless of file deletion success
      await updateDoc(userDocRef, {
        splats: arrayRemove(splat),
        storageUsed: Math.max(
          0,
          (userData.storageUsed || 0) - (splat.size || 0)
        ),
      });

      console.log("Successfully removed splat from Firestore");
    } catch (error) {
      console.error("Error in deleteSplat:", error);
      throw error;
    }
  }
  // [Rest of the class implementation remains unchanged]
  static async saveSplatConfig(
    userId: string,
    splatUrl: string,
    sceneConfig: any,
    jsonSave: SaveFile,
    sceneId: string,
    sceneTitle?: string,
    thumbnailBlob: Blob | null = null,
    onCapturePreview?: () => Promise<Blob | null>,
    skyboxBlob: Blob | null = null
  ): Promise<UserSplat> {
    try {
 // Get current user data and create backup
 const userDocRef = doc(db, "users", userId);
 const userDoc = await getDoc(userDocRef);
 if (!userDoc.exists()) {
   throw new Error("User profile not found");
 }
 const userData = userDoc.data() as UserProfile;
 await this.createBackup(userId, userData);

 if (userData.splats?.length >= (userData.maxSplats ?? userData.maxStorage ?? 5) ) {
   throw new Error(
     `Storage limit reached. Maximum ${userData.maxStorage} splats allowed.`
   );
 }

 // If no thumbnail provided, try to generate one
 if (!thumbnailBlob && onCapturePreview) {
   console.log("No thumbnail provided, attempting to generate one...");
   thumbnailBlob = await onCapturePreview();
 }

 // Upload thumbnail if available
 let thumbnailUrl: string | undefined;
 if (thumbnailBlob) {
   thumbnailUrl = await this.uploadThumbnail(userId, thumbnailBlob, sceneId);
 }

 // Upload skybox if available
 let skyboxUrl: string | undefined;
 if (skyboxBlob) {
   skyboxUrl = await this.uploadSkybox(userId, skyboxBlob, sceneId);
 }

 const jsonFileName = `scene_${sceneTitle || 'untitled'}.json`;
 const jsonContent = JSON.stringify(jsonSave, null, 2);
 const jsonBlob = new Blob([jsonContent], { type: "application/json" });
 const jsonRef = ref(storage, `users/${userId}/json/${jsonFileName}`);
 await uploadBytes(jsonRef, jsonBlob);
 const jsonUrl = await getDownloadURL(jsonRef);

 const timestamp = Date.now().toString();
 const htmlFileName = `${sceneTitle || 'untitled'}.html`;
      const htmlContent = generateExportedHTML(
        splatUrl,
        sceneConfig.includeScrollControls ?? false,
        sceneConfig.waypoints ?? [],
        sceneConfig.backgroundColor ?? "#000000",
        sceneConfig.cameraMovementSpeed ?? 1,
        sceneConfig.cameraRotationSensitivity ?? 1,
        sceneConfig.scrollSpeed ?? 1,
        sceneConfig.animationFrames ?? [],
        sceneConfig.hotspots ?? [],
        sceneConfig.defaultCameraMode ?? "orbit",
        sceneConfig.cameraDampeningRef ?? 0.05,
        sceneConfig.uiColor ?? "#ffffff",
        sceneConfig.transitionSpeed ?? 1000,
        sceneConfig.scrollButtonMode ?? "default",
        sceneConfig.scrollAmount ?? 100,
        sceneConfig.allowedCameraModes ?? ["orbit"],
        sceneConfig.loopMode ?? false,
        sceneConfig.autoPlaySpeed ?? 1,
        sceneConfig.autoPlayEnabled ?? false,
        sceneConfig.collisionMeshesData ?? [],
        sceneConfig.includeXR ?? false,
        sceneConfig.xrMode ?? "none",
        sceneConfig.arOptions ?? {
          enableHitTest: false,
          enablePlaneDetection: true,
          enableAnchors: true,
          enableBackgroundRemoval: true
        },
        sceneConfig.uiOptions ?? {},
        sceneTitle || "Untitled Scene",
        sceneId,
        userId,
        userData.displayName || "Anonymous",
        sceneConfig.lights ?? [],
        undefined,
        sceneConfig.additionalSplats,
        sceneConfig.keepMeshesInMemory,
        sceneConfig.playerHeight,
        sceneConfig.useNodeMaterial,
        thumbnailUrl,
        sceneConfig.fov,
        sceneConfig.activeSkyboxUrl,
        sceneConfig.invertCameraRotation
      );

      const htmlBlob = new Blob([htmlContent], { type: "text/html" });
      const htmlRef = ref(storage, `users/${userId}/html/${htmlFileName}`);
      await uploadBytes(htmlRef, htmlBlob);
      const htmlUrl = await getDownloadURL(htmlRef);

      const splatData: UserSplat = {
        id: timestamp,
        name: sceneTitle || "Untitled Scene",
        splatUrl,
        htmlUrl,
        jsonUrl,
        thumbnailUrl: thumbnailUrl,
        embedCode: `<iframe src="${htmlUrl}" style="width:100%;height:600px;border:none;"></iframe>`,
        createdAt: new Date(),
        updatedAt: new Date(),
        size: 0,
        description: sceneConfig.description || "Saved Scene",
      };

      await updateDoc(userDocRef, {
        splats: arrayUnion(splatData),
      });

      return splatData;    } catch (error) {
      console.error("Error saving splat config:", error);
      throw error;
    }
  }
  static async updateSplatVisibility(
    userId: string,
    splat: UserSplat,
    isPublic: boolean
  ): Promise<void> {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      const userData = userDoc.data() as UserProfile;
  
      const updatedSplats = userData.splats.map(s => {
        if (s.id === splat.id) {
          return {
            ...s,
            isPublic,
            updatedAt: new Date()
          };
        }
        return s;
      });
  
      // If making a splat public, ensure the user's profile is also public
      const updates: any = {
        splats: updatedSplats
      };

      if (isPublic) {
        updates.publicProfile = {
          ...(userData.publicProfile || {}),
          isPublic: true
        };
      }
  
      await updateDoc(doc(db, 'users', userId), updates);
      
      console.log('Successfully updated splat and profile visibility');
    } catch (error) {
      console.error('Error updating splat visibility:', error);
      throw error;
    }
  }
  
  // Method to update only the save file
  static async updateSaveFile(
    userId: string,
    jsonSave: SaveFile,
    sceneId: string
  ): Promise<void> {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (!userDoc.exists()) throw new Error('User profile not found');
      
      const userData = userDoc.data() as UserProfile;
      const currentSplat = userData.splats.find(splat => splat.id === sceneId);


      
      if (!currentSplat) throw new Error('Scene not found');

      currentSplat.name = jsonSave.sceneTitle || currentSplat?.name;

      const jsonFileName = `scene_${currentSplat.name}.json`;
      const jsonContent = JSON.stringify(jsonSave, null, 2);
      const jsonBlob = new Blob([jsonContent], { type: 'application/json' });
      const jsonRef = ref(storage, `users/${userId}/json/${jsonFileName}`);
      
      await uploadBytes(jsonRef, jsonBlob);
      const jsonUrl = await getDownloadURL(jsonRef);

      const userDocRef = doc(db, 'users', userId);
      const updatedSplats = userData.splats.map(splat => {
        if (splat.id === sceneId) {
          return {
            ...splat,
            jsonUrl,
            updatedAt: new Date()
          };
        }
        return splat;
      });

      await updateDoc(userDocRef, {
        splats: updatedSplats
      });

      console.log('Successfully updated save file');
    } catch (error) {
      console.error('Error updating save file:', error);
      throw error;
    }
  }
  static async updateSplatConfig(
    userId: string,
    splatUrl: string,
    sceneConfig: any,
    jsonSave: SaveFile,
    sceneId: string,
    sceneName: string,
    thumbnailBlob: Blob | null,
    skyboxBlob: Blob | null = null
  ): Promise<void> {
    try {
     // Get current user data to preserve existing thumbnail if needed
     const userDoc = await getDoc(doc(db, 'users', userId));
     const userData = userDoc.data() as UserProfile;
     const currentSplat = userData.splats.find(splat => splat.id === sceneId);

     // Upload new thumbnail if provided, otherwise keep existing
     let thumbnailUrl: string | undefined;
     console.log('Updating splat config with thumbnail blob:', thumbnailBlob);
     if (thumbnailBlob) {
       thumbnailUrl = await this.uploadThumbnail(userId, thumbnailBlob, sceneId);
       console.log('New thumbnail uploaded:', thumbnailUrl);
     } else if (currentSplat) {
       thumbnailUrl = currentSplat.thumbnailUrl;
       console.log('Keeping existing thumbnail:', thumbnailUrl);
     }

     // Upload skybox if available
     let skyboxUrl: string | undefined;
     if (skyboxBlob) {
       skyboxUrl = await this.uploadSkybox(userId, skyboxBlob, sceneId);
     }

     jsonSave.sceneTitle = sceneName;

     const jsonFileName = `scene_${sceneName}.json`;
     const jsonContent = JSON.stringify(jsonSave, null, 2);
     const jsonBlob = new Blob([jsonContent], { type: 'application/json' });
     const jsonRef = ref(storage, `users/${userId}/json/${jsonFileName}`);
     await uploadBytes(jsonRef, jsonBlob);
     const jsonUrl = await getDownloadURL(jsonRef);

     console.log('Updating scene configuration:: additionalSplats:', sceneConfig.additionalSplats);

     const htmlFileName = `${sceneName}.html`;
      const htmlContent = generateExportedHTML(
        splatUrl,
        sceneConfig.includeScrollControls,
        sceneConfig.waypoints,
        sceneConfig.backgroundColor,
        sceneConfig.cameraMovementSpeed,
        sceneConfig.cameraRotationSensitivity,
        sceneConfig.scrollSpeed,
        sceneConfig.animationFrames,
        sceneConfig.hotspots,
        sceneConfig.defaultCameraMode,
        sceneConfig.cameraDampeningRef,
        sceneConfig.uiColor,
        sceneConfig.transitionSpeed,
        sceneConfig.scrollButtonMode,
        sceneConfig.scrollAmount,
        sceneConfig.allowedCameraModes,
        sceneConfig.loopMode,
        sceneConfig.autoPlaySpeed,
        sceneConfig.autoPlayEnabled,
        sceneConfig.collisionMeshesData,
        sceneConfig.includeXR,
        sceneConfig.xrMode,
        sceneConfig.arOptions,
        sceneConfig.uiOptions,
        sceneName,
        sceneId,
        userId,
        userData.displayName || "Anonymous",
        sceneConfig.lights,
        undefined,
        sceneConfig.additionalSplats,
        sceneConfig.keepMeshesInMemory,
        sceneConfig.playerHeight,
        sceneConfig.useNodeMaterial,
        thumbnailUrl,
        sceneConfig.fov,
        sceneConfig.activeSkyboxUrl,
        sceneConfig.invertCameraRotation
      );

      const htmlBlob = new Blob([htmlContent], { type: 'text/html' });
      const htmlRef = ref(storage, `users/${userId}/html/${htmlFileName}`);
      await uploadBytes(htmlRef, htmlBlob);
      const htmlUrl = await getDownloadURL(htmlRef);

      const userDocRef = doc(db, 'users', userId);
      if (!userDoc.exists()) throw new Error('User profile not found');

      const updatedSplats = userData.splats.map(splat => {
        if (splat.id === sceneId) {
          const updatedSplat = {
            ...splat,
            name: sceneName,
            htmlUrl,
            jsonUrl,
            description: sceneConfig.description || splat.description,
            embedCode: `<iframe src="${htmlUrl}" style="width:100%;height:600px;border:none;"></iframe>`,
            updatedAt: new Date(),
          };

          // Only update thumbnailUrl if we have a new one
          if (thumbnailUrl) {
            updatedSplat.thumbnailUrl = thumbnailUrl;
          }

          return updatedSplat;
        }
        return splat;
      });

      await updateDoc(userDocRef, {
        splats: updatedSplats
      });
      
      console.log('Successfully updated scene configuration');    } catch (error) {
      console.error('Error updating splat config:', error);
      throw error;
    }
  }

  static async updateSplat(
    userId: string,
    file: File,
    sceneConfig: any,
    onProgress: (progress: number) => void,
    jsonSave: SaveFile,
    sceneId: string,
    sceneName: string,
    thumbnailBlob: Blob | null
  ): Promise<void> {
    try {
      // Upload new splat file first
      const splatRef = ref(storage, `users/${userId}/splats/${file.name}`);
      const uploadTask = uploadBytesResumable(splatRef, file);

      uploadTask.on('state_changed', 
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress(progress);
        }
      );

      await uploadTask;
      const splatUrl = await getDownloadURL(splatRef);
      
      // Update jsonSave with new splatUrl
      jsonSave.loadedModelUrl = splatUrl;
      jsonSave.sceneTitle = sceneName;

      // Use updateSplatConfig to handle the rest of the update
      await this.updateSplatConfig(
        userId,
        splatUrl,
        sceneConfig,
        jsonSave,
        sceneId,
        sceneName,
        thumbnailBlob
      );

      console.log('Successfully updated scene with new splat file');
    } catch (error) {
      console.error('Error updating splat:', error);
      throw error;
    }
  }

  static async uploadSplat(
    userId: string,
    file: File,
    sceneConfig: any,
    sceneId: string,
    onProgress: (progress: number) => void,
    jsonSave: SaveFile,
    sceneTitle: string,
    thumbnailBlob: Blob | null = null,
    onCapturePreview?: () => Promise<Blob | null>
  ): Promise<UserSplat> {
    try {
  // Validate file type
  if (!this.validateFileType(file.name)) {
    throw new Error('Invalid file type. Only .splat, .ply, .glb, and .gltf files are allowed.');
  }

  // Check storage limits
  const userDocRef = doc(db, "users", userId);
  const userDoc = await getDoc(userDocRef);
  if (!userDoc.exists()) {
    throw new Error("User profile not found");
  }
  const userData = userDoc.data() as UserProfile;

  // Ensure maxSplats is set
  await this.migrateToMaxSplats(userId, userData);
  const maxScenes = this.getMaxScenes(userData);

  // Check number of scenes limit
  if (userData.splats?.length >= maxScenes && maxScenes > 0) {
    throw new Error(
      `Scene limit reached. Maximum ${maxScenes} scenes allowed.`
    );
  }

  // Check storage bytes limit
  if (!await this.checkStorageLimit(userId, file.size)) {
    const maxStorageMB = userData.maxStorageBytes / (1024 * 1024);
    throw new Error(`Storage limit reached. Maximum storage is ${maxStorageMB}MB.`);
  }

  await this.createBackup(userId, userData);

  // If no thumbnail provided, try to generate one
  if (!thumbnailBlob && onCapturePreview) {
    console.log("No thumbnail provided, attempting to generate one...");
    thumbnailBlob = await onCapturePreview();
  }

  // Upload thumbnail if available
  let thumbnailUrl: string | undefined;
  if (thumbnailBlob) {
    thumbnailUrl = await this.uploadThumbnail(userId, thumbnailBlob, sceneId);
  }

  const splatRef = ref(storage, `users/${userId}/splats/${file.name}`);
  const uploadTask = uploadBytesResumable(splatRef, file);

  uploadTask.on("state_changed", (snapshot) => {
    const progress =
      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    if (onProgress) {
      onProgress(progress);
    }
  });

  await uploadTask;
  const splatUrl = await getDownloadURL(splatRef);
  
  jsonSave.loadedModelUrl = splatUrl;
  jsonSave.sceneTitle = sceneTitle || file.name.replace(".splat", "");

  const timestamp = Date.now().toString();
  const jsonFileName = `scene_${sceneTitle || file.name}.json`;
  const jsonContent = JSON.stringify(jsonSave, null, 2);
  const jsonBlob = new Blob([jsonContent], { type: "application/json" });
  const jsonRef = ref(storage, `users/${userId}/json/${jsonFileName}`);
  await uploadBytes(jsonRef, jsonBlob);
  const jsonUrl = await getDownloadURL(jsonRef);

      const htmlContent = generateExportedHTML(
        splatUrl,
        sceneConfig.includeScrollControls ?? false,
        sceneConfig.waypoints ?? [],
        sceneConfig.backgroundColor ?? "#000000",
        sceneConfig.cameraMovementSpeed ?? 1,
        sceneConfig.cameraRotationSensitivity ?? 1,
        sceneConfig.scrollSpeed ?? 1,
        sceneConfig.animationFrames ?? [],
        jsonSave?.hotspots ?? sceneConfig.hotspots ?? [],
        sceneConfig.defaultCameraMode ?? "orbit",
        sceneConfig.cameraDampeningRef ?? 0.05,
        sceneConfig.uiColor ?? "#ffffff",
        sceneConfig.transitionSpeed ?? 1000,
        sceneConfig.scrollButtonMode ?? "default",
        sceneConfig.scrollAmount ?? 100,
        sceneConfig.allowedCameraModes ?? ["orbit"],
        sceneConfig.loopMode ?? false,
        sceneConfig.autoPlaySpeed ?? 1,
        sceneConfig.autoPlayEnabled ?? false,
        sceneConfig.collisionMeshesData ?? [],
        sceneConfig.includeXR ?? false,
        sceneConfig.xrMode ?? "none",
        sceneConfig.arOptions ?? {
          enableHitTest: false,
          enablePlaneDetection: true,
          enableAnchors: true,
          enableBackgroundRemoval: true
        },
        sceneConfig.uiOptions ?? {},
        sceneTitle || file.name.replace(".splat", ""),
        sceneId,
        userId,
        userData.displayName || "Anonymous",
        sceneConfig.lights ?? [],
        undefined,
        sceneConfig.additionalSplats,
        sceneConfig.keepMeshesInMemory,
        sceneConfig.playerHeight,
        sceneConfig.useNodeMaterial,
        thumbnailUrl,
        sceneConfig.fov,
        sceneConfig.activeSkyboxUrl,
        sceneConfig.invertCameraRotation
      );

      const htmlBlob = new Blob([htmlContent], { type: "text/html" });
      const htmlRef = ref(storage, `users/${userId}/html/${sceneTitle || file.name.replace(".splat", "")}.html`);
      await uploadBytes(htmlRef, htmlBlob);
      const htmlUrl = await getDownloadURL(htmlRef);
      
      const splatData: UserSplat = {
        id: timestamp,
        name: sceneTitle || file.name,
        splatUrl,
        htmlUrl,
        jsonUrl,
        thumbnailUrl: thumbnailUrl,
        embedCode: `<iframe src="${htmlUrl}" style="width:100%;height:600px;border:none;"></iframe>`,
        createdAt: new Date(),
        updatedAt: new Date(),
        size: file.size,
        description: sceneConfig.description || "My Scene",
        likes: [],
        comments: [],
        isPublic: false
      };

      await updateDoc(userDocRef, {
        splats: arrayUnion(splatData),
        storageUsed: (userData.storageUsed || 0) + file.size,
      });

      return splatData;    } catch (error) {
      console.error("Error in uploadSplat:", error);
      throw error;
    }
  }

  static async getSplatConfig(
    uid: string,
    name: string,
    splatUrl: string
  ): Promise<SaveFile> {
    try {
      // First try with the scene name
      const configRef = ref(storage, `users/${uid}/json/scene_${name}.json`);
      const url = await getDownloadURL(configRef);
      const response = await fetch(url);
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error fetching splat config:", error);

      try {
        // Fallback: try with the splat URL method
        const configRef = ref(
          storage,
          `users/${uid}/json/scene_${splatUrl}.json`
        );
        const url = await getDownloadURL(configRef);
        const response = await fetch(url);
        const jsonData = await response.json();
        return jsonData;
      } catch (error) {
        console.error("Error fetching splat config with fallback:", error);
        throw error;
      }
    }
  }

  static async updateLicenseAcceptance(
    userId: string,
    accepted: boolean
  ): Promise<void> {
    try {
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        hasAcceptedLicense: accepted,
        licenseAcceptedAt: accepted ? new Date() : null,
      });
    } catch (error) {
      console.error("Error updating license acceptance:", error);
      throw error;
    }
  }

  static async getLicenseAcceptanceStatus(userId: string): Promise<boolean> {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        return userDoc.data()?.hasAcceptedLicense || false;
      }
      return false;
    } catch (error) {
      console.error("Error getting license acceptance status:", error);
      throw error;
    }
  }

  static async checkForDuplicateName(userId: string, name: string): Promise<boolean> {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        throw new Error("User profile not found");
      }
      const userData = userDoc.data() as UserProfile;
      return userData.splats?.some(splat => splat.name === name) || false;
    } catch (error) {
      console.error("Error checking for duplicate name:", error);
      throw error;
    }
  }

  static async generateUniqueName(userId: string, baseName: string): Promise<string> {
    let counter = 1;
    let uniqueName = baseName;
    
    while (await this.checkForDuplicateName(userId, uniqueName)) {
      uniqueName = `${baseName} (${counter})`;
      counter++;
    }
    
    return uniqueName;
  }



  static async uploadSplatFile(
    userId: string,
    file: File,
    onProgress?: (progress: number) => void
  ): Promise<{ url: string; size: number }> {
    try {
      // Validate file type
      if (!this.validateFileType(file.name)) {
        throw new Error('Invalid file type. Only .splat, .ply, .glb, and .gltf files are allowed.');
      }

      // Check storage limits
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        throw new Error("User profile not found");
      }
      const userData = userDoc.data() as UserProfile;

      // Check storage bytes limit
      if (!await this.checkStorageLimit(userId, file.size)) {
        const maxStorageMB = userData.maxStorageBytes / (1024 * 1024);
        throw new Error(`Storage limit reached. Maximum storage is ${maxStorageMB}MB.`);
      }

      // Upload the splat file
      const splatRef = ref(storage, `users/${userId}/splats/${file.name}`);
      const uploadTask = uploadBytesResumable(splatRef, file);

      uploadTask.on("state_changed", (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (onProgress) {
          onProgress(progress);
        }
      });

      await uploadTask;
      const splatUrl = await getDownloadURL(splatRef);

      // Update user's storage usage
      await updateDoc(userDocRef, {
        storageUsed: (userData.storageUsed || 0) + file.size,
      });

      return {
        url: splatUrl,
        size: file.size
      };
    } catch (error) {
      console.error("Error in uploadSplatFile:", error);
      throw error;
    }
  }

  private static async uploadSkybox(
    userId: string,
    skyboxBlob: Blob,
    sceneId: string
  ): Promise<string> {
    try {
      const skyboxRef = ref(storage, `users/${userId}/skyboxes/${sceneId}.jpg`);
      await uploadBytes(skyboxRef, skyboxBlob);
      return await getDownloadURL(skyboxRef);
    } catch (error) {
      console.error("Error uploading skybox:", error);
      throw error;
    }
  }

  private static async deleteSkybox(
    userId: string,
    sceneId: string
  ): Promise<void> {
    try {
      const skyboxRef = ref(storage, `users/${userId}/skyboxes/${sceneId}.jpg`);
      await deleteObject(skyboxRef);
    } catch (error) {
      console.error("Error deleting skybox:", error);
      // Don't throw - continue if skybox doesn't exist
    }
  }

}


