/*
File: mainScript.ts
Description: Main entry point for Babylon.js scene generation that combines all modular components
Last modified: 2024-02-19
Changes: 
- Refactored into modular components for better organization and maintainability
- Added initialization of shared variables before module code
- Split functionality into separate files for scene setup, hotspots, navigation, collisions, XR, mobile controls, etc.
- Added splat swapping system initialization
- Reordered module initialization to ensure splatSystem is available before scene setup
*/

import { GenerateHTMLProps } from "./types";
import { generateSceneSetup } from "./modules/sceneSetup";
import { generateHotspotSystem } from "./modules/hotspotSystem";
import { generateNavigationSystem } from "./modules/navigationSystem";
import { generateCollisionSystem } from "./modules/collisionSystem";
import { generateXRSystem } from "./modules/xrSystem";
import { generateMobileControls } from "./modules/mobileControls";
import { generateFullscreenControls } from "./modules/fullscreenControls";
import { generateRenderLoop } from "./modules/renderLoop";
import { generateSplatSystem } from "./modules/splatSystem";
import { generateSkyboxSystem } from './modules/skyboxSystem';
import { generatePreloaderService } from './modules/preloaderService';

export const generateMainScript = (props: GenerateHTMLProps): string => {
  const debugMode = props.uiOptions?.debugMode === true;

  return `
    ${generatePreloaderService()}

    window.addEventListener("keydown",
      function(e) {
        //if wasd or arrow keys, and canvas is not focused
        if([32, 37, 38, 39, 40, 87, 65, 83, 68, 81, 69].indexOf(e.keyCode) > -1 && 
            document.activeElement !== canvas &&
            !(document.activeElement instanceof HTMLInputElement) &&
            !(document.activeElement instanceof HTMLTextAreaElement)) {
          e.preventDefault();
          canvas.focus();
          //resend the event to the canvas  
          canvas.dispatchEvent(new KeyboardEvent('keydown', e));
        }
      }, false
    );

    // Initialize shared variables
    const waypoints = ${JSON.stringify(props.waypoints)};
    let userControl = cameraMode === 'explore';
    let scrollPosition = 0;
    let scrollTarget = 0.01;
    let targetRotation = null;
    let targetPosition = null;
    const activeWaypoints = new Set();
    let transitionSpeed = ${props.transitionSpeed};

    // Then set up the scene (which will use the splat system)
    ${generateSceneSetup(props)}

    // Initialize splat system first
    ${generateSplatSystem(props)}

    // After scene setup, initialize target positions
    targetRotation = camera.rotationQuaternion.clone();
    targetPosition = camera.position.clone();

    ${
      debugMode
        ? `
    // Debug mode setup
    window.addEventListener('keydown', (event) => {
      if (event.ctrlKey && event.key === 'i') {
        if (scene.debugLayer.isVisible()) {
          scene.debugLayer.hide();
        } else {
          scene.debugLayer.show();
        }
      }
    });
    `
        : ""
    }

    ${generateSkyboxSystem(props)}
    ${generateHotspotSystem(props)}
    ${generateNavigationSystem(props)}
    ${generateCollisionSystem(props)}
    ${generateXRSystem(props)}
    ${generateMobileControls(props)}
    ${generateFullscreenControls()}
    ${generateRenderLoop(props)}
  `;
};
