import React, { useCallback } from 'react';
import styled from 'styled-components';
import { FiUpload, FiTrash2 } from 'react-icons/fi';
import Draggable from "react-draggable";
import { useAuth } from '../contexts/AuthContext';
import { storage } from '../utils/FirebaseConfig';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import BackgroundColorSelector from './BackgroundColorSelector';

const Container = styled.div`
  position: absolute;
  top: 60px;
  left: 400px;
  background-color: #1e1e1e;
  border-radius: 8px;
  color: #ffffff;
  z-index: 1000;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
  width: 280px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  @media (max-width: 480px) {
    width: 90%;
    left: 5%;
  }
`;

const Handle = styled.div`
  padding: 16px;
  cursor: move;
  background-color: #2c2c2c;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  user-select: none;
`;

const Content = styled.div`
  padding: 16px;
`;

const Button = styled.button<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 12px;
  background-color: ${props => props.color || '#1e90ff'};
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.color ? props.color + 'dd' : '#1e90ffdd'};
  }

  &:disabled {
    background-color: #666;
    cursor: not-allowed;
  }
`;

const ButtonNoWidth = styled.button<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 12px;
  background-color: ${props => props.color || '#1e90ff'};
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;

  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.color ? props.color + 'dd' : '#1e90ffdd'};
  }

  &:disabled {
    background-color: #666;
    cursor: not-allowed;
  }
`;
const Preview = styled.div`
  margin-top: 12px;
  img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
  }
`;

const SkyboxList = styled.div`
  margin: 12px 0;
  max-height: 200px;
  overflow-y: auto;
`;

const SkyboxItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  padding: 8px;
  background: ${props => props.isActive ? '#2c2c2c' : 'transparent'};
  border-radius: 4px;
  align-items: center;
  img {
    width: 60px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
  }
  button {
    flex-shrink: 0;  // Prevent buttons from shrinking
  }
  .controls {
    display: flex;
    gap: 4px;
    margin-left: auto;  // Push controls to the right
  }
`;

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string;
}

interface SkyboxData {
  id: string;
  url: string;
}

interface SkyboxMenuProps {
  skyboxes: SkyboxData[];
  setSkyboxes: React.Dispatch<React.SetStateAction<SkyboxData[]>>;
  activeSkyboxUrl: string | null;
  setActiveSkyboxUrl: (url: string | null) => void;
  sceneId: string;
  backgroundColor: string;
  setBackgroundColor: (color: string) => void;
}

const SkyboxMenu: React.FC<SkyboxMenuProps> = ({
  skyboxes,
  setSkyboxes,
  activeSkyboxUrl,
  setActiveSkyboxUrl,
  sceneId,
  backgroundColor,
  setBackgroundColor,
}) => {
  const { currentUser } = useAuth();

  const handleFileSelect = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !currentUser) return;

    try {
      const skyboxId = `${sceneId}-${Date.now()}`;
      const skyboxRef = ref(storage, `users/${currentUser.uid}/skyboxes/${skyboxId}.jpg`);
      await uploadBytes(skyboxRef, file);
      const url = await getDownloadURL(skyboxRef);
      
      setSkyboxes((prev: SkyboxData[]) => [...prev, { id: skyboxId, url }]);
      if (!activeSkyboxUrl) {
        setActiveSkyboxUrl(url);
      }
    } catch (error) {
      console.error('Error uploading skybox:', error);
      alert('Error uploading skybox image');
    }
  }, [setSkyboxes, currentUser, sceneId, activeSkyboxUrl, setActiveSkyboxUrl]);

  const handleDelete = useCallback(async (skyboxId: string, url: string) => {
    if (!currentUser) return;

    try {
      const skyboxRef = ref(storage, url);
      await deleteObject(skyboxRef);
      
      setSkyboxes((prev: SkyboxData[]) => prev.filter(skybox => skybox.id !== skyboxId));
      if (url === activeSkyboxUrl) {
        setActiveSkyboxUrl(null);
      }
    } catch (error) {
      console.error("Error deleting skybox:", error);
    }
  }, [setSkyboxes, currentUser, activeSkyboxUrl, setActiveSkyboxUrl]);

  return (
    <Draggable handle=".handle">
      <Container>
        <Handle className="handle">
          <h3 style={{ margin: 0 }}>Environment Skybox</h3>
        </Handle>
        <Content>
          <BackgroundColorSelector
            backgroundColor={backgroundColor}
            setBackgroundColor={setBackgroundColor}
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleFileSelect}
            style={{ display: 'none' }}
            id="skybox-upload"
          />
          <ButtonNoWidth as="label" htmlFor="skybox-upload" color="#1e90ff">
            <FiUpload /> Upload Skybox
          </ButtonNoWidth>

          <SkyboxList>
            {skyboxes.map(skybox => (
              <SkyboxItem key={skybox.id} isActive={skybox.url === activeSkyboxUrl}>
                <img src={skybox.url} alt="Skybox preview" />
                <div className="controls">
                  <ButtonNoWidth
                    as="button"
                    onClick={() => setActiveSkyboxUrl(activeSkyboxUrl === skybox.url ? null : skybox.url)}
                    color={skybox.url === activeSkyboxUrl ? '#4CAF50' : '#1e90ff'}
                  >
                    {skybox.url === activeSkyboxUrl ? 'Disable' : 'Enable'}
                  </ButtonNoWidth>
                  <ButtonNoWidth
                    as="button"
                    onClick={() => handleDelete(skybox.id, skybox.url)}
                    color="#dc3545"
                  >
                    <FiTrash2 />
                  </ButtonNoWidth>
                </div>
              </SkyboxItem>
            ))}
          </SkyboxList>
        </Content>
      </Container>
    </Draggable>
  );
};

export default SkyboxMenu;
