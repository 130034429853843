/**
 * AudioManager: Centralized utility for managing audio playback across the application
 * 
 * Ensures consistent audio handling, prevents duplicate sound playback,
 * and supports both spatial and non-spatial sounds
 * 
 * Last modified: 2024-02-21
 */
import * as BABYLON from '@babylonjs/core';
import { AudioInteractionData, PlaySoundOptions } from '../types/SceneTypes';

class AudioManagerClass {
  private activeSounds: Map<string, { sound: BABYLON.Sound; data: AudioInteractionData }> = new Map();
  private isMuted: boolean = false;

  /**
   * Play a sound with advanced configuration
   * @param audioData Audio interaction data
   * @param options Additional playback options
   */
  playSound(audioData: AudioInteractionData, options: PlaySoundOptions = {}): void {
    if (this.isMuted) return;

    const { 
      scene, 
      position, 
      useCorsProxy = false, 
      corsProxyUrl = '' 
    } = options;

    // Check if sound is already playing and not spatial
    const existingSound = this.activeSounds.get(audioData.id);
    if (existingSound && existingSound.sound.isPlaying && !audioData.spatialSound) {
      return;
    }

    // Dispose existing non-playing sound
    if (existingSound && !existingSound.sound.isPlaying) {
      existingSound.sound.dispose();
      this.activeSounds.delete(audioData.id);
    }

    if (!scene) {
      console.warn(`Cannot play sound ${audioData.id}: No scene provided`);
      return;
    }

    const audioUrl = useCorsProxy && corsProxyUrl 
      ? `${corsProxyUrl}${audioData.url}` 
      : audioData.url;

    const sound = new BABYLON.Sound(
      audioData.id,
      audioUrl,
      scene,
      () => {
        sound.play();
        scene.sounds?.push(sound);
      },
      {
        loop: audioData.loop ?? true,
        volume: audioData.volume ?? 1,
        spatialSound: audioData.spatialSound ?? false,
        distanceModel: audioData.distanceModel ?? 'exponential',
        maxDistance: audioData.maxDistance ?? 100,
        refDistance: audioData.refDistance ?? 1,
        rolloffFactor: audioData.rolloffFactor ?? 1,
      }
    );

    // Set sound position for spatial sounds
    if (audioData.spatialSound && position) {
      sound.setPosition(position);
    }

    // Store metadata
    sound.metadata = {
      stopOnExit: audioData.stopOnExit,
      spatialSound: audioData.spatialSound
    };

    this.activeSounds.set(audioData.id, { sound, data: audioData });
  }

  /**
   * Get the sound instance for a specific ID
   * @param id Sound identifier
   * @returns Sound instance or undefined
   */
  getSoundInstance(id: string): BABYLON.Sound | undefined {
    return this.activeSounds.get(id)?.sound;
  }

  /**
   * Stop a specific sound by its ID
   * @param id Sound identifier
   */
  stopSound(id: string): void {
    const soundEntry = this.activeSounds.get(id);
    if (soundEntry) {
      soundEntry.sound.stop();
      soundEntry.sound.dispose();
      this.activeSounds.delete(id);
    }
  }

  /**
   * Stop all currently playing sounds
   */
  stopAllSounds(): void {
    this.activeSounds.forEach(({ sound }) => {
      sound.stop();
      sound.dispose();
    });
    this.activeSounds.clear();
  }

  /**
   * Check if a specific sound is currently playing
   * @param id Sound identifier
   * @returns Boolean indicating if the sound is playing
   */
  isSoundPlaying(id: string): boolean {
    const soundEntry = this.activeSounds.get(id);
    return soundEntry ? soundEntry.sound.isPlaying : false;
  }

  /**
   * Mute all sounds
   */
  mute(): void {
    this.isMuted = true;
    this.stopAllSounds();
  }

  /**
   * Unmute sounds
   */
  unmute(): void {
    this.isMuted = false;
  }
}

export const AudioManager = new AudioManagerClass();
export default AudioManager;
