import { useEffect, useRef, useState } from "react";
import { SplatSwapPoint } from "../types/SceneTypes";
import * as BABYLON from "@babylonjs/core";
import { NodeMaterial } from "@babylonjs/core";

interface PreloadedSplat {
  url: string;
  meshes: BABYLON.AbstractMesh[];
}

export const useSplatPreloader = (
  scene: BABYLON.Scene | null,
  additionalSplats: SplatSwapPoint[],
  currentSplatIndex: number,
  enabled: boolean = true,
  useNodeMaterial: boolean = true
) => {
  const [isPreloading, setIsPreloading] = useState(false);
  const preloadedSplatsRef = useRef<Map<string, PreloadedSplat>>(new Map());
  const preloadQueueRef = useRef<string[]>([]);

  // Initialize materials
  useEffect(() => {
    if (!scene) return;

    // Cleanup
    return () => {};
  }, [scene]);

  // Function to preload a single splat
  const preloadSplat = async (url: string) => {
    if (!scene || preloadedSplatsRef.current.has(url)) return;

    try {
      const result = await BABYLON.SceneLoader.ImportMeshAsync(
        "",
        url,
        "",
        scene
      );

      // Store the loaded meshes but make them invisible
      result.meshes.forEach((mesh) => {
        scene.addMesh(mesh);
        mesh.isVisible = false;
        mesh.isPickable = false;
      });

      preloadedSplatsRef.current.set(url, {
        url,
        meshes: result.meshes,
      });

      console.log(`SPLATSWAP:: [PRELOADER] Successfully preloaded splat: ${url}`);
    } catch (error) {
      console.error(`SPLATSWAP:: [PRELOADER] Failed to preload splat: ${url}`, error);
    }
  };

  // Function to show a preloaded splat
  const showPreloadedSplat = async (url: string) => {
    const preloadedSplat = preloadedSplatsRef.current.get(url);
    if (!preloadedSplat || !scene) return false;

    let meshFadeMaterial;
    if (useNodeMaterial) {
      // Clone the material for this instance to avoid shared state
      meshFadeMaterial = await NodeMaterial.ParseFromFileAsync(
        "nodeMat",
        "https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Fmaterials%2FsplatFadeNodeMaterial.json?alt=media&token=00ea0eea-ea77-40bf-9f98-ed9bc54c25f9",
        scene
      );
    }

    for (const mesh of preloadedSplat.meshes) {
      mesh.isVisible = true;
      mesh.isPickable = true;
      
      if (useNodeMaterial && meshFadeMaterial) {
        mesh.material = meshFadeMaterial;
        const fadeAmountFloat = meshFadeMaterial.getBlockByName("FadeAmountFloat");
        if (fadeAmountFloat && fadeAmountFloat.isInput) {
          //@ts-ignore
          fadeAmountFloat._storedValue = -10;
          const observer = scene.onBeforeRenderObservable.add(() => {
            //@ts-ignore
            fadeAmountFloat._storedValue +=
              scene.getEngine().getDeltaTime() / 100;
            //@ts-ignore
            if (fadeAmountFloat._storedValue >= 10) {
              scene.onBeforeRenderObservable.remove(observer);
            }
          });
        }
      }
    }

    // Hide all other preloaded splats
    Array.from(preloadedSplatsRef.current.keys()).forEach(async (splaturl) => {
      if (url === splaturl) return;
      const preloadedSplat = preloadedSplatsRef.current.get(splaturl);
      console.log("SPLATSWAP:: [PRELOADER] Attempting to hide splat:", splaturl);
      if (preloadedSplat) {
        hidePreloadedSplat(splaturl);
      }
    });

    return true;
  };

  // Function to hide a preloaded splat with inverse fade effect
  const hidePreloadedSplat = async (url: string) => {
    const preloadedSplat = preloadedSplatsRef.current.get(url);
    if (!preloadedSplat || !scene) return false;

    if (useNodeMaterial) {
      const meshInverseMaterial = await NodeMaterial.ParseFromFileAsync(
        "nodeMatInverse",
        "https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Fmaterials%2FsplatFadeNodeMaterialInverse.json?alt=media&token=669d70b9-5957-4070-8b80-d01f9327fa08",
        scene
      );

      for (const mesh of preloadedSplat.meshes) {
        mesh.material = meshInverseMaterial;

        const fadeAmountFloat =
          meshInverseMaterial.getBlockByName("FadeAmountFloat");
        if (fadeAmountFloat && fadeAmountFloat.isInput) {
          // Start from 10 and decrease to -10
          //@ts-ignore
          fadeAmountFloat._storedValue = 10;
          const observer = scene.onBeforeRenderObservable.add(() => {
            //@ts-ignore
            fadeAmountFloat._storedValue -=
              scene.getEngine().getDeltaTime() / 100;
            //@ts-ignore
            if (fadeAmountFloat._storedValue <= -10) {
              mesh.isVisible = false;
              mesh.isPickable = false;
              scene.onBeforeRenderObservable.remove(observer);
              meshInverseMaterial.dispose();
            }
          });
        }
      }
    } else {
      console.log("SPLATSWAP:: [PRELOADER] Hiding splat without node material:", url);
      for (const mesh of preloadedSplat.meshes) {
        mesh.isVisible = false;
        mesh.isPickable = false;
      }
      console.log("SPLATSWAP:: [PRELOADER] Successfully hid splat:", url);
    }
  };

  // Function to dispose of a preloaded splat
  const disposePreloadedSplat = (url: string) => {
    const preloadedSplat = preloadedSplatsRef.current.get(url);
    if (preloadedSplat) {
      preloadedSplat.meshes.forEach((mesh) => mesh.dispose());
      preloadedSplatsRef.current.delete(url);
    }
  };

  //add initial splat via a supplied mesh
  const addInitialSplat = (url: string, mesh: BABYLON.AbstractMesh) => {
    if (!scene) return;

    preloadedSplatsRef.current.set(url, {
      url: url,
      meshes: [mesh],
    });

    console.log(`SPLATSWAP:: [PRELOADER] Successfully added initial splat`);
  };

  // Effect to manage preloading
  useEffect(() => {
    if (!enabled || !scene || additionalSplats.length === 0) {
      console.log("SPLATSWAP:: [PRELOADER] Skipping preload - conditions not met:", {
        enabled,
        hasScene: !!scene,
        additionalSplatsLength: additionalSplats.length
      });
      return;
    }

    const preloadNextSplats = async () => {
      setIsPreloading(true);

      // Determine which splats to preload (next 2 splats)
      const splatUrls = additionalSplats.map((splat) => splat.url);

      const nextIndexes = [
        (currentSplatIndex + 1) % additionalSplats.length, //only load the next splat and this is = to the current splat index because the inital splat is not included in the additional splats
      ];

      console.log("SPLATSWAP:: preloading splats:: nextIndexes", nextIndexes);

      const urlsToPreload = nextIndexes
        .map((index) => splatUrls[index])
        .filter((url) => url && !preloadedSplatsRef.current.has(url));

      // Add to preload queue
      preloadQueueRef.current = urlsToPreload;

      // Preload splats
      for (const url of urlsToPreload) {
        await preloadSplat(url);
      }

      setIsPreloading(false);
    };

    preloadNextSplats();

    // Cleanup function
    return () => {
      if (!enabled) {
        // Dispose all preloaded splats when disabled
        Array.from(preloadedSplatsRef.current.keys()).forEach((url) => {
          disposePreloadedSplat(url);
        });
      }
    };
  }, [scene, currentSplatIndex, enabled, additionalSplats]);

  return {
    isPreloading,
    showPreloadedSplat,
    hidePreloadedSplat,
    hasPreloadedSplat: (url: string) => preloadedSplatsRef.current.has(url),
    addInitialSplat,
    disposePreloadedSplat,
  };
};
