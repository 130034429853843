/*
File: MainScene.tsx
Description: Main scene component that handles model loading and scene setup
Last modified: 2024-02-21
Changes: 
- Added loading progress tracking
- Connected progress to Preloader component
- Connected progress tracking to useSceneSetup hook
- Added splat swapping functionality
- Added initial splat URL tracking
- Modified preloader behavior for splat swaps
- Added scroll pausing during splat loading
*/

import React, { useRef, useEffect, useState } from "react";
import * as BABYLON from "@babylonjs/core";
import { User } from "firebase/auth";
import { toast } from "react-toastify";
import BabylonScene, { BabylonSceneRef } from "./BabylonScene";
import SceneControls from "./SceneControls";
import FileDropZone from "./FileDropZone";
import SceneContainer from "./SceneContainer";
import EditCameraToggle from "../EditCameraToggle";
import { useSceneState } from "../../hooks/useSceneState";
import { useSceneSetup } from "../../hooks/useSceneSetup";
import { useMobileDetection } from "../../hooks/useMobileDetection";
import { useSceneFiles } from "../../hooks/useSceneFiles";
import { useSplatPreloader } from "../../hooks/useSplatPreloader";
import MobileOverlay from "../MobileOverlay";
import Preloader from "../Preloader";
import loadModelFile from "../../tools/LoadModelFile";
import { useAuth } from "../../contexts/AuthContext";
import HotspotManager from "../HotspotManager";
import HotspotContentDisplay from "../HotspotContentDisplay";
import SkyboxMenu from "../SkyboxMenu";
import { useHotspotImagePreloader } from '../../hooks/useHotspotImagePreloader';

interface MainSceneProps {
  currentUser?: User | null;
  hasAcceptedLicense?: boolean;
}

const MainScene: React.FC<MainSceneProps> = ({
  currentUser,
  hasAcceptedLicense,
}) => {
  const { userProfile } = useAuth();
  const sceneState = useSceneState();
  const [useCorsProxy, setUseCorsProxy] = React.useState(false);
  const [corsProxyUrl, setCorsProxyUrl] = React.useState(
    "https://cors-anywhere.herokuapp.com/"
  );
  const [loadingProgress, setLoadingProgress] = useState<number | undefined>(undefined);
  const [showSplatControls, setShowSplatControls] = useState(false);
  const infoTextRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useMobileDetection();
  const isSceneReadyRef = useRef<boolean>(false);
  const babylonSceneRef = useRef<BabylonSceneRef>(null);
  const hotspotManagerRef = useRef<any>(null);
  const [isCapturingThumbnail, setIsCapturingThumbnail] = useState(false);
  const capturePromiseRef = useRef<{
    resolve: (blob: Blob) => void;
    reject: (error: Error) => void;
  } | null>(null);
  const isInitialLoadRef = useRef<boolean>(true);
  const scrollPausedRef = useRef<boolean>(false);

  const { handleSceneReady, setupEventListeners } = useSceneSetup({
    sceneRef: sceneState.sceneRef,
    cameraRef: sceneState.cameraRef,
    loadedMeshesRef: sceneState.loadedMeshesRef,
    localModelFileRef: sceneState.localModelFileRef,
    setIsModelLocal: sceneState.setIsModelLocal,
    infoTextRef,
    setIsSplatLoading: sceneState.setIsSplatLoading,
    autoFrame: sceneState.autoFrame,
    setSceneType: sceneState.setSceneType,
    renderHotspots: () => hotspotManagerRef.current?.renderHotspots(),
    onProgress: setLoadingProgress,
    useNodeMaterial: sceneState.useNodeMaterial,
  });

  useHotspotImagePreloader(sceneState.hotspots || []);

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        if (sceneState.sceneRef.current) {
          sceneState.localModelFileRef.current = file;
          setLoadingProgress(0);
          const loadedMeshes = await loadModelFile(
            file,
            sceneState.sceneRef.current,
            sceneState.autoFrame,
            sceneState.setIsModelLocal,
            infoTextRef,
            sceneState.setIsSplatLoading,
            sceneState.setSceneType,
            () => hotspotManagerRef.current?.renderHotspots(),
            (progress) => setLoadingProgress(progress),
            sceneState.useNodeMaterial
          );
          
          if (loadedMeshes) {
            sceneState.setIsModelLocal(true);
            sceneState.loadedMeshesRef.current = loadedMeshes;
          }
          setLoadingProgress(undefined);
        }
      } catch (error) {
        console.error("Error loading file:", error);
        sceneState.setIsModelLocal(false);
        setLoadingProgress(undefined);
      }
    }
  };

  const handleThumbnailCapture = (blob: Blob) => {
    console.log("MainScene received thumbnail blob:", {
      size: blob?.size,
      type: blob?.type,
      exists: !!blob
    });
    sceneState.setThumbnailBlob(blob);
    if (isCapturingThumbnail) {
      toast.success("Preview image captured successfully!", {
        position: "bottom-right",
        autoClose: 3000
      });
      setIsCapturingThumbnail(false);
      if (capturePromiseRef.current) {
        console.log("Resolving capture promise with blob");
        capturePromiseRef.current.resolve(blob);
        capturePromiseRef.current = null;
      }
    }
  };

  const handleCapturePreview = async () => {
    try {
      console.log("Starting manual thumbnail capture...");
      setIsCapturingThumbnail(true);
      toast.info("Capturing preview image...", {
        position: "bottom-right",
        autoClose: 2000
      });

      if(!babylonSceneRef.current) {
        throw new Error("Babylon scene ref is not available");
      }
      const blob = await babylonSceneRef.current.captureScreenshot();

      console.log("Manual capture completed successfully, blob:", {
        size: blob?.size,
        type: blob?.type,
        exists: !!blob
      });

      return blob;
    } catch (error) {
      console.error("Error capturing preview:", error);
      toast.error("Failed to capture preview image", {
        position: "bottom-right",
        autoClose: 5000
      });
      setIsCapturingThumbnail(false);
      throw error;
    }
  };

  const ensureThumbnail = async () => {
    if (!sceneState.thumbnailBlobRef.current) {
      console.log("No existing thumbnail, starting automatic capture...");
      try {
        const blob = await handleCapturePreview();
        console.log("Automatic capture completed, blob:", blob);
        return blob;
      } catch (error) {
        console.error("Error during automatic capture:", error);
        toast.error("Failed to capture preview image automatically", {
          position: "bottom-right",
          autoClose: 5000
        });
        return null;
      }
    }
    console.log("Using existing thumbnail:", sceneState.thumbnailBlobRef.current);
    return sceneState.thumbnailBlobRef.current;
  };

  const sceneFiles = useSceneFiles({
    ...sceneState,
    collisionMeshesRef: sceneState.collisionMeshesRef,
    setCurrentLoadedSceneId: sceneState.setCurrentLoadedSceneId,
    ensureThumbnail,
  });
  
  useEffect(() => {
    const cleanup = setupEventListeners();
    return () => {
      cleanup();
      setLoadingProgress(undefined);
    };
  }, [setupEventListeners]);
  
  const onSceneReady = React.useCallback(
    (scene: BABYLON.Scene, camera: BABYLON.UniversalCamera) => {
      console.log("Scene ready callback");
      isSceneReadyRef.current = true;
      handleSceneReady(scene, camera);
    },
    [handleSceneReady]
  );

  // Initialize splat preloader
  const { isPreloading, showPreloadedSplat, hasPreloadedSplat, addInitialSplat } = useSplatPreloader(
    sceneState.sceneRef.current,
    sceneState.additionalSplats,
    sceneState.currentSplatIndex,
    true,
    sceneState.useNodeMaterial // Always enable preloading by default
  );

  // Ref to track currently loading URL
  const loadingUrlRef = useRef<string | null>(null);

  useEffect(() => {
    const loadModel = async () => {
      console.log("SPLATSWAP:: Loading model from URL:", sceneState.loadedModelUrl);
      
      // Prevent duplicate loading of same URL
      if (loadingUrlRef.current === sceneState.loadedModelUrl) {
        console.log("SPLATSWAP:: Already loading this URL, skipping:", sceneState.loadedModelUrl);
        return;
      }
      
      scrollPausedRef.current = true;
      loadingUrlRef.current = sceneState.loadedModelUrl;
      // Check if the splat is preloaded
      if (sceneState.loadedModelUrl && hasPreloadedSplat(sceneState.loadedModelUrl)) {
 
        console.log('SPLATSWAP:: Existing Meshes Hidden: Showing preloaded splat:', sceneState.loadedModelUrl);  
        showPreloadedSplat(sceneState.loadedModelUrl);
        scrollPausedRef.current = false;
        loadingUrlRef.current = null; // Clear loading URL after showing preloaded splat
        return;
      }

      if (
        sceneState.loadedModelUrl &&
        sceneState.sceneRef.current &&
        isSceneReadyRef.current
      ) {
        try {
          if (!sceneState.sceneRef.current.getEngine()) {
            console.warn("Scene engine not ready, waiting...");
            return;
          }

          // Only show loading progress and preloader for initial load
          if (isInitialLoadRef.current) {
            setLoadingProgress(0);
            sceneState.setIsSplatLoading(true);
          }

          console.log("SPLATSWAP:: NOT PRELOADED: Loading model from URL:", sceneState.loadedModelUrl);

          const loadedMeshes = await loadModelFile(
            sceneState.loadedModelUrl,
            sceneState.sceneRef.current,
            sceneState.autoFrame,
            sceneState.setIsModelLocal,
            infoTextRef,
            // Skip preloader for subsequent loads
            (loading) => {
              if (isInitialLoadRef.current) {
                sceneState.setIsSplatLoading(loading);
              }
            },
            sceneState.setSceneType,
            () => hotspotManagerRef.current?.renderHotspots(),
            // Only show progress for initial load
            (progress) => {
              if (isInitialLoadRef.current) {
                setLoadingProgress(progress);
              }
            }, 
            sceneState.useNodeMaterial
          );

          scrollPausedRef.current = false;

          if (loadedMeshes) {
            sceneState.loadedMeshesRef.current = loadedMeshes;
            sceneState.setIsModelLocal(true);

            if (sceneState.waypoints.length > 0) {
              sceneState.setCameraMode("hybrid");
            }
            if (isInitialLoadRef.current) {
               addInitialSplat(sceneState.loadedModelUrl, loadedMeshes[0]);
            }
          }
          
          if (isInitialLoadRef.current) {
            setLoadingProgress(undefined);
            isInitialLoadRef.current = false;
          }
          
          loadingUrlRef.current = null; // Clear loading URL after successful load

        } catch (error) {
          console.error("Error loading model from URL:", error);
          if (infoTextRef.current) {
            infoTextRef.current.style.display = "block";
            infoTextRef.current.innerText =
              "Error loading model from URL: " + (error as Error).message;
          }
          scrollPausedRef.current = false;
          sceneState.setIsLoading(false);
          sceneState.setIsSplatLoading(false);
          sceneState.setIsModelLocal(false);
          setLoadingProgress(undefined);
        }
      }
    };

    loadModel();
  }, [sceneState.loadedModelUrl]);

  useEffect(() => {
    console.log("Loading state changed:", sceneState.isLoading);
  }, [sceneState.isLoading]);

  useEffect(() => {
    console.log("isSplatLoading State Changed:", sceneState.isSplatLoading);
  }, [sceneState.isSplatLoading]);

  return (
    <SceneContainer>
      <FileDropZone 
        infoTextRef={infoTextRef} 
        onFileSelect={handleFileSelect}
        isFileLoaded={sceneState.isModelLocal}
      />

      <BabylonScene
        ref={babylonSceneRef}
        onSceneReady={onSceneReady}
        onSceneCapture={handleThumbnailCapture}
        backgroundColor={sceneState.backgroundColor}
        cameraMovementSpeed={sceneState.cameraMovementSpeed}
        cameraRotationSensitivity={sceneState.cameraRotationSensitivity}
        isMobile={isMobile}
        setIsLoading={sceneState.setIsLoading}
        pathRef={sceneState.pathRef}
        rotationsRef={sceneState.rotationsRef}
        scrollPositionRef={sceneState.scrollPositionRef}
        scrollTargetRef={sceneState.scrollTargetRef}
        userControlRef={sceneState.userControlRef}
        cameraModeRef={sceneState.cameraModeRef}
        setScrollPercentage={sceneState.setScrollPercentage}
        transitionSpeedRef={sceneState.transitionSpeedRef}
        waypoints={sceneState.waypoints}
        animationFrames={sceneState.animationFrames}
        scrollSpeed={sceneState.scrollSpeed}
        isEditMode={sceneState.isEditMode}
        autoPlayEnabled={sceneState.autoPlayEnabled}
        autoPlaySpeed={sceneState.autoPlaySpeed}
        loopMode={sceneState.loopMode}
        setCurrentWaypointTitle={sceneState.setCurrentWaypointTitle}
        setInfoPopupText={sceneState.setInfoPopupText}
        lights={sceneState.lights}
        scrollPausedRef={scrollPausedRef}
        additionalSplatsRef={sceneState.additionalSplatsRef}
        loadedModelUrl={sceneState.loadedModelUrl || sceneState.savedModelUrl || ''}
        setLoadedModelUrl={sceneState.setLoadedModelUrl}
        setCurrentSplatIndex={sceneState.setCurrentSplatIndex}
        waypointsRef={sceneState.waypointsRef}
        savedModelUrlRef={sceneState.savedModelUrlRef}
        playerHeight={sceneState.playerHeight}
        fov={sceneState.fov}
        skyboxUrl={sceneState.activeSkyboxUrl}
        invertCameraRotation={sceneState.invertCameraRotation}
      />

      {isMobile && <MobileOverlay />}

      <Preloader
        isLoading={sceneState.isLoading || sceneState.isSplatLoading}
        progress={loadingProgress}
      />

      <SceneControls
        sceneState={sceneState}
        currentUser={currentUser}
        hasAcceptedLicense={hasAcceptedLicense}
        useCorsProxy={useCorsProxy}
        setUseCorsProxy={setUseCorsProxy}
        corsProxyUrl={corsProxyUrl}
        setCorsProxyUrl={setCorsProxyUrl}
        isMobile={isMobile}
        sceneFiles={sceneFiles}
        isPro={userProfile?.isPro || false}
        isBusiness={(userProfile?.proTier === "business" || userProfile?.proTier === "enterprise" || userProfile?.proTier==="Admin") || false}
        onCapturePreview={handleCapturePreview} 
        hotspotManagerRef={hotspotManagerRef}
        keepMeshesInMemory={sceneState.keepMeshesInMemory}
        setKeepMeshesInMemory={sceneState.setKeepMeshesInMemory}
      />

      {sceneState.isModelLocal && (
        <EditCameraToggle
          cameraMode={sceneState.cameraMode}
          setCameraMode={sceneState.setCameraMode}
        />
      )}
        {sceneState.displayedHotspot && (
          <HotspotContentDisplay
            hotspot={sceneState.displayedHotspot}
            onClose={() => sceneState.setDisplayedHotspot(null)}
            showCloseButton={sceneState.displayedHotspot.activationMode === "click"}
            mousePosition={{ x: sceneState.sceneRef.current?.pointerX || 0, y: sceneState.sceneRef.current?.pointerY || 0 }}
          />
        )}
    </SceneContainer>
  );
};

export default MainScene;
