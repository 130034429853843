/*
File: types.ts
Description: Type definitions for HTML generation
Last modified: 2024-02-19
Changes: 
- Added UIType enum
- Added isMobileDevice flag
- Added additionalSplats support
*/

import { CameraMode, SceneLight, SceneType, XRMode, AROptions, SplatSwapPoint } from '../../types/SceneTypes';
export interface Waypoint {
  x: number;
  y: number;
  z: number;
  rotation: {
    x: number;
    y: number;
    z: number;
    w: number;
  };
  interactions: Array<{
    id: string;
    type: string;
    data: any;
  }>;
  triggerDistance?: number;
  name?: string;
}

export interface Hotspot {
  id: string;
  position: {
    _x: number;
    _y: number;
    _z: number;
  };
  scale: {
    _x: number;
    _y: number;
    _z: number;
  };
  title: string;
  information?: string;
  photoUrl?: string;
  activationMode: "click" | "hover";
  color: string;
  type: "sphere" | "image";
  imageUrl?: string;
  imageName?: string;
}

export enum UIType {
  Standard = "standard",
  Minimal = "minimal",
  Pro = "pro"
}

export interface UIOptions {
  infoPosition: 'popup' | 'controls';
  buttonPosition: "inline" | "below";
  showStartExperience?: boolean; // Optional flag to control preloader visibility
  debugMode?: boolean; // Optional flag to enable debug features (inspector and collision visualization)
  uiType: UIType; // Type of UI to use (standard, minimal, or pro)
  hideWatermark?: boolean; // Optional flag to hide watermark (enterprise only)
}

export interface GenerateHTMLProps {
  modelUrl: string;
  includeScrollControls: boolean;
  waypoints: Waypoint[];
  backgroundColor: string;
  cameraMovementSpeed: number;
  cameraRotationSensitivity: number;
  scrollSpeed: number;
  animationFrames: number;
  hotspots:  Hotspot[];
  defaultCameraMode: CameraMode;
  cameraDampeningRef: number;
  uiColor: string;
  transitionSpeed: number;
  scrollButtonMode: "percentage" | "waypoint";
  scrollAmount: number;
  allowedCameraModes: string[];
  loopMode: boolean;
  autoPlaySpeed: number;
  autoPlayEnabled: boolean;
  collisionMeshesData: any[];
  includeXR: boolean;
  xrMode: XRMode;
  arOptions: AROptions;
  uiOptions: UIOptions;
  name: string;
  sceneId: string;
  userId: string;
  userName: string;
  lights: SceneLight[];
  sceneType?: SceneType;
  additionalSplats?: SplatSwapPoint[];
  isMobileDevice: boolean;
  keepMeshesInMemory?: boolean;
  playerHeight?: number; // Height of player camera in walk mode (meters)
  useNodeMaterial?: boolean; // Toggle for using node material on splats
  thumbnailUrl?: string; // URL of the preview image for OpenGraph
  fov?: number;
  activeSkyboxUrl?: string | null;
  invertCameraRotation?: boolean;
}
