/*
File: template.ts
Description: Generates the complete HTML template by combining all components
Last modified: 2024-02-07
Changes: 
- Updated Babylon.js script URLs to use local CDN
- Added fallback to public CDN if local files fail to load
- Added conditional rendering of camera mode buttons based on allowedCameraModes
- Added AR/VR buttons when XR is enabled
- Added WebXR permissions policy
- Ensured analytics code loads before main script
- Added conditional watermark rendering based on hideWatermark option
- Added collapsible help section with ? icon
- Removed movement instructions references
- Added drone/walk mode toggle system
*/

import { GenerateHTMLProps } from './types';
import { generateStyles } from './styles';
import { generateWaypointInteractionCode } from './waypointInteractions';
import { generateAnalyticsCode } from './analytics';
import { generateMainScript } from './mainScript';
import { isMobileDevice } from './helpers';

const generateWatermark = (userName: string, sceneId: string): string => {
  return `
    <div class="storysplat-watermark" style="
      position: fixed;
      bottom: 10px;
      right: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 12px;
      z-index: 1000;
      pointer-events: none;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    ">
      Created with <a href="https://storysplat.com?ref=${sceneId}" target="_blank" 
        style="color: #4CAF50; text-decoration: none; pointer-events: auto;">
        StorySplat
      </a>
    </div>
  `;
};

export const generateTemplate = (props: GenerateHTMLProps): string => {
  const {
    includeScrollControls,
    uiOptions,
    uiColor,
    name,
    sceneId,
    userId,
    userName,
    defaultCameraMode,
    autoPlayEnabled,
    scrollButtonMode,
    scrollAmount,
    allowedCameraModes,
    includeXR,
    xrMode
  } = props;

  const showStartExperience = uiOptions.showStartExperience;

  return `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
  <meta property="og:title" content="${name}">
  <meta property="og:type" content="website">
  <meta property="og:image" content="${props.thumbnailUrl}">
  <meta property="og:url" content="">
  <script>
    document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
  </script>
  ${includeXR ? `
  <meta http-equiv="Permissions-Policy" content="xr-spatial-tracking=*, xr=*">
  <meta name="apple-mobile-web-app-capable" content="yes">
  ` : ''}
  <title>${name}</title>
  <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
  <script async src="https://www.googletagmanager.com/gtag/js?id=G-L2NG77PKGG"></script>
  <script>
    ${generateAnalyticsCode({
      sceneId,
      userId,
      sceneName: name,
      userName
    })}
  </script>
  <style>
    ${generateStyles({
      uiColor,
      isMobileDevice: isMobileDevice(),
      uiOptions
    })}

    #helpButton {
      position: fixed;
      top: 10px;
      left: 10px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.6);
      color: white;
      border: 2px solid rgba(255, 255, 255, 0.2);
      cursor: pointer;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
      transition: all 0.3s ease;
    }

    #helpButton:hover {
      background: rgba(0, 0, 0, 0.8);
      border-color: rgba(255, 255, 255, 0.4);
      transform: scale(1.05);
    }

    #helpPanel {
      position: fixed;
      top: 55px;
      left: 10px;
      background: rgba(0, 0, 0, 0.85);
      color: white;
      padding: 20px;
      border-radius: 10px;
      max-width: 320px;
      z-index: 999;
      display: none;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
      border: 1px solid rgba(255, 255, 255, 0.1);
    }

    #helpPanel.visible {
      display: block;
      animation: fadeIn 0.3s ease;
    }

    @keyframes fadeIn {
      from { opacity: 0; transform: translateY(-10px); }
      to { opacity: 1; transform: translateY(0); }
    }

    #helpPanel h3 {
      margin: 0 0 15px 0;
      font-size: 18px;
      font-weight: 600;
      color: #4CAF50;
    }

    #helpPanel p {
      margin: 8px 0;
      line-height: 1.5;
      font-size: 14px;
    }

    #helpPanel strong {
      color: #4CAF50;
    }

    #exploreToggleContainer {
      position: fixed;
      bottom: 60px;
      right: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      z-index: 1000;
      height: 90px;
    }

    #droneToggleButton {
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      transition: all 0.3s ease;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #droneToggleButton svg {
      width: 24px;
      height: 24px;
      fill: currentColor;
    }

    #droneToggleButton.selected {
      background-color: ${uiColor};
    }

    #droneToggleButton:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }

    #walkToggleButton {
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      transition: all 0.3s ease;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #walkToggleButton svg {
      width: 24px;
      height: 24px;
      fill: currentColor;
    }

    #walkToggleButton.selected {
      background-color: ${uiColor};
    }

    #walkToggleButton:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
  </style>
</head>
<body>
  <button id="helpButton" title="Toggle Help">?</button>
  <div id="helpPanel">
    <h3>Controls & Help</h3>
    <p><strong>Camera Modes:</strong></p>
    <p>• Tour - Follow predefined path</p>
    <p>• Explore - Free movement</p>
    <p>• Hybrid - Mix of both</p>
    ${allowedCameraModes.includes('walk') ? '<p>• Walk - First-person mode with gravity</p>' : ''}
    <p><strong>Navigation:</strong></p>
    <p>• Mouse/Touch - Look around</p>
    <p>• WASD/Arrows - Move camera</p>
    ${!allowedCameraModes.includes('walk') ? '<p>• Q/E - Move up/down</p>' : ''}
    <p>• Scroll - Move along path</p>
    ${allowedCameraModes.includes('walk') ? '<p>• Enter walk mode in explore mode to walk with gravity</p>' : ''}
  </div>

  <script>
    window.cameraMode = '${defaultCameraMode}';
    window.autoPlayEnabled = ${autoPlayEnabled};
  </script>

  <div id="preloader">
    <div id="preloader-content">
      <div id="preloader-media">
        <img id="preloader-image" src="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Fimages%2FStorySplat.webp?alt=media&token=953e8ab3-1865-4ac1-a98d-b548b7066bda" alt="StorySplat Logo" />
        <lottie-player id="preloader-lottie" 
          src="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Flotties%2FstorySplatLottie.json?alt=media&token=d7edc19d-9cb8-4c6e-a94c-cba1d2b65d5e"
          background="transparent" 
          speed="1" 
          loop 
          autoplay>
        </lottie-player>
      </div>
    </div>
  </div>

  <button id="fullscreenButton" title="Toggle Fullscreen">
    <svg id="expandIcon" viewBox="0 0 24 24">
      <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"/>
    </svg>
    <svg id="compressIcon" viewBox="0 0 24 24" style="display: none;">
      <path d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"/>
    </svg>
  </button>

  ${showStartExperience ? `
  <div id="startButtonContainer">
    <button id="startButton">Start Experience</button>
  </div>
  ` : ''}
  <canvas id="renderCanvas"></canvas>

  <div id="hotspotContent"></div>
  <div id="infoPopup"></div>

  ${includeScrollControls
    ? `
    <div id="scrollControls">
      <div id="waypointInfo" style="display: none;"></div>

      <div id="scrollControlsContent">
        <div id="scrollPercentage">0%</div>
        <div id="progressBarContainer">
          <div id="progressBar"></div>
        </div>

        ${uiOptions.buttonPosition === "inline"
          ? `
        <div id="scrollControlsContentInline">
          <div id="scrollButtons">
            <button id="prevButton" class="button" onclick="handleButtonScroll(-1)">
              <span class="button-icon">←</span>
              <span class="button-text">${scrollButtonMode === 'percentage' ? `${scrollAmount}%` : 'Prev'}</span>
            </button>
            <button id="nextButton" class="button" onclick="handleButtonScroll(1)">
              <span class="button-text">${scrollButtonMode === 'percentage' ? `${scrollAmount}%` : 'Next'}</span>
              <span class="button-icon">→</span>
            </button>
          </div>
        </div>
        `
          : `
        <div id="scrollButtons">
          <button id="prevButton" class="button" onclick="handleButtonScroll(-1)">
            <span class="button-icon">←</span>
            <span class="button-text">${scrollButtonMode === 'percentage' ? `${scrollAmount}%` : 'Prev'}</span>
          </button>
          <button id="nextButton" class="button" onclick="handleButtonScroll(1)">
            <span class="button-text">${scrollButtonMode === 'percentage' ? `${scrollAmount}%` : 'Next'}</span>
            <span class="button-icon">→</span>
          </button>
        </div>
        `}
      </div>

      <div id="modeToggleContainer">
        <div id="modeToggle">
          ${allowedCameraModes.includes('walk') && !allowedCameraModes.includes('explore') ? 
            `<button id="modeWalk" class="mode-button" onclick="enableWalkMode()? changeCameraMode('walk') : null">Walk</button>` : ''}
          ${allowedCameraModes.includes('explore') ? 
            `<button id="modeExplore" class="mode-button" onclick="changeCameraMode('explore')">Explore</button>` : ''}
          ${allowedCameraModes.includes('tour') ? 
            `<button id="modeTour" class="mode-button" onclick="changeCameraMode('tour')">Tour</button>` : ''}
          ${allowedCameraModes.includes('hybrid') ? 
            `<button id="modeHybrid" class="mode-button" onclick="changeCameraMode('hybrid')">Hybrid</button>` : ''}
        </div>
      </div>
    </div>
    `
    : `
    <div id="modeToggleContainer">
      <div id="modeToggle">
        ${allowedCameraModes.includes('walk') && !allowedCameraModes.includes('explore') ? 
          `<button id="modeWalk" class="mode-button" onclick="enableWalkMode()? changeCameraMode('walk') : null">Walk</button>` : ''}
        ${allowedCameraModes.includes('explore') ? 
          `<button id="modeExplore" class="mode-button" onclick="changeCameraMode('explore')">Explore</button>` : ''}
        ${allowedCameraModes.includes('tour') ? 
          `<button id="modeTour" class="mode-button" onclick="changeCameraMode('tour')">Tour</button>` : ''}
        ${allowedCameraModes.includes('hybrid') ? 
          `<button id="modeHybrid" class="mode-button" onclick="changeCameraMode('hybrid')">Hybrid</button>` : ''}
       </div>
    </div>
    `}

  ${allowedCameraModes.includes('walk') && allowedCameraModes.includes('explore') ? `
    <div id="exploreToggleContainer">
      <button id="droneToggleButton" title="Explore Mode">
        <svg viewBox="0 0 24 24">
          <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
        </svg>
      </button>
      <button id="walkToggleButton" title="Walk Mode">
        <svg viewBox="0 0 24 24">
          <path d="M13.5 5.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM9.8 8.9L7 23h2.1l1.8-8 2.1 2v6h2v-7.5l-2.1-2 .6-3C14.8 12 16.8 13 19 13v-2c-1.9 0-3.5-1-4.3-2.4l-1-1.6c-.4-.6-1-1-1.7-1-.3 0-.5.1-.8.1L6 8.3V13h2V9.6l1.8-.7"/>
        </svg>
      </button>
    </div>` : ''}
  <button id="muteButton">🔊 Mute</button>

  ${!uiOptions.hideWatermark ? generateWatermark(userName, sceneId) : ''}

  <script src="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Fbabylon%2Fcdn%2Fbabylon-7.38.0.js?alt=media&token=848987a3-68a6-47b5-8f7b-0e42185b7a09" onerror="this.onerror=null;this.src='https://cdn.babylonjs.com/babylon.js'"></script>
  <script src="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Fbabylon%2Fcdn%2Fbabylonjs.loaders.min.js?alt=media&token=789d9d2f-c308-419c-9899-2650e62bdae1" onerror="this.onerror=null;this.src='https://preview.babylonjs.com/loaders/babylonjs.loaders.min.js'"></script>

  <script>
    ${generateWaypointInteractionCode({ uiOptions })}
    ${generateMainScript(props)}

    const helpButton = document.getElementById('helpButton');
    const helpPanel = document.getElementById('helpPanel');
    let helpVisible = false;

    helpButton.addEventListener('click', () => {
      helpVisible = !helpVisible;
      helpPanel.classList.toggle('visible', helpVisible);
    });

    document.addEventListener('click', (event) => {
      if (helpVisible && !helpPanel.contains(event.target) && event.target !== helpButton) {
        helpVisible = false;
        helpPanel.classList.remove('visible');
      }
    });

    function updateCameraModeButtons() {
      const buttons = document.querySelectorAll('.mode-button');
      buttons.forEach(button => {
        const mode = button.id.replace('mode', '').toLowerCase();
        button.classList.toggle('selected', mode === window.cameraMode || 
          (mode === 'explore' && (window.cameraMode === 'explore' || window.cameraMode === 'walk')));
      });

      // Update scroll controls visibility based on camera mode
      const scrollControls = document.getElementById('scrollControls');
      if (scrollControls) {
        scrollControls.classList.toggle('visible', window.cameraMode !== 'explore' && window.cameraMode !== 'walk');
      }

      // Update explore toggle buttons visibility and state
      const droneToggleButton = document.getElementById('droneToggleButton');
      const walkToggleButton = document.getElementById('walkToggleButton');
      
      if (droneToggleButton && walkToggleButton) {
        droneToggleButton.classList.toggle('selected', window.cameraMode === 'explore');
        walkToggleButton.classList.toggle('selected', window.cameraMode === 'walk');
      }
    }

    // Handle explore/walk mode buttons
    const droneToggleButton = document.getElementById('droneToggleButton');
    const walkToggleButton = document.getElementById('walkToggleButton');

    if (droneToggleButton) {
      droneToggleButton.addEventListener('click', () => {
        if (window.cameraMode === 'walk') {
          disableWalkMode();
        }
        changeCameraMode('explore');
      });
    }

    if (walkToggleButton) {
      walkToggleButton.addEventListener('click', () => {
        if (window.cameraMode !== 'walk' && enableWalkMode()) {
          changeCameraMode('walk');
        }
      });
    }

    const originalChangeCameraMode = window.changeCameraMode;
    window.changeCameraMode = function(mode) {
      originalChangeCameraMode(mode);
      updateCameraModeButtons();
    };

    updateCameraModeButtons();
  </script>
</body>
</html>
  `;
};
