import { GenerateHTMLProps } from '../types';

export const generateSkyboxSystem = (props: GenerateHTMLProps): string => {
  const { activeSkyboxUrl } = props;

  if (!activeSkyboxUrl) {
    return '';
  }

  return `
    // Create PhotoDome for skybox
    const skybox = new BABYLON.PhotoDome(
      "skybox",
      "${activeSkyboxUrl}",
      {
        resolution: 32,
        size: 1000
      },
      scene
    );

    // Cleanup skybox on scene dispose
    scene.onDisposeObservable.add(() => {
      if (skybox) {
        skybox.dispose();
      }
    });
  `;
};
