/*
File: LoadModelFile.ts
Description: Handles loading of model files into the BabylonJS scene
Last modified: 2024-02-21
Changes: 
- Added onProgress callback parameter to track loading progress
- Added progress tracking for ImportMeshAsync
*/

import addTooltipToMesh from "./AddToolTipToMesh";
import { SceneType } from "../types/SceneTypes";
import {
  AbstractMesh,
  FreeCamera,
  ISceneLoaderAsyncResult,
  ISceneLoaderProgressEvent,
  Mesh,
  Scene,
  SceneLoader,
  Vector3,
} from "@babylonjs/core";
import { NodeMaterial } from "@babylonjs/core/Materials/Node/nodeMaterial";
import "@babylonjs/core/Materials/Node/Blocks";

/**
 * Asynchronously loads a model file into the BabylonJS scene.
 *
 * @param fileOrUrl - The file or URL of the model to load.
 * @param scene - The BabylonJS scene where the model will be loaded.
 * @param shouldAutoFrame - Whether to automatically frame the scene after loading.
 * @param setIsModelLocal - State setter to indicate if the model is loaded locally.
 * @param infoTextRef - Ref to display informational text or errors.
 * @param setIsSplatLoading - State setter to indicate splat loading status.
 * @param setSceneType - State setter to set the type of scene (mesh/splat/ply)
 * @param renderHotspots - Optional callback to re-render hotspots after loading completes
 * @param onProgress - Optional callback to track loading progress (0-100)
 * @returns A promise that resolves to an array of loaded meshes or undefined if failed.
 */
const loadModelFile = async (
  fileOrUrl: File | string,
  scene: Scene,
  shouldAutoFrame: boolean,
  setIsModelLocal: React.Dispatch<React.SetStateAction<boolean>>,
  infoTextRef: React.RefObject<HTMLDivElement>,
  setIsSplatLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setSceneType: React.Dispatch<React.SetStateAction<SceneType | null>>,
  renderHotspots?: () => void,
  onProgress?: (progress: number) => void,
  useNodeMaterial?: boolean
): Promise<AbstractMesh[] | undefined> => {
  try {
    // Show loading state
    setIsSplatLoading(true);
    console.log("Splat is loading...");

    // Ensure scene is ready
    if (!scene.getEngine()) {
      throw new Error("Scene engine not ready");
    }

    if (infoTextRef.current) {
      infoTextRef.current.style.display = "block";
      infoTextRef.current.innerText = "Loading file... Please wait.";
    }

    // Define supported file extensions
    const loadExtensions = [".splat", ".ply", ".gltf", ".glb", ".spz"];

    const getFileExtension = (fileOrUrl: string | File) => {
      if (typeof fileOrUrl === "string") {
        const url = fileOrUrl.split(/[?#]/)[0]; // Remove query parameters and fragments
        const parts = url.split(".");
        return "." + (parts.pop()?.toLowerCase() || "");
      } else {
        const parts = fileOrUrl.name.split(".");
        return "." + (parts.pop()?.toLowerCase() || "");
      }
    };

    const fileExtension = getFileExtension(fileOrUrl);

    // Check for supported file formats
    if (!loadExtensions.includes(fileExtension)) {
      setIsSplatLoading(false);
      alert(
        "Unsupported file format. Please load a .splat, .ply, .gltf, or .glb file."
      );
      return;
    }

    // Set scene type based on file extension
    if (
      fileExtension === ".splat" ||
      fileExtension === ".ply" ||
      fileExtension === ".spz"
    ) {
      setSceneType(
        fileExtension === ".splat"
          ? "splat"
          : fileExtension === ".ply"
            ? "ply"
            : "spz"
      );
    } else {
      setSceneType("mesh"); // For .gltf and .glb files
    }

    /*     // Clean up existing resources in the scene
    scene.meshes.slice().forEach(mesh => {
      mesh.dispose(true, true); // Dispose mesh and its materials/textures
    });
    scene.materials.slice().forEach(material => {
      material.dispose();
    });
    scene.textures.slice().forEach(texture => {
      texture.dispose();
    });
 */

    let result: ISceneLoaderAsyncResult;
    let isLocal = false;

    // Configure loading options with progress tracking
    const loadingOptions = {
      onProgress: (event: ISceneLoaderProgressEvent) => {
        if (onProgress && event.lengthComputable) {
          const progressPercent = (event.loaded / event.total) * 100;
          console.log("Loading progress:", progressPercent.toFixed(2) + "%");
          onProgress(progressPercent);
        }
      },
    };

    if (typeof fileOrUrl === "string") {
      console.log("Loading model from URL:", fileOrUrl);
      // Load from URL with progress tracking
      result = await SceneLoader.ImportMeshAsync(
        "",
        fileOrUrl,
        "",
        scene,
        loadingOptions.onProgress
      );
      isLocal = false;
    } else {
      console.log("Loading model from file:", fileOrUrl.name);
      result = await SceneLoader.ImportMeshAsync(
        "",
        "",
        fileOrUrl,
        scene,
        loadingOptions.onProgress
      );
      isLocal = true;
    }

    const newMeshes = result.meshes;
    newMeshes.forEach((mesh) => {
      scene.addMesh(mesh);
      if (mesh instanceof Mesh) {
        mesh.position = Vector3.Zero();
      }
    });
    console.log("LoadModelFile:: Splat loaded, applying node maerial to mesh, useNodeMaterial: ", useNodeMaterial);

    //if mesh type === splat apply node material to the mesh
    if ((fileExtension === ".splat" || fileExtension === ".ply" || fileExtension === ".spz") && useNodeMaterial) {
      const nodeMaterial = await NodeMaterial.ParseFromFileAsync(
        "nodeMat",
        "https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/public%2Fmaterials%2FsplatFadeNodeMaterial.json?alt=media&token=00ea0eea-ea77-40bf-9f98-ed9bc54c25f9",
        scene
      );
      newMeshes.forEach((mesh) => {
        mesh.material = nodeMaterial;
      });
      console.log("nodeMaterial loaded");
      const fadeAmountFloat = nodeMaterial.getBlockByName("FadeAmountFloat");
      if (fadeAmountFloat && fadeAmountFloat.isInput) {
        console.log("fadeAmountFloat found", fadeAmountFloat);
        //ignore type error
        //@ts-ignore
        fadeAmountFloat._storedValue = -10;
        //tween this value to 10 over 1 second
        scene.registerBeforeRender(() => {
          //@ts-ignore
          fadeAmountFloat._storedValue +=
            scene.getEngine().getDeltaTime() / 100;
        });
      }
    }

    //

    // Frame new mesh in camera if autoFrame is enabled
    if (shouldAutoFrame) {
      const camera = scene.activeCamera as FreeCamera;
      let target = newMeshes[0];
      let scaleFactor = 0.5;

      //Helper function to calculate the distance needed to frame the target
      const frameTarget = (
        target: AbstractMesh,
        camera: FreeCamera,
        scaleFactor: number
      ) => {
        let bbInfo = target.getBoundingInfo();
        let box = bbInfo.boundingBox;
        let minX = Number.POSITIVE_INFINITY;
        let maxX = Number.NEGATIVE_INFINITY;
        let minY = Number.POSITIVE_INFINITY;
        let maxY = Number.NEGATIVE_INFINITY;
        let minZ = Number.POSITIVE_INFINITY;
        let maxZ = Number.NEGATIVE_INFINITY;
        let maxDistance = Number.NEGATIVE_INFINITY;
        let targetPosition = target.position.clone();
        let vectors = box.vectorsWorld;
        vectors.forEach((v) => {
          minX = Math.min(minX, v.x - targetPosition.x);
          maxX = Math.max(maxX, v.x - targetPosition.x);
          minY = Math.min(minY, v.y - targetPosition.y);
          maxY = Math.max(maxY, v.y - targetPosition.y);
          minZ = Math.min(minZ, v.z - targetPosition.z);
          maxZ = Math.max(maxZ, v.z - targetPosition.z);
          let md0 = Math.pow(
            Math.pow(maxX - minX, 2) + Math.pow(maxY - minY, 2),
            0.5
          );
          let md1 = Math.pow(
            Math.pow(maxX - minX, 2) + Math.pow(maxZ - minZ, 2),
            0.5
          );
          let md2 = Math.pow(
            Math.pow(maxY - minY, 2) + Math.pow(maxZ - minZ, 2),
            0.5
          );
          maxDistance = Math.max(
            maxDistance,
            Math.max(md0, Math.max(md1, md2))
          );
        });

        let fov = camera.fov;
        let scale = Math.min(
          Math.max(
            (maxDistance / Math.tan(fov * 0.5)) * scaleFactor,
            camera.minZ * 2
          ),
          camera.maxZ * 0.8
        );
        let normal = camera.getForwardRay(1).direction;
        camera.position = normal.scale(-scale).add(targetPosition);
      };

      if (camera && target) {
        frameTarget(target, camera, scaleFactor);
      }
    }

    // Hide the info text
    if (infoTextRef.current) {
      infoTextRef.current.style.display = "none";
    }

    console.log("Splat loaded");
    setIsSplatLoading(false);

    // Re-render hotspots after splat loading is complete
    if (renderHotspots) {
      console.log("Rendering hotspots...", renderHotspots());
      renderHotspots();
    }

    setIsModelLocal(isLocal); // Update state based on loading source

    // Ensure we send 100% progress when loading is complete
    if (onProgress) {
      onProgress(100);
    }

    return newMeshes;
  } catch (error) {
    console.error("Error loading model file:", error);
    if (infoTextRef.current) {
      infoTextRef.current.style.display = "block";
      infoTextRef.current.innerText =
        "Error loading file: " + (error as Error).message;
    }
    setIsSplatLoading(false);
    setSceneType(null); // Reset scene type on error
    return;
  }
};

export default loadModelFile;
