// Import necessary modules
import React from "react";
import styled from "styled-components";
import { FaDiscord } from "react-icons/fa"; // Import Discord icon from react-icons
import { FiExternalLink } from "react-icons/fi";
import { Link } from "react-router-dom";

// Styled components
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1e1e1e;
  color: white;
  font-size: 24px;
  text-align: center;
  z-index: 5000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  pointer-events: none;
`;

const Heading = styled.h1`
  margin-top: 60px; // Reduced from 120px to 40px
`;

const Paragraph = styled.p`
  padding: 0 15px;
  max-width: 600px;
`;

const ExamplesGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  width: calc(100% - 40px);
  max-width: 400px;
  margin: 0 20px 20px 20px;
  height: 300px;
  overflow-y: auto; // Added to prevent overflow
  scrollbar-width: thin; // For Firefox
  scrollbar-color: #4c4c4c #2c2c2c; // For Firefox

  // Webkit (Chrome, Safari, newer versions of Opera) scrollbar styling
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #2c2c2c;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #4c4c4c;
    border-radius: 4px;
  }
`;

const ExampleCard = styled.a`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #2c2c2c;
  border-radius: 8px;
  text-decoration: none;
  color: white;
  pointer-events: auto;
  transition: transform 0.2s ease, border-color 0.2s ease;
  border: 1px solid #3c3c3c;

  &:hover {
    transform: translateY(-2px);
    border-color: #4caf50;
  }
`;

const ExampleTitle = styled.h3`
  margin: 0 0 8px 0;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ExampleDescription = styled.p`
  margin: 0;
  color: #b0b0b0;
  font-size: 0.9rem;
  line-height: 1.4;
`;

const DiscoverButton = styled.a`
  position: absolute;
  bottom: 200px;
  display: flex;
  align-items: center;
  background-color: #4caf50;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  pointer-events: auto;
  transition:
    background-color 0.3s,
    transform 0.3s;

  &:hover {
    background-color: #45a049;
    transform: translateY(-2px);
  }

  svg {
    margin-right: 8px;
  }
`;

const DiscordButton = styled.a`
  position: absolute;
  bottom: 140px;
  display: flex;
  align-items: center;
  background-color: #7289da;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  pointer-events: auto;
  transition:
    background-color 0.3s,
    transform 0.3s;

  &:hover {
    background-color: #5b6eae;
    transform: translateY(-2px);
  }

  svg {
    margin-right: 8px;
  }
`;

const FooterText = styled.p`
  font-size: 14px;
  position: absolute;
  bottom: 90px;
  margin: 0;
`;

const FooterLink = styled.a`
  color: white;
  text-decoration: underline;
  position: absolute;
  bottom: 60px;
  pointer-events: auto;
  transition: color 0.3s;

  &:hover {
    color: #7289da;
  }
`;

// Main component
const MobileOverlay: React.FC = () => {
  return (
    <Container>
      <Heading>StorySplat</Heading>
      <Paragraph>
        Please visit on a desktop to use the editor. Check out some examples
        below.
      </Paragraph>
      <ExamplesGrid>
        <ExampleCard
          href="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/users%2FmFNJrfet99Qv9mMX2OC6hewy1xC2%2Fhtml%2FHead%20of%20a%20Warrior.html?alt=media&token=709078d8-84a8-44eb-b158-e4939a6ec526"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ExampleTitle>
            Head of A Warrior, Picasso
            <FiExternalLink size={16} />
          </ExampleTitle>
          <ExampleDescription>
            Experience Picasso's iconic sculpture in immersive 3D, capturing the raw emotion and geometric complexity of this masterpiece.
          </ExampleDescription>
        </ExampleCard>

        <ExampleCard
          href="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/users%2FmFNJrfet99Qv9mMX2OC6hewy1xC2%2Fhtml%2FSanta%20Maria%20Novella.html?alt=media&token=9abf226a-5c95-4819-83b3-d189da62fe82"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ExampleTitle>
            Santa Maria Novella
            <FiExternalLink size={16} />
          </ExampleTitle>
          <ExampleDescription>
            Explore the architectural magnificence of this historic basilica in Florence, showcasing stunning Gothic and Renaissance design elements.
          </ExampleDescription>
        </ExampleCard>

        <ExampleCard
          href="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/users%2FmFNJrfet99Qv9mMX2OC6hewy1xC2%2Fhtml%2FDonatello%20Sculpture.html?alt=media&token=e705c0bb-222c-4c6b-acbc-c3de9d1f6c43"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ExampleTitle>
            Donatello Sculpture
            <FiExternalLink size={16} />
          </ExampleTitle>
          <ExampleDescription>
            Discover the artistry of Donatello's sculpture in 3D, showcasing the masterful craftsmanship and lifelike details of this Renaissance masterpiece.
          </ExampleDescription>
        </ExampleCard>

        <ExampleCard
          href="https://firebasestorage.googleapis.com/v0/b/story-splat.firebasestorage.app/o/users%2FmFNJrfet99Qv9mMX2OC6hewy1xC2%2Fhtml%2FHead%20of%20Alexander%20the%20Great.html?alt=media&token=34c49c6a-19eb-4197-9525-b78b12c55690"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ExampleTitle>
            Alexander the Great
            <FiExternalLink size={16} />
          </ExampleTitle>
          <ExampleDescription>
            Experience the grandeur of Alexander the Great's iconic sculpture in 3D, capturing the power and majesty of this legendary historical figure.
          </ExampleDescription>
        </ExampleCard>
      </ExamplesGrid>
      <DiscoverButton
        href="/discover"
        target="_blank"
        rel="noopener noreferrer"
      >
        Discover Page
      </DiscoverButton>
      <DiscordButton
        href="https://discord.gg/XMv6jcNKcJ"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaDiscord size={20} />
        Join our Discord
      </DiscordButton>
      <FooterText>For more information visit</FooterText>
      <FooterLink
        href="https://sonnycirasuolo.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        sonnycirasuolo.com
      </FooterLink>
    </Container>
  );
};

export default MobileOverlay;
