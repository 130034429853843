/*
* LicenseButton.tsx
* Description: Component that handles displaying and managing the license agreement
* Last modified: 2024-02-14
* Changes: Added controlled mode support for non-standalone usage and business/enterprise license variants
*/

import React, { useState, useEffect } from "react";
import { Button, Modal, Typography, Checkbox } from "antd";
import { useAuth } from '../contexts/AuthContext';

const { Title, Paragraph, Text } = Typography;

interface LicenseButtonProps {
  standalone?: boolean;
  onAccept?: (popUp: 'export' | 'upload') => void;
  onCancel?: () => void;
  showAcceptButton?: boolean;
  isProcessing?: boolean;
  forceVisible?: boolean;
}

const LicenseButton: React.FC<LicenseButtonProps> = ({
  standalone = true,
  onAccept,
  onCancel,
  showAcceptButton = false,
  isProcessing = false,
  forceVisible = false
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasReadAndAccepted, setHasReadAndAccepted] = useState(false);
  const { hasAcceptedLicense, updateLicenseAcceptance, userProfile } = useAuth();

  // Effect to handle forced visibility
  useEffect(() => {
    if (!standalone && forceVisible) {
      setIsModalVisible(true);
    }
  }, [forceVisible, standalone]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    // Reset the checkbox state when closing
    setHasReadAndAccepted(false);
    setIsModalVisible(false);
    
    // If we're in non-standalone mode, we should call onCancel from parent
    if (!standalone && onCancel) {
      onCancel();
    }
  };

  const handleAccept = async () => {
    try {
      await updateLicenseAcceptance(true);
      setIsModalVisible(false);
      setHasReadAndAccepted(false); // Reset checkbox state
      if (onAccept) {
        onAccept('upload');
      }
    } catch (error) {
      console.error("Error accepting license:", error);
      alert("There was an error accepting the license. Please try again.");
    }
  };

  const renderFooter = () => {
    if (showAcceptButton) {
      return [
        <Button key="cancel" onClick={handleCancel}>
          Decline
        </Button>,
        <Button
          key="accept"
          type="primary"
          onClick={handleAccept}
          disabled={!hasReadAndAccepted || isProcessing}
          loading={isProcessing}
        >
          Accept
        </Button>
      ];
    }
    return [
      <Button key="ok" type="primary" onClick={handleOk}>
        OK
      </Button>
    ];
  };

  const renderLicenseContent = () => {
    const isBusinessOrEnterprise = userProfile?.proTier === 'business' || userProfile?.proTier === 'enterprise' || userProfile?.proTier === 'Admin';

    if (isBusinessOrEnterprise) {
      return (
        <>
          <Paragraph>
            <Text strong>Last Updated:</Text> November 5, 2024
          </Paragraph>
          <Paragraph>
            Thank you for choosing StorySplat! As a {userProfile?.proTier} subscriber,
            you have access to enhanced commercial usage rights for our software.
          </Paragraph>
          <Title level={4}>
            License Type: StorySplat Commercial License ({userProfile?.proTier})
          </Title>
          <Title level={5}>Commercial Usage Rights</Title>
          <Paragraph>
            Under this commercial license, you are granted the following rights:
          </Paragraph>
          <ul>
            <li>
              <Text strong>Commercial Use:</Text> You may use StorySplat in commercial
              projects and derive monetary benefit from its use.
            </li>
            <li>
              <Text strong>Export Rights:</Text> Any content exported while your
              subscription is active can be used commercially indefinitely, even if
              your subscription later expires.
            </li>
            <li>
              <Text strong>Analytics and Hosting:</Text> To maintain access to analytics
              and hosting features, an active subscription must be maintained.
            </li>
          </ul>
          <Title level={5}>Conditions</Title>
          <ol>
            <li>
              <Text strong>Attribution:</Text> You must include appropriate attribution
              to StorySplat in your projects.
            </li>
            <li>
              <Text strong>Subscription Status:</Text> While exported content remains
              usable indefinitely, analytics and hosting features require an active
              subscription.
            </li>
            <li>
              <Text strong>No Redistribution:</Text> You may not redistribute StorySplat
              itself or create derivative products based on our software.
            </li>
          </ol>
          <Title level={5}>Support and Updates</Title>
          <Paragraph>
            As a {userProfile?.proTier} subscriber, you have access to priority support
            and regular updates. Please maintain an active subscription to continue
            receiving these benefits.
          </Paragraph>
        </>
      );
    }

    return (
      <>
        <Paragraph>
          <Text strong>Last Updated:</Text> November 5, 2024
        </Paragraph>
        <Paragraph>
          Thank you for choosing StorySplat! We believe in empowering
          storytellers, journalists, and educators to create visually stunning
          experiences. To ensure the appropriate use of our software, we have
          outlined our licensing terms below.
        </Paragraph>
        <Title level={4}>
          License Type: CC Attribution-NonCommercial (CC BY-NC)
        </Title>
        <Title level={5}>Summary of the License</Title>
        <Paragraph>
          StorySplat is licensed under the{" "}
          <Text strong>
            Creative Commons Attribution-NonCommercial (CC BY-NC)
          </Text>{" "}
          license. This means you are free to:
        </Paragraph>
        <ul>
          <li>
            <Text strong>Use and Modify:</Text> You can use and modify
            StorySplat for personal, educational, or non-commercial purposes.
          </li>
          <li>
            <Text strong>Distribute:</Text> You may distribute your derivative
            works, provided they comply with this license and credit
            StorySplat.
          </li>
        </ul>
        <Title level={5}>Conditions</Title>
        <ol>
          <li>
            <Text strong>Attribution:</Text> You must give appropriate credit
            to StorySplat, provide a link to the license, and indicate if
            changes were made. You may do so in any reasonable manner, but not
            in any way that suggests StorySplat endorses you or your use of
            the software.
          </li>
          <li>
            <Text strong>Non-Commercial Use Only:</Text> You may not use
            StorySplat for commercial purposes. Commercial use includes, but
            is not limited to, activities where you or others derive monetary
            or commercial benefit from the use of StorySplat.
          </li>
          <li>
            <Text strong>No Additional Restrictions:</Text> You may not apply
            legal terms or technological measures that legally restrict others
            from doing anything the license permits.
          </li>
        </ol>
        <Title level={5}>Commercial Use</Title>
        <Paragraph>
          If you wish to use StorySplat for commercial purposes, including
          using it in a for-profit project or service, you must obtain a
          commercial license. Please{" "}
          <a href="mailto:info@storysplat.com">contact us</a> for licensing
          options.
        </Paragraph>
        <Title level={5}>Attribution</Title>
        <Paragraph>
          When using or modifying StorySplat, please include the following
          attribution:
        </Paragraph>
        <Paragraph italic>
          "This project uses StorySplat, created by Sonny Cirasuolo. Learn
          more at <a href="https://storysplat.com">storysplat.com</a>"
        </Paragraph>
        <Title level={5}>Exclusions and Limitations</Title>
        <ul>
          <li>
            This license does not grant permission to use the trademarks,
            service marks, or logos of StorySplat.
          </li>
          <li>
            Any use of StorySplat that conflicts with these terms will be
            considered a breach of the license agreement and may result in
            legal action.
          </li>
        </ul>
        <Title level={5}>Contact Us</Title>
        <Paragraph>
          For questions about this license or to inquire about a commercial
          license, please reach out to us at{" "}
          <a href="mailto:info@storysplat.com">info@storysplat.com</a>
        </Paragraph>
      </>
    );
  };

  return (
    <>
      {standalone && (
        <Button
          type="primary"
          onClick={showModal}
          style={{ position: "relative", height: '40px', zIndex: 10 }}
        >
          License
        </Button>
      )}
      <Modal
        title="StorySplat License Agreement"
        open={isModalVisible || (!standalone && forceVisible)}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={true}
        maskClosable={false}
        keyboard={false}
        footer={renderFooter()}
        width={800}
        style={{ zIndex: 1002 }}
      >
        <Typography style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 300px)' }}>
          {renderLicenseContent()}

          {showAcceptButton && (
            <div style={{ marginTop: 24 }}>
              <Checkbox
                checked={hasReadAndAccepted}
                onChange={(e) => setHasReadAndAccepted(e.target.checked)}
              >
                I have read and accept the terms of the license agreement
              </Checkbox>
            </div>
          )}
        </Typography>
      </Modal>
    </>
  );
};

export default LicenseButton;
