export const generatePreloaderService = (): string => {
  return `
    class ImagePreloader {
      static imageCache = new Map();

      static preloadImages(urls) {
        const uniqueUrls = [...new Set(urls)];
        const loadPromises = uniqueUrls.map(url => {
          if (this.imageCache.has(url)) {
            return Promise.resolve();
          }

          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
              this.imageCache.set(url, img);
              resolve();
            };
            img.onerror = reject;
            img.src = url;
          });
        });

        return Promise.all(loadPromises);
      }

      static getPreloadedImage(url) {
        return this.imageCache.get(url);
      }

      static isImagePreloaded(url) {
        return this.imageCache.has(url);
      }
    }
  `;
};
