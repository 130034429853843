import React from 'react';
import ImagePreloader from '../services/ImagePreloader';

interface PreloadImageProps {
  src: string;
  alt: string;
  style?: React.CSSProperties;
  onLoad?: () => void;
}

const PreloadImage: React.FC<PreloadImageProps> = ({ src, alt, style, onLoad }) => {
  React.useEffect(() => {
    if (ImagePreloader.isImagePreloaded(src)) {
      onLoad?.();
    }
  }, [src, onLoad]);

  if (!ImagePreloader.isImagePreloaded(src)) {
    return (
      <div 
        style={{
          ...style,
          backgroundColor: 'rgba(200, 200, 200, 0.2)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        Loading...
      </div>
    );
  }

  // Use the cached image instead of creating a new img element
  const cachedImage = ImagePreloader.getPreloadedImage(src);
  return (
    <img 
      src={cachedImage?.src || src} 
      alt={alt} 
      style={style}
      // Clone the cached image properties
      width={cachedImage?.width}
      height={cachedImage?.height}
    />
  );
};

export default PreloadImage;
