/**
 * Pricing.tsx
 * Displays pricing plans and options for StorySplat
 * 
 * Updated: March 19, 2024
 * Changes:
 * - Added yearly pricing options
 * - Updated pricing to reflect new rates
 * - Added monthly/yearly toggle
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { STRIPE_CONFIG, STORAGE_LIMITS } from '../utils/StripeConfig';
import config from '../config';


const BillingToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 40px;
`;

const ToggleButton = styled.button<{ active: boolean }>`
  padding: 8px 16px;
  border: 2px solid #d4af37;
  background: ${props => props.active ? '#d4af37' : 'transparent'};
  color: ${props => props.active ? 'white' : '#d4af37'};
  border-radius: 20px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.active ? '#b4941f' : 'rgba(212, 175, 55, 0.1)'};
  }
`;

const SaveBadge = styled.span`
  position: absolute;
  top: -12px;
  right: -12px;
  background: #d4af37;
  color: white;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.9em;
  font-weight: 500;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f4f4f4;
`;

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 20px;
  margin-right: 40px;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #666;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 4px;
  transition: all 0.2s ease;

  &:hover {
    color: #333;
    background: rgba(0, 0, 0, 0.05);
  }
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
  width: 100%;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 60px;
`;

const Title = styled.h1`
  color: #333;
  font-size: 2.5em;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  color: #666;
  font-size: 1.2em;
  max-width: 600px;
  margin: 0 auto 30px auto;
`;

const EarlyAccessBanner = styled.div`
  background: linear-gradient(135deg, #d4af37 0%, #b4941f 100%);
  color: white;
  padding: 15px 20px;
  border-radius: 8px;
  text-align: center;
  margin: 20px auto 40px;
  max-width: 800px;
  box-shadow: 0 4px 12px rgba(212, 175, 55, 0.2);
  animation: pulse 2s infinite;
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.02); }
    100% { transform: scale(1); }
  }

  h3 {
    margin: 0;
    font-size: 1.4em;
    font-weight: 600;
  }
`;

const UpgradeButton = styled(Link)`
  display: inline-block;
  padding: 15px 40px;
  margin-bottom: 12px;
  background-color: #d4af37;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-size: 1.2em;
  font-weight: 500;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #b4941f;
  }
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-bottom: 40px;
`;

const PricingCard = styled.div<{ featured?: boolean }>`
  background: white;
  border-radius: 12px;
  padding: 30px;
  box-shadow: ${props => props.featured ? 
    '0 8px 24px rgba(212, 175, 55, 0.15)' : 
    '0 4px 6px rgba(0, 0, 0, 0.1)'};
  display: flex;
  flex-direction: column;
  border: ${props => props.featured ? '2px solid #d4af37' : 'none'};
  position: relative;
`;

const PlanName = styled.h2`
  color: #333;
  margin-bottom: 15px;
  font-size: 1.8em;
`;

const Price = styled.div`
  font-size: 2.5em;
  color: #2c2c2c;
  margin-bottom: 20px;
  font-weight: bold;
  
  span {
    font-size: 0.4em;
    color: #666;
  }
`;

const StorageInfo = styled.div`
  color: #666;
  font-size: 1.1em;
  margin-bottom: 20px;
  padding: 10px;
  background: #f8f8f8;
  border-radius: 6px;
  text-align: center;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
`;

const Feature = styled.li<{ highlight?: boolean }>`
  padding: 10px 0;
  color: #555;
  display: flex;
  align-items: center;
  font-weight: ${props => props.highlight ? 'bold' : 'normal'};
  color: ${props => props.highlight ? '#b4941f' : '#555'};
  
  &:before {
    content: "✓";
    color: #d4af37;
    margin-right: 10px;
    font-weight: bold;
  }
`;

const LoginMessage = styled.div`
  text-align: center;
  margin-top: 40px;
  padding: 20px;
  background: #faf6e9;
  border-radius: 8px;
  color: #b4941f;
`;

const ContactSection = styled.div`
  text-align: center;
  margin-top: 40px;
  padding: 30px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ContactText = styled.p`
  color: #666;
  font-size: 1.2em;
  margin-bottom: 15px;
`;

const ContactLink = styled.a`
  color: #d4af37;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.2em;
  
  &:hover {
    text-decoration: underline;
    color: #b4941f;
  }
`;

const ScrollContainer = styled.div`
  overflow-y: scroll !important;
  height: calc(100vh - 64px); /* 64px is the height of the Toolbar */
`;

const formatStorage = (gb: number) => {
  return gb === -1 ? 'Unlimited' : `${gb}GB`;
};
const Pricing: React.FC = () => {
  const { currentUser } = useAuth();
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'yearly'>('monthly');

  const isCommercialLicense = (feature: string) => 
    feature.toLowerCase().includes('commercial') || 
    feature.toLowerCase().includes('license');

  const calculateYearlySavings = (monthlyPrice: number, yearlyPrice: number) => {
    const yearlyTotal = monthlyPrice * 12;
    const savings = ((yearlyTotal - yearlyPrice) / yearlyTotal * 100).toFixed(0);
    return `Save ${savings}%`;
  };

  const getPrice = (plan: 'PRO' | 'BUSINESS') => {
    const config = STRIPE_CONFIG.PLANS[plan];
    return billingCycle === 'monthly' ? config.price : config.yearlyPrice;
  };

  return (
    <PageContainer>
      <Toolbar>
        <Logo to="/">
          StorySplat
        </Logo>
        <NavLinks>
          <NavLink to="/editor">Editor</NavLink>
          <NavLink to="/discover">Discover</NavLink>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/help">Help</NavLink>
          {config.stripeIntegrationEnabled && (
            <NavLink to="/pricing">Pricing</NavLink>
          )}
        </NavLinks>
      </Toolbar>
      <ScrollContainer>
        <ContentContainer>
          <Header>
            <Title>Pricing</Title>

            <Subtitle>
              Start with our free tier and upgrade as your needs grow. All plans include basic features.
            </Subtitle>
            <BillingToggle>
              <ToggleButton 
                active={billingCycle === 'monthly'} 
                onClick={() => setBillingCycle('monthly')}
              >
                Monthly
              </ToggleButton>
              <ToggleButton 
                active={billingCycle === 'yearly'} 
                onClick={() => setBillingCycle('yearly')}
              >
                Yearly
              </ToggleButton>
            </BillingToggle>
       
          </Header>

          <EarlyAccessBanner>
            <h3>🚀 Early Access Pricing!</h3>
          </EarlyAccessBanner>

          <PricingGrid>
            <PricingCard>
              <PlanName>Free</PlanName>
              <Price>$0 <span>/month</span></Price>
              <StorageInfo>{formatStorage(STORAGE_LIMITS.FREE)} Storage</StorageInfo>
              <FeatureList>
                <Feature>Full StorySplat Editor </Feature>
                <Feature>Up to 5 Saved Scenes</Feature>
                <Feature>Local Export to HTML</Feature>
                <Feature>Generate Embed Code</Feature>
                <Feature>Non Commercial Use</Feature>
              </FeatureList>
            </PricingCard>

            <PricingCard featured>
              {billingCycle === 'yearly' && (
                <SaveBadge>
                  {calculateYearlySavings(STRIPE_CONFIG.PLANS.PRO.price, STRIPE_CONFIG.PLANS.PRO.yearlyPrice)}
                </SaveBadge>
              )}
              <PlanName>{STRIPE_CONFIG.PLANS.PRO.name}</PlanName>
              <Price>${getPrice('PRO')} <span>/{billingCycle === 'monthly' ? 'month' : 'year'}</span></Price>
              <StorageInfo>{formatStorage(STORAGE_LIMITS.PRO)} Storage</StorageInfo>
              <FeatureList>
                {STRIPE_CONFIG.PLANS.PRO.features.map((feature, index) => (
                  <Feature key={index}>{feature}</Feature>
                ))}
              </FeatureList>
            </PricingCard>

            <PricingCard>
              {billingCycle === 'yearly' && (
                <SaveBadge>
                  {calculateYearlySavings(STRIPE_CONFIG.PLANS.BUSINESS.price, STRIPE_CONFIG.PLANS.BUSINESS.yearlyPrice)}
                </SaveBadge>
              )}
              <PlanName>{STRIPE_CONFIG.PLANS.BUSINESS.name}</PlanName>
              <Price>${getPrice('BUSINESS')} <span>/{billingCycle === 'monthly' ? 'month' : 'year'}</span></Price>
              <StorageInfo>{formatStorage(STORAGE_LIMITS.BUSINESS)} Storage</StorageInfo>
              <FeatureList>
                {STRIPE_CONFIG.PLANS.BUSINESS.features.map((feature, index) => (
                  <Feature 
                    key={index}
                    highlight={isCommercialLicense(feature)}
                  >
                    {feature}
                  </Feature>
                ))}
              </FeatureList>
            </PricingCard>
          </PricingGrid>
          <div style={{ textAlign: 'center', marginBottom: 40 }}>
          <UpgradeButton to="/upgrade-to-pro">
              Click Here To Upgrade
            </UpgradeButton>
          </div>
          <ContactSection>
            <ContactText>Need a custom enterprise solution?</ContactText>
            <ContactLink href="mailto:info@storysplat.com">
              Contact us at info@storysplat.com
            </ContactLink>
          </ContactSection>

          {!currentUser && (
            <LoginMessage>
              Ready to upgrade? Sign in through the editor to access premium features.
            </LoginMessage>
          )}
        </ContentContainer>
      </ScrollContainer>
    </PageContainer>
  );
};

export default Pricing;
