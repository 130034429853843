// Last updated: 2024-02-15
// Purpose: Public splats discovery page
// Changes: 
// - Added displayNameSlug and htmlUrl to PublicSplat interface
// - Updated layout to be scrollable
// - Added consistent toolbar with all navigation links
// - Updated likes handling to support Like objects array
// - 2024-02-21: Added defensive rendering for views
// - 2024-02-24: Added Pricing link to toolbar
// - 2024-02-26: Added pagination system with URL parameters

import React, { useState, useEffect, useCallback } from 'react';
import { collection, query, getDocs, DocumentData, QueryDocumentSnapshot, where } from 'firebase/firestore';
import { db } from '../utils/FirebaseConfig';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Like, Comment } from '../types/UserTypes';
import config from '../config';
import { useMobileDetection } from '../hooks/useMobileDetection';

// Defensive rendering functions
export const renderLikes = (likes?: Like[]) => {
  if (!likes || !Array.isArray(likes)) return 0;
  return likes.length;
};

export const renderViews = (views?: number) => {
  if (views === undefined || views === null) return 0;
  return views;
};

export const renderComments = (comments?: Comment[]) => {
  if (!comments || !Array.isArray(comments)) return [];
  return comments
    .filter(comment => 
      comment && 
      typeof comment.id === 'string' && 
      typeof comment.userId === 'string' && 
      comment.createdAt instanceof Date
    )
    .map(comment => ({
      ...comment,
      createdAtString: comment.createdAt.toLocaleDateString()
    }));
};

// Interfaces
interface PublicSplat {
  id: string;
  userId: string;
  userName: string;
  thumbnailUrl: string;
  title: string;
  createdAt: Date;
  views?: number;
  likes: Like[];
  comments?: Comment[];
  description?: string;
  displayNameSlug?: string;
  htmlUrl?: string;
}

interface SortOption {
  label: string;
  value: 'createdAt' | 'views' | 'likes';
  direction: 'desc' | 'asc';
}

// Styled Components
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
  background-color: #f4f4f4;
  overflow: hidden;
`;

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 20px;
  margin-right: 40px;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

const NavLink = styled(Link)<{ $isMobile?: boolean }>`
  text-decoration: none;
  color: #666;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 4px;
  transition: all 0.2s ease;
  font-size: ${props => props.$isMobile ? '12px' : '14px'};

  &:hover {
    color: #333;
    background: rgba(0, 0, 0, 0.05);
  }
`;

const ScrollContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  margin-top: 60px;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const Title = styled.h1`
  color: #333;
  margin: 0;
  
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Controls = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Select = styled.select`
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
  min-width: 150px;

  &:focus {
    outline: none;
    border-color: #4CAF50;
  }
`;

const SearchInput = styled.input`
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  min-width: 200px;

  &:focus {
    outline: none;
    border-color: #4CAF50;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const Thumbnail = styled.div<{ url: string }>`
  width: 100%;
  height: 200px;
  background-image: url(${props => props.url});
  background-size: cover;
  background-position: center;
  position: relative;
`;

const CardContent = styled.div`
  padding: 15px;
`;

const CardTitle = styled.h3`
  margin: 0 0 8px 0;
  color: #333;
  font-size: 1.1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CardMeta = styled.div`
  display: flex;
  justify-content: space-between;
  color: #666;
  font-size: 0.9em;
  margin-top: 8px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const PaginationButton = styled.button<{ $active?: boolean }>`
  padding: 8px 12px;
  border: 1px solid ${props => props.$active ? '#2c2c2c' : '#ddd'};
  background-color: ${props => props.$active ? '#2c2c2c' : 'white'};
  color: ${props => props.$active ? 'white' : '#666'};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 40px;

  &:hover:not(:disabled) {
    background-color: ${props => props.$active ? '#404040' : '#f5f5f5'};
  }

  &:disabled {
    background-color: #f5f5f5;
    color: #999;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  text-align: center;
  color: #ff4444;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const RetryButton = styled.button`
  margin-top: 15px;
  padding: 8px 16px;
  background-color: #ff4444;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #ff6666;
  }
`;

const LoadingPlaceholder = styled.div`
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 8px;
  height: 300px;
  
  @keyframes loading {
    0% { background-position: 200% 0; }
    100% { background-position: -200% 0; }
  }
`;

const NoResults = styled.div`
  text-align: center;
  padding: 40px;
  background-color: white;
  border-radius: 8px;
  color: #666;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

// Sort options
const sortOptions: SortOption[] = [
  { label: 'Newest First', value: 'createdAt', direction: 'desc' },
  { label: 'Oldest First', value: 'createdAt', direction: 'asc' },
  { label: 'Most Viewed', value: 'views', direction: 'desc' },
  { label: 'Most Liked', value: 'likes', direction: 'desc' },
];

const ITEMS_PER_PAGE = 12;
const MAX_PAGES_SHOWN = 5;

const DiscoveryPage: React.FC = () => {
  const [publicSplats, setPublicSplats] = useState<PublicSplat[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [totalItems, setTotalItems] = useState(0);
  const [sortBy, setSortBy] = useState<SortOption>(sortOptions[0]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMobileDetection();

  const queryParams = new URLSearchParams(location.search);
  const currentPage = parseInt(queryParams.get('page') || '1', 10);

  // Fetch splats function
  const fetchSplats = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);

      const usersRef = collection(db, 'users');
      
      // First, get total count
      const countQuery = query(
        usersRef,
        where('publicProfile.isPublic', '==', true)
      );
      const countSnapshot = await getDocs(countQuery);
      let totalCount = 0;
      countSnapshot.docs.forEach(doc => {
        const userData = doc.data();
        if (userData.splats && Array.isArray(userData.splats)) {
          totalCount += userData.splats.filter((splat: any) => splat.isPublic).length;
        }
      });
      setTotalItems(totalCount);

      // Then get paginated data
      const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
      const q = query(
        usersRef,
        where('publicProfile.isPublic', '==', true)
      );

      const querySnapshot = await getDocs(q);
      let allPublicSplats: PublicSplat[] = [];

      querySnapshot.docs.forEach(doc => {
        const userData = doc.data();
        if (userData.splats && Array.isArray(userData.splats)) {
          const userPublicSplats = userData.splats
            .filter((splat: any) => splat.isPublic)
            .map((splat: any) => ({
              id: splat.id,
              userId: doc.id,
              userName: userData.publicProfile?.displayName || userData.displayName || 'Anonymous',
              displayNameSlug: userData.publicProfile?.displayNameSlug,
              thumbnailUrl: splat.thumbnailUrl || '/default-thumbnail.png',
              title: splat.name || 'Untitled Splat',
              createdAt: splat.createdAt?.toDate() || new Date(),
              views: splat.views || 0,
              likes: splat.likes || [],
              comments: splat.comments || [],
              description: splat.description || '',
              htmlUrl: splat.htmlUrl || ''
            }));

          allPublicSplats = [...allPublicSplats, ...userPublicSplats];
        }
      });

      // Sort splats
      allPublicSplats.sort((a, b) => {
        switch (sortBy.value) {
          case 'createdAt':
            return sortBy.direction === 'desc' 
              ? b.createdAt.getTime() - a.createdAt.getTime()
              : a.createdAt.getTime() - b.createdAt.getTime();
          case 'views':
            return sortBy.direction === 'desc' 
              ? (b.views || 0) - (a.views || 0)
              : (a.views || 0) - (b.views || 0);
          case 'likes':
            return sortBy.direction === 'desc'
              ? renderLikes(b.likes) - renderLikes(a.likes)
              : renderLikes(a.likes) - renderLikes(b.likes);
          default:
            return 0;
        }
      });

      // Apply pagination
      setPublicSplats(allPublicSplats.slice(startIndex, startIndex + ITEMS_PER_PAGE));

    } catch (err) {
      console.error('Error in fetchSplats:', err);
      setError('Failed to load public splats');
    } finally {
      setIsLoading(false);
    }
  }, [sortBy, currentPage]);

  useEffect(() => {
    fetchSplats();
    // Update URL with current page
    const newParams = new URLSearchParams(location.search);
    newParams.set('page', currentPage.toString());
    navigate(`${location.pathname}?${newParams.toString()}`, { replace: true });
  }, [fetchSplats, currentPage, location.pathname, location.search, navigate]);

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSort = sortOptions.find(option => 
      `${option.value}-${option.direction}` === e.target.value
    );
    if (newSort) {
      setSortBy(newSort);
      // Reset to first page when sorting changes
      const newParams = new URLSearchParams(location.search);
      newParams.set('page', '1');
      navigate(`${location.pathname}?${newParams.toString()}`);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (page: number) => {
    const newParams = new URLSearchParams(location.search);
    newParams.set('page', page.toString());
    navigate(`${location.pathname}?${newParams.toString()}`);
  };

  const filteredSplats = publicSplats.filter(splat =>
    splat.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    splat.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (splat.description?.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const handleViewSplat = (userId: string, splatId: string, displayNameSlug?: string) => {
    if (!displayNameSlug) {
      console.error('No displayNameSlug available for user:', userId);
      return;
    }
    navigate(`/${displayNameSlug}?sceneId=${splatId}`);
  };

  const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

  const getPaginationRange = () => {
    const range: number[] = [];
    const leftOffset = Math.floor(MAX_PAGES_SHOWN / 2);
    const rightOffset = MAX_PAGES_SHOWN - leftOffset - 1;

    let start = Math.max(1, currentPage - leftOffset);
    let end = Math.min(totalPages, currentPage + rightOffset);

    if (end - start + 1 < MAX_PAGES_SHOWN) {
      if (start === 1) {
        end = Math.min(MAX_PAGES_SHOWN, totalPages);
      } else {
        start = Math.max(1, totalPages - MAX_PAGES_SHOWN + 1);
      }
    }

    for (let i = start; i <= end; i++) {
      range.push(i);
    }

    return range;
  };

  const renderContent = () => (
    <ContentContainer>
      <Header>
        <Title>Discover Story Splats</Title>
        <Controls>
          <SearchInput
            type="text"
            placeholder="Search splats..."
            value={searchTerm}
            onChange={handleSearch}
          />
          <Select
            value={`${sortBy.value}-${sortBy.direction}`}
            onChange={handleSortChange}
          >
            {sortOptions.map(option => (
              <option 
                key={`${option.value}-${option.direction}`}
                value={`${option.value}-${option.direction}`}
              >
                {option.label}
              </option>
            ))}
          </Select>
        </Controls>
      </Header>

      {error && (
        <ErrorMessage>
          <p>{error}</p>
          <RetryButton onClick={() => fetchSplats()}>
            Try Again
          </RetryButton>
        </ErrorMessage>
      )}

      {!error && publicSplats.length === 0 ? (
        <NoResults>
          <h3>No public splats found</h3>
          <p>This could be because:</p>
          <ul>
            <li>No public splats have been created yet</li>
            <li>The splats might not be properly marked as public</li>
            <li>There might be an issue with permissions</li>
          </ul>
        </NoResults>
      ) : (
        <>
          <Grid>
            {filteredSplats.map(splat => (
              <Card 
                key={splat.id} 
                onClick={() => handleViewSplat(splat.userId, splat.id, splat.displayNameSlug)}
              >
                <Thumbnail url={splat.thumbnailUrl} />
                <CardContent>
                  <CardTitle>{splat.title}</CardTitle>
                  <CardMeta>
                    <span>By {splat.userName}</span>
                    <span>{splat.createdAt.toLocaleDateString()}</span>
                  </CardMeta>
                  <CardMeta>
                    <span>{renderViews(splat.views)} views</span>
                    <span>{renderLikes(splat.likes)} likes</span>
                  </CardMeta>
                </CardContent>
              </Card>
            ))}
          </Grid>

          {totalPages > 1 && (
            <PaginationContainer>
              <PaginationButton
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                «
              </PaginationButton>
              <PaginationButton
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                ‹
              </PaginationButton>
              
              {getPaginationRange().map(page => (
                <PaginationButton
                  key={page}
                  onClick={() => handlePageChange(page)}
                  $active={page === currentPage}
                >
                  {page}
                </PaginationButton>
              ))}
              
              <PaginationButton
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                ›
              </PaginationButton>
              <PaginationButton
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                »
              </PaginationButton>
            </PaginationContainer>
          )}
        </>
      )}
    </ContentContainer>
  );

  return (
    <PageContainer>
      <Toolbar>
        <Logo to="/">StorySplat</Logo>
        <NavLinks>
          {!isMobile ? (
            <>
              <NavLink to="/editor" $isMobile={isMobile}>Editor</NavLink>
              <NavLink to="/discover" $isMobile={isMobile}>Discover</NavLink>
              <NavLink to="/about" $isMobile={isMobile}>About</NavLink>
              <NavLink to="/help" $isMobile={isMobile}>Help</NavLink>
              {config.stripeIntegrationEnabled && (
                <NavLink to="/pricing" $isMobile={isMobile}>Pricing</NavLink>
              )}
            </>
          ) : (
            <>
              <NavLink to="/discover" $isMobile={isMobile}>Discover</NavLink>
              <NavLink to="/about" $isMobile={isMobile}>About</NavLink>
              <NavLink to="/help" $isMobile={isMobile}>Help</NavLink>
              {config.stripeIntegrationEnabled && (
                <NavLink to="/pricing" $isMobile={isMobile}>Pricing</NavLink>
              )}
            </>
          )}
        </NavLinks>
      </Toolbar>

      <ScrollContainer>
        {isLoading && publicSplats.length === 0 ? (
          <ContentContainer>
            <Header>
              <Title>Public Splats Discover</Title>
              <Controls>
                <SearchInput disabled placeholder="Search splats..." />
                <Select disabled>
                  <option>Loading...</option>
                </Select>
              </Controls>
            </Header>
            <Grid>
              {[...Array(ITEMS_PER_PAGE)].map((_, i) => (
                <LoadingPlaceholder key={i} />
              ))}
            </Grid>
          </ContentContainer>
        ) : (
          renderContent()
        )}
      </ScrollContainer>
    </PageContainer>
  );
};

export default DiscoveryPage;
