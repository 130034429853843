/*
* File: src/routes/Routes.tsx
* Description: This file contains the routing configuration for the application.
* Changes: 
* - 2024-01-09 - Added routes for manage-profile and public profile pages
* - 2024-01-09 - Modified all routes to render alongside MainScene
* - 2024-01-12 - Updated to properly handle public profile routes without MainScene
* - 2024-01-13 - Added Discovery page route
* - 2024-01-25 - Updated profile route to use displayNameSlug instead of userId
* - 2024-01-26 - Added SplatView route for individual splat viewing
* - 2024-02-08 - Added About and Help page routes
* - 2024-02-24 - Added public Pricing page
* - 2024-02-25 - Added Login page route
*/

import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useMobileDetection } from '../hooks/useMobileDetection';
import { useAuth } from '../contexts/AuthContext';
import ProUpgrade from '../pages/ProUpgrade';
import Pricing from '../pages/Pricing';
import SubscriptionSuccess from '../pages/SubscriptionSuccess';
import SubscriptionCancel from '../pages/SubscriptionCancel';
import Analytics from '../pages/Analytics';
import MainScene from '../components/Scene/MainScene';
import AccountSettings from '../pages/AccountSettings';
import ExamplesPage from '../pages/Examples';
import ManageProfile from '../pages/ManageProfile';
import PublicProfile from '../pages/PublicProfile';
import DiscoveryPage from '../pages/DiscoveryPage';
import SplatView from '../pages/SplatView';
import About from '../pages/About';
import Help from '../pages/Help';
import Login from '../pages/Login';
import config from '../config';

// Protected Route wrapper component
const ProtectedRoute: React.FC<{
  children: React.ReactNode;
  requiresPro?: boolean;
}> = ({ children, requiresPro }) => {
  const { currentUser, userProfile } = useAuth();

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  if (requiresPro && !userProfile?.isPro) {
    return <Navigate to="/upgrade-to-pro" replace />;
  }

  return <>{children}</>;
};

const AppRoutes: React.FC = () => {
  const { currentUser, hasAcceptedLicense } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMobileDetection();

  // Redirect mobile users to discover page
  useEffect(() => {
    if (isMobile && (location.pathname === '/' || location.pathname === '/editor')) {
      navigate('/discover', { replace: true });
    }
  }, [isMobile, location.pathname, navigate]);

  // Helper function to check if we're on a specific route
  const isRoute = (path: string) => {
    if (path.includes(':')) {
      // For dynamic routes like /analytics/:sceneId
      const basePath = path.split(':')[0];
      return location.pathname.startsWith(basePath);
    }
    return location.pathname === path;
  };

  // Check if we're on a public route
  const isPublicRoute = 
    location.pathname.startsWith('/profile/') || 
    location.pathname === '/discover' ||
    location.pathname === '/about' ||
    location.pathname === '/help' ||
    location.pathname === '/pricing' ||
    location.pathname === '/login' ||
    location.pathname.startsWith('/analytics/') ||
    // Check if the path matches /:displayNameSlug format (but not other known routes)
    (location.pathname.slice(1).includes('/') === false && 
     location.pathname !== '/' &&
     !['/editor', '/examples', '/upgrade-to-pro', '/subscription-success', 
       '/subscription-cancel', '/manage-subscription', '/manage-profile'].includes(location.pathname));

  return (
    <>
      {/* Main Scene is rendered except for public profile and discovery pages */}
      {!isPublicRoute && (
        <MainScene 
          currentUser={currentUser}
          hasAcceptedLicense={hasAcceptedLicense}
        />
      )}

      {/* Render pages as overlays based on current route */}
      {isRoute('/examples') && <ExamplesPage />}
      
      {isRoute('/discover') && <DiscoveryPage />}
      
      {isRoute('/about') && <About />}
      
      {isRoute('/help') && <Help />}

      {isRoute('/pricing') && <Pricing />}
      
      {isRoute('/upgrade-to-pro') && (
        <ProtectedRoute>
          <ProUpgrade />
        </ProtectedRoute>
      )}
      
      {isRoute('/subscription-success') && (
        <ProtectedRoute>
          <SubscriptionSuccess />
        </ProtectedRoute>
      )}
      
      {isRoute('/subscription-cancel') && (
        <ProtectedRoute>
          <SubscriptionCancel />
        </ProtectedRoute>
      )}
      
      {isRoute('/manage-subscription') && (
        <ProtectedRoute requiresPro>
          <AccountSettings />
        </ProtectedRoute>
      )}
      
      {isRoute('/manage-profile') && (
        <ProtectedRoute>
          <ManageProfile />
        </ProtectedRoute>
      )}

      {isRoute('/analytics/') && (
        <ProtectedRoute requiresPro>
          <Analytics />
        </ProtectedRoute>
      )}

      {/* Routes configuration for navigation */}
      <Routes>
        <Route path="/" element={<Navigate to="/editor" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/editor" element={null} />
        <Route path="/examples" element={null} />
        <Route path="/discover" element={null} />
        <Route path="/about" element={null} />
        <Route path="/help" element={null} />
        <Route path="/pricing" element={null} />
        <Route path="/upgrade-to-pro" element={null} />
        <Route path="/subscription-success" element={null} />
        <Route path="/subscription-cancel" element={null} />
        <Route path="/manage-subscription" element={null} />
        <Route path="/manage-profile" element={null} />
        <Route path="/analytics/:sceneId" element={<Analytics />} />
        <Route 
          path="/profile/:displayNameSlug" 
          element={
            config.publicProfilesEnabled ? <PublicProfile /> : <Navigate to="/" replace />
          } 
        />
        {/* New route for individual splat viewing */}
        <Route 
          path="/:displayNameSlug" 
          element={
            config.publicProfilesEnabled ? <SplatView /> : <Navigate to="/" replace />
          } 
        />
      </Routes>
    </>
  );
};

export default AppRoutes;
