import {
  useState,
  useRef,
  useCallback,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import * as BABYLON from "@babylonjs/core";
import {
  Waypoint,
  AudioInteractionData,
  InfoInteractionData,
  DEFAULT_SETTINGS,
  UIOptions,
  SerializedMeshData,
  SceneLight,
  SceneType,
  CameraMode,
  SplatSwapPoint,
} from "../types/SceneTypes";
import { Hotspot } from "../types/SceneTypes";
import { UIType } from "../tools/html-generation/types";

export interface SceneState {
  playerHeight: number;
  setPlayerHeight: Dispatch<SetStateAction<number>>;
  useNodeMaterial: boolean;
  setUseNodeMaterial: Dispatch<SetStateAction<boolean>>;
  sceneType: SceneType | null;
  setSceneType: Dispatch<SetStateAction<SceneType | null>>;
  lights: SceneLight[];
  setLights: Dispatch<SetStateAction<SceneLight[]>>;
  isLightEditMode: boolean;
  setIsLightEditMode: Dispatch<SetStateAction<boolean>>;
  selectedLightIndex: number | null;
  setSelectedLightIndex: Dispatch<SetStateAction<number | null>>;
  autoFrame: boolean;
  setAutoFrame: Dispatch<SetStateAction<boolean>>;
  sceneRef: React.MutableRefObject<BABYLON.Scene | null>;
  cameraRef: React.MutableRefObject<BABYLON.UniversalCamera | null>;
  editCameraRef: React.MutableRefObject<BABYLON.ArcRotateCamera | null>;
  pathRef: React.MutableRefObject<BABYLON.Vector3[]>;
  rotationsRef: React.MutableRefObject<BABYLON.Quaternion[]>;
  loadedMeshesRef: React.MutableRefObject<BABYLON.AbstractMesh[]>;
  collisionMeshesRef: React.MutableRefObject<BABYLON.Mesh[]>;
  localModelFileRef: React.MutableRefObject<File | null>;
  userControlRef: React.MutableRefObject<boolean>;
  animatingToPathRef: React.MutableRefObject<boolean>;
  scrollPositionRef: React.MutableRefObject<number>;
  scrollTargetRef: React.MutableRefObject<number>;
  cameraModeRef: React.MutableRefObject<CameraMode>;
  cameraDampeningRef: React.MutableRefObject<number>;
  transitionSpeedRef: React.MutableRefObject<number>;
  autoPlaySpeedRef: React.MutableRefObject<number>;
  autoPlayEnabledRef: React.MutableRefObject<boolean>;
  loopModeRef: React.MutableRefObject<boolean>;
  cameraMovementSpeedRef: React.MutableRefObject<number>;
  waypoints: Waypoint[];
  setWaypoints: Dispatch<SetStateAction<Waypoint[]>>;
  waypointsRef: React.MutableRefObject<Waypoint[]>;
  activeWaypointsRef: React.MutableRefObject<Set<number>>;
  activeAudioRefs: React.MutableRefObject<{
    [key: string]: { sound: BABYLON.Sound; data: AudioInteractionData };
  }>;
  targetRotationRef: React.MutableRefObject<BABYLON.Quaternion>;
  isEditMode: boolean;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  isCollisionEditMode: boolean;
  setIsCollisionEditMode: Dispatch<SetStateAction<boolean>>;
  cameraMode: CameraMode;
  setCameraMode: Dispatch<SetStateAction<CameraMode>>;
  scrollPercentage: number;
  setScrollPercentage: Dispatch<SetStateAction<number>>;
  showScrollControls: boolean;
  setShowScrollControls: Dispatch<SetStateAction<boolean>>;
  currentWaypointTitle: string;
  setCurrentWaypointTitle: Dispatch<SetStateAction<string>>;
  infoPopupText: string | null;
  setInfoPopupText: Dispatch<SetStateAction<string | null>>;
  loadedModelUrl: string | null;
  setLoadedModelUrl: Dispatch<SetStateAction<string | null>>;
  savedModelUrl: string | null;
  setSavedModelUrl: Dispatch<SetStateAction<string | null>>;
  additionalSplats: SplatSwapPoint[];
  setAdditionalSplats: Dispatch<SetStateAction<SplatSwapPoint[]>>;
  currentSplatIndex: number;
  setCurrentSplatIndex: Dispatch<SetStateAction<number>>;
  isModelLocal: boolean;
  setIsModelLocal: Dispatch<SetStateAction<boolean>>;
  backgroundColor: string;
  setBackgroundColor: Dispatch<SetStateAction<string>>;
  customModelUrl: string;
  setCustomModelUrl: Dispatch<SetStateAction<string>>;
  hotspots: Hotspot[];
  setHotspots: Dispatch<SetStateAction<Hotspot[]>>;
  displayedHotspot: Hotspot | null;
  setDisplayedHotspot: Dispatch<SetStateAction<Hotspot | null>>;
  uiColor: string;
  setUiColor: Dispatch<SetStateAction<string>>;
  sceneTitle: string;
  setSceneTitle: Dispatch<SetStateAction<string>>;
  scrollSpeed: number;
  setScrollSpeed: Dispatch<SetStateAction<number>>;
  animationFrames: number;
  setAnimationFrames: Dispatch<SetStateAction<number>>;
  cameraMovementSpeed: number;
  setCameraMovementSpeed: Dispatch<SetStateAction<number>>;
  cameraRotationSensitivity: number;
  setCameraRotationSensitivity: Dispatch<SetStateAction<number>>;
  cameraDampening: number;
  setCameraDampening: Dispatch<SetStateAction<number>>;
  transitionSpeed: number;
  setTransitionSpeed: Dispatch<SetStateAction<number>>;
  scrollButtonMode: "percentage" | "waypoint";
  setScrollButtonMode: Dispatch<SetStateAction<"percentage" | "waypoint">>;
  scrollAmount: number;
  setScrollAmount: Dispatch<SetStateAction<number>>;
  allowedCameraModes: string[];
  setAllowedCameraModes: Dispatch<SetStateAction<string[]>>;
  loopMode: boolean;
  setLoopMode: Dispatch<SetStateAction<boolean>>;
  autoPlaySpeed: number;
  setAutoPlaySpeed: Dispatch<SetStateAction<number>>;
  autoPlayEnabled: boolean;
  setAutoPlayEnabled: Dispatch<SetStateAction<boolean>>;
  uiOptions: UIOptions;
  setUIOptions: Dispatch<SetStateAction<UIOptions>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isSplatLoading: boolean;
  setIsSplatLoading: Dispatch<SetStateAction<boolean>>;
  showLicenseAgreement: boolean;
  setShowLicenseAgreement: Dispatch<SetStateAction<boolean>>;
  showExportPopup: boolean;
  setShowExportPopup: Dispatch<SetStateAction<boolean>>;
  isProcessingLicense: boolean;
  setIsProcessingLicense: Dispatch<SetStateAction<boolean>>;
  currentLoadedSceneId: string | null;
  setCurrentLoadedSceneId: Dispatch<SetStateAction<string | null>>;
  collisionMeshesData: SerializedMeshData[];
  setCollisionMeshesData: Dispatch<SetStateAction<SerializedMeshData[]>>;
  collisionMeshes: BABYLON.Mesh[];
  setCollisionMeshes: Dispatch<SetStateAction<BABYLON.Mesh[]>>;
  resetSettings: () => void;
  thumbnailBlob: Blob | null;
  setThumbnailBlob: Dispatch<SetStateAction<Blob | null>>;
  thumbnailBlobRef: React.MutableRefObject<Blob | null>;
  additionalSplatsRef: React.MutableRefObject<SplatSwapPoint[]>;
  keepMeshesInMemory: boolean;
  setKeepMeshesInMemory: Dispatch<SetStateAction<boolean>>;
  keepMeshesInMemoryRef: React.MutableRefObject<boolean>;
  savedModelUrlRef: React.MutableRefObject<string | null>;
  fov: number;
  setFov: Dispatch<SetStateAction<number>>;
  skyboxes: Array<{ id: string; url: string }>;
  setSkyboxes: Dispatch<SetStateAction<Array<{ id: string; url: string }>>>;
  activeSkyboxUrl: string | null;
  setActiveSkyboxUrl: Dispatch<SetStateAction<string | null>>;
  invertCameraRotation: boolean;
  setInvertCameraRotation: Dispatch<SetStateAction<boolean>>;
}

export const useSceneState = (): SceneState => {
  const [playerHeight, setPlayerHeight] = useState<number>(1.8); // Default height in meters
  const [useNodeMaterial, setUseNodeMaterial] = useState<boolean>(DEFAULT_SETTINGS.useNodeMaterial);
  const [sceneType, setSceneType] = useState<SceneType | null>(null);
  const [lights, setLights] = useState<SceneLight[]>(DEFAULT_SETTINGS.lights);
  const [isLightEditMode, setIsLightEditMode] = useState<boolean>(false);
  const [selectedLightIndex, setSelectedLightIndex] = useState<number | null>(null);
  const [autoFrame, setAutoFrame] = useState<boolean>(DEFAULT_SETTINGS.autoFrame);
  const [additionalSplats, setAdditionalSplats] = useState<SplatSwapPoint[]>([]);
  const [currentSplatIndex, setCurrentSplatIndex] = useState<number>(0);
  const [loadedModelUrl, setLoadedModelUrl] = useState<string | null>(null);
  const [savedModelUrl, setSavedModelUrl] = useState<string | null>(null);
  const savedModelUrlRef = useRef<string | null>(null);
  const [fov, setFov] = useState<number>(0.8); // FOV in radians, default ~45 degrees
  const [skyboxes, setSkyboxes] = useState<Array<{ id: string; url: string }>>([]);
  const [activeSkyboxUrl, setActiveSkyboxUrl] = useState<string | null>(null);
  const [invertCameraRotation, setInvertCameraRotation] = useState<boolean>(false);

  // Scene refs
  const sceneRef = useRef<BABYLON.Scene | null>(null);
  const cameraRef = useRef<BABYLON.UniversalCamera | null>(null);
  const editCameraRef = useRef<BABYLON.ArcRotateCamera | null>(null);
  const pathRef = useRef<BABYLON.Vector3[]>([]);
  const rotationsRef = useRef<BABYLON.Quaternion[]>([]);
  const loadedMeshesRef = useRef<BABYLON.AbstractMesh[]>([]);
  const collisionMeshesRef = useRef<BABYLON.Mesh[]>([]);
  const localModelFileRef = useRef<File | null>(null);
  const thumbnailBlobRef = useRef<Blob | null>(null);
  const additionalSplatsRef = useRef<SplatSwapPoint[]>([]);

  // Control refs
  const userControlRef = useRef<boolean>(false);
  const animatingToPathRef = useRef<boolean>(false);
  const scrollPositionRef = useRef<number>(0);
  const scrollTargetRef = useRef<number>(0.01);
  const cameraModeRef = useRef<CameraMode>("explore");
  const cameraDampeningRef = useRef(0.05);
  const transitionSpeedRef = useRef<number>(0.5);
  const autoPlaySpeedRef = useRef<number>(0.05);
  const autoPlayEnabledRef = useRef<boolean>(false);
  const loopModeRef = useRef<boolean>(false);
  const cameraMovementSpeedRef = useRef<number>(DEFAULT_SETTINGS.cameraMovementSpeed);

  // Waypoints and interactions
  const [waypoints, setWaypoints] = useState<Waypoint[]>([]);
  const waypointsRef = useRef<Waypoint[]>([]);
  const activeWaypointsRef = useRef<Set<number>>(new Set());
  const activeAudioRefs = useRef<{
    [key: string]: { sound: BABYLON.Sound; data: AudioInteractionData };
  }>({});
  const targetRotationRef = useRef<BABYLON.Quaternion>(BABYLON.Quaternion.Identity());

  // UI and control states
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isCollisionEditMode, setIsCollisionEditMode] = useState(false);
  const [cameraMode, setCameraMode] = useState<CameraMode>("explore");
  const [scrollPercentage, setScrollPercentage] = useState<number>(0);
  const [showScrollControls, setShowScrollControls] = useState<boolean>(true);
  const [currentWaypointTitle, setCurrentWaypointTitle] = useState<string>("");
  const [infoPopupText, setInfoPopupText] = useState<string | null>(null);

  // Scene configuration
  const [isModelLocal, setIsModelLocal] = useState<boolean>(false);
  const [backgroundColor, setBackgroundColor] = useState<string>(DEFAULT_SETTINGS.backgroundColor);
  const [customModelUrl, setCustomModelUrl] = useState<string>("");
  const [hotspots, setHotspots] = useState<Hotspot[]>([]);
  const [displayedHotspot, setDisplayedHotspot] = useState<Hotspot | null>(null);
  const [uiColor, setUiColor] = useState<string>("#4CAF50");
  const [sceneTitle, setSceneTitle] = useState<string>("");
  const [thumbnailBlob, setThumbnailBlob] = useState<Blob | null>(null);

  // Performance and animation settings
  const [scrollSpeed, setScrollSpeed] = useState<number>(DEFAULT_SETTINGS.scrollSpeed);
  const [animationFrames, setAnimationFrames] = useState<number>(DEFAULT_SETTINGS.animationFrames);
  const [cameraMovementSpeed, setCameraMovementSpeed] = useState<number>(DEFAULT_SETTINGS.cameraMovementSpeed);
  const [cameraRotationSensitivity, setCameraRotationSensitivity] = useState<number>(DEFAULT_SETTINGS.cameraRotationSensitivity);
  const [cameraDampening, setCameraDampening] = useState<number>(0.95);
  const [transitionSpeed, setTransitionSpeed] = useState<number>(0.5);

  // Additional settings
  const [scrollButtonMode, setScrollButtonMode] = useState<"percentage" | "waypoint">("waypoint");
  const [scrollAmount, setScrollAmount] = useState(10);
  const [allowedCameraModes, setAllowedCameraModes] = useState<string[]>(["tour", "explore", "auto", "edit"]);
  const [loopMode, setLoopMode] = useState(false);
  const [autoPlaySpeed, setAutoPlaySpeed] = useState(0.05);
  const [autoPlayEnabled, setAutoPlayEnabled] = useState(false);
  const [uiOptions, setUIOptions] = useState<UIOptions>({
    infoPosition: "controls",
    buttonPosition: "below",
    uiType: UIType.Standard,
    showStartExperience: false,
    debugMode: false,
    hideWatermark: false,
  });

  // Loading states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSplatLoading, setIsSplatLoading] = useState<boolean>(false);

  // License and export states
  const [showLicenseAgreement, setShowLicenseAgreement] = useState(false);
  const [showExportPopup, setShowExportPopup] = useState(false);
  const [isProcessingLicense, setIsProcessingLicense] = useState(false);
  const [currentLoadedSceneId, setCurrentLoadedSceneId] = useState<string | null>(null);
  const [collisionMeshesData, setCollisionMeshesData] = useState<SerializedMeshData[]>([]);
  const [collisionMeshes, setCollisionMeshes] = useState<BABYLON.Mesh[]>([]);
  const [keepMeshesInMemory, setKeepMeshesInMemory] = useState<boolean>(false);
  const keepMeshesInMemoryRef = useRef<boolean>(false);

  // Effects
  useEffect(() => {
    additionalSplatsRef.current = additionalSplats;
    console.log("SCENESTATE:: additionalSplats updated:", additionalSplatsRef.current);
  }, [additionalSplats]);

  useEffect(() => {
    collisionMeshesRef.current = collisionMeshes;
  }, [collisionMeshes]);

  useEffect(() => {
    cameraModeRef.current = cameraMode;
  }, [cameraMode]);

  useEffect(() => {
    waypointsRef.current = waypoints;
  }, [waypoints]);

  useEffect(() => {
    loopModeRef.current = loopMode;
  }, [loopMode]);

  useEffect(() => {
    autoPlayEnabledRef.current = autoPlayEnabled;
  }, [autoPlayEnabled]);

  useEffect(() => {
    transitionSpeedRef.current = transitionSpeed;
  }, [transitionSpeed]);

  useEffect(() => {
    if (isEditMode) {
      setCameraMode("explore");
      cameraModeRef.current = "explore";
    }
  }, [isEditMode]);

  useEffect(() => {
    thumbnailBlobRef.current = thumbnailBlob;
    console.log("SCENESTATE:: Thumbnail blob updated:", thumbnailBlob);
  }, [thumbnailBlob]);

  useEffect(() => {
    keepMeshesInMemoryRef.current = keepMeshesInMemory;
    console.log("SCENESTATE:: Keep meshes in memory state updated:", keepMeshesInMemory);
  }, [keepMeshesInMemory]);

  useEffect(() => {
    console.log("Current Splat Index:", currentSplatIndex);
  }, [currentSplatIndex]);

  // When loadedModelUrl is first set (not null and savedModelUrl is null), set it as savedModelUrl
  useEffect(() => {
    if (loadedModelUrl && !savedModelUrl) {
      console.log("SCENESTATE:: Setting saved model URL to loaded model URL...: ", loadedModelUrl);
      setSavedModelUrl(loadedModelUrl);
    }
  }, [loadedModelUrl]);

  useEffect(() => {
    console.log("SCENESTATE:: Updating saved model URL ref...");
    savedModelUrlRef.current = savedModelUrl;
  }, [savedModelUrl]);

  const resetSettings = useCallback(() => {
    sceneRef.current?.meshes.forEach((mesh) => {
      console.log("SCENESTATE:: Resetting scene settings... Disposing mesh:", mesh.name);
      mesh.dispose();
    });
    //dispose of all material and sounds in the scene
    sceneRef.current?.materials.forEach((material) => {
      console.log("SCENESTATE:: Resetting scene settings... Disposing material:", material.name);
      material.dispose();
    });
    sceneRef.current?.sounds?.forEach((sound) => {
      console.log("SCENESTATE:: Resetting scene settings... Disposing sound:", sound.name);
      sound.dispose();
    });
    console.log("SCENESTATE:: Resetting scene settings...");
    setScrollSpeed(DEFAULT_SETTINGS.scrollSpeed);
    setAnimationFrames(DEFAULT_SETTINGS.animationFrames);
    setCameraMovementSpeed(DEFAULT_SETTINGS.cameraMovementSpeed);
    setCameraRotationSensitivity(DEFAULT_SETTINGS.cameraRotationSensitivity);
    setBackgroundColor(DEFAULT_SETTINGS.backgroundColor);
    setAutoFrame(DEFAULT_SETTINGS.autoFrame);
    setCustomModelUrl("");
    setLoadedModelUrl(null);
    setSavedModelUrl(null);
    setIsModelLocal(false);
    setWaypoints([]);
    setLights(DEFAULT_SETTINGS.lights);
    setSceneType(null);
    setAdditionalSplats([]);
    setCurrentSplatIndex(0);
    setPlayerHeight(1.8); // Reset player height to default
    setCollisionMeshesData([]);
    setCollisionMeshes([]);
    setKeepMeshesInMemory(false);
    setThumbnailBlob(null);
    setAdditionalSplats([]);
    setUseNodeMaterial(DEFAULT_SETTINGS.useNodeMaterial);
    setSkyboxes([]);
    setActiveSkyboxUrl(null);
    setInvertCameraRotation(false);
  }, []);

  return {
    playerHeight,
    setPlayerHeight,
    useNodeMaterial,
    setUseNodeMaterial,
    sceneType,
    setSceneType,
    lights,
    setLights,
    isLightEditMode,
    setIsLightEditMode,
    selectedLightIndex,
    setSelectedLightIndex,
    autoFrame,
    setAutoFrame,
    sceneRef,
    cameraRef,
    editCameraRef,
    pathRef,
    rotationsRef,
    loadedMeshesRef,
    collisionMeshesRef,
    localModelFileRef,
    userControlRef,
    animatingToPathRef,
    scrollPositionRef,
    scrollTargetRef,
    cameraModeRef,
    cameraDampeningRef,
    transitionSpeedRef,
    autoPlaySpeedRef,
    autoPlayEnabledRef,
    loopModeRef,
    cameraMovementSpeedRef,
    waypoints,
    setWaypoints,
    waypointsRef,
    activeWaypointsRef,
    activeAudioRefs,
    targetRotationRef,
    isEditMode,
    setIsEditMode,
    isCollisionEditMode,
    setIsCollisionEditMode,
    cameraMode,
    setCameraMode,
    scrollPercentage,
    setScrollPercentage,
    showScrollControls,
    setShowScrollControls,
    currentWaypointTitle,
    setCurrentWaypointTitle,
    infoPopupText,
    setInfoPopupText,
    loadedModelUrl,
    setLoadedModelUrl,
    savedModelUrl,
    setSavedModelUrl,
    additionalSplats,
    setAdditionalSplats,
    currentSplatIndex,
    setCurrentSplatIndex,
    isModelLocal,
    setIsModelLocal,
    backgroundColor,
    setBackgroundColor,
    customModelUrl,
    setCustomModelUrl,
    hotspots,
    setHotspots,
    displayedHotspot,
    setDisplayedHotspot,
    uiColor,
    setUiColor,
    sceneTitle,
    setSceneTitle,
    scrollSpeed,
    setScrollSpeed,
    animationFrames,
    setAnimationFrames,
    cameraMovementSpeed,
    setCameraMovementSpeed,
    cameraRotationSensitivity,
    setCameraRotationSensitivity,
    cameraDampening,
    setCameraDampening,
    transitionSpeed,
    setTransitionSpeed,
    scrollButtonMode,
    setScrollButtonMode,
    scrollAmount,
    setScrollAmount,
    allowedCameraModes,
    setAllowedCameraModes,
    loopMode,
    setLoopMode,
    autoPlaySpeed,
    setAutoPlaySpeed,
    autoPlayEnabled,
    setAutoPlayEnabled,
    uiOptions,
    setUIOptions,
    isLoading,
    setIsLoading,
    isSplatLoading,
    setIsSplatLoading,
    showLicenseAgreement,
    setShowLicenseAgreement,
    showExportPopup,
    setShowExportPopup,
    isProcessingLicense,
    setIsProcessingLicense,
    currentLoadedSceneId,
    setCurrentLoadedSceneId,
    collisionMeshesData,
    setCollisionMeshesData,
    collisionMeshes,
    setCollisionMeshes,
    resetSettings,
    thumbnailBlob,
    setThumbnailBlob,
    thumbnailBlobRef,
    additionalSplatsRef,
    keepMeshesInMemory,
    setKeepMeshesInMemory,
    keepMeshesInMemoryRef,
    savedModelUrlRef,
    fov,
    setFov,
    skyboxes,
    setSkyboxes,
    activeSkyboxUrl,
    setActiveSkyboxUrl,
    invertCameraRotation,
    setInvertCameraRotation,
  };
};
